import {createSlice} from '@reduxjs/toolkit';
import {CustomizeSettingState} from '../state-types/customizeSettingStateType';

const initialState: CustomizeSettingState = {
  siteName: '',
  logoAlt: '',
  logoPhotoUri: '',
  backGroundPhotoUri: '',
  contactEmail: '',
  contactPhoneNumber: '',
  contactCompanyName: '',
  hideAppDownloadLink: false,
};

export const customizeSettingSlice = createSlice({
  name: 'customizeSetting',
  initialState,
  reducers: {
    set: (state, action: {payload: Partial<CustomizeSettingState>}) => ({...state, ...action.payload}),
    clear: () => initialState,
  },
});
