import {configureStore, getDefaultMiddleware} from '@reduxjs/toolkit';
import rootReducer from './rootReducer';

const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware(),
});

if (process.env.NODE_ENV === 'development' && (module as any).hot) {
  // Enable Webpack hot module replacement for reducers
  (module as any).hot.accept('./rootReducer', () => {
    store.replaceReducer(rootReducer);
  });
}

export {store};
