import axios, {AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse} from 'axios';
import Logger from '../../logger/logger';
import Env from '../../env/env';

const createAxios = (baseURL?: string) => {
  if (!baseURL) {
    throw new Error('baseURL required.');
  }
  return axios.create({
    baseURL,
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

const logger = Logger.create('functions');
const traceRequest = (path: string, data?: any, config?: AxiosRequestConfig) => {
  logger.trace('path=', path, 'data=', data, 'config=', config);
};

const toResponse = async <T>(promise: Promise<AxiosResponse<T>>) => {
  try {
    const res = await promise;
    logger.trace(res.data);
    return res.data;
  } catch (e) {
    logger.error((e as AxiosError)?.response);
    throw e;
  }
};

export class FirebaseHomehubFunctions {
  private static axios: AxiosInstance;
  private static axiosLocal: AxiosInstance;

  private static init = () => {
    FirebaseHomehubFunctions.axios = createAxios(process.env.REACT_APP_HOMEHUB_API_HOST);
  };

  private static initLocal = () => {
    FirebaseHomehubFunctions.axiosLocal = createAxios(process.env.REACT_APP_HOMEHUB_API_HOST_LOCAL);
  };

  private static initIfNot = () => {
    if (!FirebaseHomehubFunctions.axios) {
      FirebaseHomehubFunctions.init();
    }
    if (!FirebaseHomehubFunctions.axiosLocal) {
      if (Env.getLocalApi()) {
        FirebaseHomehubFunctions.initLocal();
      }
    }
  };

  public static setAccessToken = (token: string) => {
    FirebaseHomehubFunctions.initIfNot();
    FirebaseHomehubFunctions.axios.defaults.headers['X-Api-Key'] = token;
    if (FirebaseHomehubFunctions.axiosLocal) {
      FirebaseHomehubFunctions.axiosLocal.defaults.headers['X-Api-Key'] = token;
    }
  };

  public static makePath = (a: string, b: string) => {
    if (!a.startsWith('/')) {
      a = '/' + a;
    }
    if (!b.startsWith('/')) {
      b = '/' + b;
    }
    return a + b;
  };

  public static post = <Response, Request>(path: string, data: Request, config?: AxiosRequestConfig) => {
    traceRequest(path, data, config);
    FirebaseHomehubFunctions.initIfNot();
    return toResponse(FirebaseHomehubFunctions.axios.post<Response>(path, data, config));
  };
  public static patch = <Response, Request>(path: string, data: Request, config?: AxiosRequestConfig) => {
    traceRequest(path, data, config);
    FirebaseHomehubFunctions.initIfNot();
    return toResponse(FirebaseHomehubFunctions.axios.patch<Response>(path, data, config));
  };

  public static patchLocal = <Response, Request>(path: string, data: Request, config?: AxiosRequestConfig) => {
    traceRequest(path, data, config);
    FirebaseHomehubFunctions.initIfNot();
    return toResponse(FirebaseHomehubFunctions.axiosLocal.patch<Response>(path, data, config));
  };

  public static postLocal = <Response, Request>(path: string, data: Request, config?: AxiosRequestConfig) => {
    traceRequest(path, data, config);
    FirebaseHomehubFunctions.initIfNot();
    return toResponse(FirebaseHomehubFunctions.axiosLocal.post<Response>(path, data, config));
  };

  public static get = <Response>(path: string, config?: AxiosRequestConfig) => {
    traceRequest(path, null, config);
    FirebaseHomehubFunctions.initIfNot();
    return toResponse(FirebaseHomehubFunctions.axios.get<Response>(path, config));
  };

  public static getLocal = <Response>(path: string, config?: AxiosRequestConfig) => {
    traceRequest(path, null, config);
    FirebaseHomehubFunctions.initIfNot();
    return toResponse(FirebaseHomehubFunctions.axiosLocal.get<Response>(path, config));
  };
}
