import {createSlice} from '@reduxjs/toolkit';
import {ExternalMembersActivationStateType} from '../state-types/externalMembersActivationStateType';

const initialState: ExternalMembersActivationStateType = {
  organizationId: '',
  peopleId: '',
  externalMembers: [],
  status: 'None',
};

export const externalMembersActivationSlice = createSlice({
  name: 'externalMembersActivation',
  initialState,
  reducers: {
    set: (state, action: {payload: Partial<ExternalMembersActivationStateType>}) => ({...state, ...action.payload}),
    clear: () => initialState,
  },
});
