import {combineReducers} from '@reduxjs/toolkit';
import {userSlice} from '../slices/userSlice';
import {localeSlice} from '../slices/localeSlice';
import {invitationSlice} from '../slices/invitationSlice';
import {customizeSettingSlice} from '../slices/customSettingSlice';
import {resetPasswordSlice} from '../slices/resetPasswordSlice';
import {activationSlice} from '../slices/activationSlice';
import {externalMembersActivationSlice} from '../slices/externalMemberActivationSlice';

const rootReducer = combineReducers({
  user: userSlice.reducer,
  invitation: invitationSlice.reducer,
  locale: localeSlice.reducer,
  customizeSetting: customizeSettingSlice.reducer,
  resetPassword: resetPasswordSlice.reducer,
  activation: activationSlice.reducer,
  externalMembersActivation: externalMembersActivationSlice.reducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
