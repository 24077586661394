import firebase from 'firebase/app';
import 'firebase/storage';
import 'firebase/auth';
import BooleanUtil from '../util/booleanUtil';

const serviceAccount = {
  apiKey: process.env.REACT_APP_FIREBASE_SERVICE_ACCOUNT_API_KEY,
  databaseURL: process.env.REACT_APP_FIREBASE_SERVICE_ACCOUNT_DATABASE_URL,
  storageBucket: process.env.REACT_APP_FIREBASE_SERVICE_ACCOUNT_STORAGE_BUCKET,
  authDomain: process.env.REACT_APP_FIREBASE_SERVICE_ACCOUNT_AUTH_DOMAIN,
  messagingSenderId: process.env.REACT_APP_FIREBASE_SERVICE_ACCOUNT_MESSAGING_SENDER_ID,
  projectId: process.env.REACT_APP_FIREBASE_SERVICE_ACCOUNT_PROJECT_ID,
};

export class Firebase {
  private readonly app: firebase.app.App;
  private static instance = new Firebase();
  public static getInstance = () => Firebase.instance;
  constructor() {
    console.log('firebase initialized.');
    this.app = firebase.initializeApp(serviceAccount);
    console.log('this.app: ', this.app);
    if (BooleanUtil.toVal(process.env.REACT_APP_USE_LOCAL_FIREBASE_CALLABLE_API)) {
      this.app.functions().useFunctionsEmulator('http://localhost:5000');
    }
  }
  public getApp = () => this.app;
}
