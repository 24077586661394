import {createSlice} from '@reduxjs/toolkit';
import {NestedReadonly} from '../../types/common-types';
import {InvitationState} from '../state-types/invitationStateType';
import {BusinessType} from '../../types/business-types';
import {ContactType} from '../../../lib/bitkey-platform-api';

const initialState: NestedReadonly<InvitationState> = {
  invitationId: '',
  inviteeOrganizationId: '',
  inviteeOrganizationName: '',
  businessType: BusinessType.CORE,
  hasAccount: false,
  alreadyRegistered: false,
  contactType: ContactType.email,
};

export const invitationSlice = createSlice({
  name: 'invitation',
  initialState,
  reducers: {
    set: (state, action: {payload: Partial<InvitationState>}) => ({...state, ...action.payload}),
    clear: () => initialState,
  },
});
