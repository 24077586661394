export enum LocalStorageKey {
  // TODO タブごとに別で持てるようにする
  AccessToken = 'AccessToken',
  RefreshToken = 'RefreshToken',
  CustomToken = 'CustomToken',
  // 次回ログインするpersonaを覚えておくため
  PersonaId = 'PersonaId',
  // personaとemailの一致を確認しないとログイン詰むため必須
  Email = 'Email',
}

export default class LocalStorage {
  public static get = (key: LocalStorageKey) => {
    // nullは使いづらい・・・
    const res = localStorage.getItem(key);
    return res === null ? undefined : res;
  };
  public static set = (key: LocalStorageKey, value: string) => localStorage.setItem(key, value);
  public static remove = (key: LocalStorageKey) => localStorage.removeItem(key);
}
