import {createSlice} from '@reduxjs/toolkit';
import {NestedReadonly} from '../../types/common-types';
import {ResetPasswordState} from '../state-types/resetPasswordSteteType';
import {ContactType} from '../../../lib/bitkey-platform-api';

const initialState: NestedReadonly<ResetPasswordState> = {
  newPassword: '',
  token: '',
  resetPasswordSessionId: '',
  contactType: ContactType.email,
};

export const resetPasswordSlice = createSlice({
  name: 'resetPassword',
  initialState,
  reducers: {
    set: (state, action: {payload: Partial<ResetPasswordState>}) => ({...state, ...action.payload}),
    clear: () => initialState,
  },
});
