import {createMuiTheme} from '@material-ui/core/styles';
import {BColor} from './color';

export const defaultTheme = createMuiTheme({
  palette: {
    primary: {
      main: BColor.Main,
      light: BColor.MainLight,
      dark: BColor.MainDark,
      contrastText: BColor.White,
    },
    secondary: {
      main: BColor.Sub,
      light: BColor.SubLight,
      dark: BColor.SubDark,
      contrastText: BColor.White,
    },
    text: {
      primary: BColor.Black,
      secondary: BColor.Modify,
      hint: BColor.Hint,
    },
  },
  overrides: {
    MuiInputBase: {
      input: {
        height: '1.2em',
        paddingTop: 5,
      },
    },
    MuiTypography: {
      root: {
        // fontSize: '0.875rem',
        // fontFamily: 'inherit',
      },
      body1: {
        fontSize: 'inherit',
        fontFamily: 'inherit',
      },
    },
    MuiMenuItem: {
      root: {
        fontFamily: 'inherit',
      },
    },
    MuiButton: {
      root: {
        fontFamily: 'inherit',
        textTransform: 'none',
      },
    },
    MuiOutlinedInput: {
      inputMarginDense: {
        paddingTop: 10.5,
        paddingBottom: 10.5,
      },
    },
    MuiTab: {
      root: {
        textTransform: 'none',
      },
    },
    MuiTableCell: {
      body: {
        position: 'relative',
      },
    },
  },
});
