import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {NestedReadonly} from '../../types/common-types';
import {Locale, LocaleState} from '../state-types/localeStateType';

const initialState: NestedReadonly<LocaleState> = Locale.ja_JP as Locale;

export const localeSlice = createSlice({
  name: 'locale',
  initialState,
  reducers: {
    set: (state, action: PayloadAction<Locale>) => action.payload,
    clear: () => initialState,
  },
});
