import {hot} from 'react-hot-loader/root';
import React, {createContext, lazy, Suspense, useCallback, useEffect, useState} from 'react';
import {Provider} from 'react-redux';
import {BrowserRouter} from 'react-router-dom';
import {store} from './common/redux/store/store';
import {defaultTheme} from './common/styles/theme';
import {ThemeProvider} from '@material-ui/styles';
import Env from './common/env/env';
import SimpleSnackbar, {SimpleSnackbarState, SimpleSnackbarType} from './components/snackbars/SimpleSnackbar';

const Router = lazy(() => import('./screens/Router'));

export const SnackbarContext = createContext<{
  openSnackbar: (state: SimpleSnackbarState) => any;
}>({
  openSnackbar: () => {},
});

function App() {
  const [initialized, setInitialized] = useState(false);
  const [snackbarState, setSnackbarState] = useState<SimpleSnackbarState>({
    open: false,
    message: '',
    type: SimpleSnackbarType.Success,
  });
  const openSnackbar = useCallback((state: SimpleSnackbarState) => setSnackbarState(state), []);
  useEffect(() => {
    Env.init();
    setInitialized(true);
  }, []);

  if (!initialized) {
    return null;
  } else {
    return (
      <Provider store={store}>
        <ThemeProvider theme={defaultTheme}>
          <SnackbarContext.Provider
            value={{
              openSnackbar: openSnackbar,
            }}
          >
            <BrowserRouter>
              <Suspense fallback={null}>
                <Router />
              </Suspense>
            </BrowserRouter>
            <SimpleSnackbar snackbarState={snackbarState} setSnackbarState={setSnackbarState} />
          </SnackbarContext.Provider>
        </ThemeProvider>
      </Provider>
    );
  }
}

export default hot(App);
