import {createSlice} from '@reduxjs/toolkit';
import {ActivationStateType} from '../state-types/activationStateType';

const initialState: ActivationStateType = {
  organizationId: '',
  peopleId: '',
  spaceUsages: [],
  spaces: [],
  peopleStatus: 'none',
  hasBitkeyAccount: false,
};

export const activationSlice = createSlice({
  name: 'activation',
  initialState,
  reducers: {
    set: (state, action: {payload: Partial<ActivationStateType>}) => ({...state, ...action.payload}),
    clear: () => initialState,
  },
});
