/**
 * 環境情報を管理するファイル
 * サーバー側とは情報が異なるためserviceのenvを持ってくるのは禁止
 * 各クライアント間では共有してOK
 */
import Logger, {LogLevel} from '../logger/logger';
import {Firebase} from '../firebase/firebase';
import {FirebaseFunctions} from '../firebase/functions/firebaseFunctions';
import BitkeyPlatformAPI, {DatetimeType} from '../../lib/bitkey-platform-api';
import LocalStorage, {LocalStorageKey} from '../storage/localStorage';
import {FirebaseHomehubFunctions} from '../firebase/functions/firebaseHomehubFunctions';

const initLogger = () => {
  if (process.env.REACT_APP_LOG_LEVEL) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const logLevel = LogLevel[process.env.REACT_APP_LOG_LEVEL];
    if (!logLevel) {
      throw new Error('log level must be one of LogLevel.');
    }
    Logger.setLogLevel(logLevel);
  } else {
    throw new Error('log level must be specified.');
  }
};

const initBkp = () => {
  if (process.env.REACT_APP_BKP_BASE_PATH && process.env.REACT_APP_BKP_VERSION) {
    BitkeyPlatformAPI.init(`${process.env.REACT_APP_BKP_BASE_PATH}${process.env.REACT_APP_BKP_VERSION}`, {
      datetimeType: DatetimeType.MOMENT,
      logger: {
        log: console.log,
        warn: console.log,
        error: console.error,
      },
      updateTokens: async (accessToken, refreshToken) => {
        await LocalStorage.set(LocalStorageKey.AccessToken, accessToken);
        await LocalStorage.set(LocalStorageKey.RefreshToken, refreshToken);
      },
    });
  } else {
    throw new Error('BKP path and version is not set.');
  }
};

const checkEnv = () => {
  if (!Env.getApiHost()) {
    throw new Error('api host required.');
  }
  if (process.env.LOCAL_API && !Env.getApiHostLocal()) {
    throw new Error('api host for local required.');
  }
};

export default class Env {
  private static environment: string;
  public static init = () => {
    Env.environment = process.env.REACT_APP_ENV!;
    checkEnv();
    initLogger();
    initBkp();
    Firebase.getInstance();
    FirebaseFunctions.setAccessToken('');
    FirebaseHomehubFunctions.setAccessToken('');
  };
  public static isProduction = () => Env.environment === 'production';

  public static getApiHost = () => process.env.REACT_APP_API_HOST as string;
  public static getApiHostLocal = () => process.env.REACT_APP_API_HOST_LOCAL as string;

  /**
   * firebase serveしているローカル環境を使う場合。
   * npm start -- --local common
   * みたいに指定するとそこだけ選択的にローカルサーバを使用する。
   * カンマ区切りで複数指定も考えたけど、ポート指定がめんどくさいのと、そもそも各APIサーバは独立しているべきなので、
   * 複数ローカルで動作確認しないといけないシーンがよくないためこれで。
   */
  public static getLocalApi = () => process.env.REACT_APP_LOCAL_API;
}
