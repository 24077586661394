import axios, {AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse} from 'axios';
import firebase from 'firebase/app';
import 'firebase/functions';
import Logger from '../../logger/logger';
import Env from '../../env/env';

const createAxios = (baseURL?: string) => {
  if (!baseURL) {
    throw new Error('baseURL required.');
  }
  return axios.create({
    baseURL,
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

const logger = Logger.create('functions');
const traceRequest = (path: string, data?: any, config?: AxiosRequestConfig) => {
  logger.trace('path=', path, 'data=', data, 'config=', config);
};

const toResponse = async <T>(promise: Promise<AxiosResponse<T>>) => {
  try {
    const res = await promise;
    logger.trace(res.data);
    return res.data;
  } catch (e) {
    logger.error((e as AxiosError)?.response);
    throw e;
  }
};

export class FirebaseFunctions {
  private static axios: AxiosInstance;
  private static axiosLocal: AxiosInstance;

  private static init = () => {
    FirebaseFunctions.axios = createAxios(process.env.REACT_APP_API_HOST);
  };

  private static initLocal = () => {
    FirebaseFunctions.axiosLocal = createAxios(process.env.REACT_APP_API_HOST_LOCAL);
  };

  private static initIfNot = () => {
    if (!FirebaseFunctions.axios) {
      FirebaseFunctions.init();
    }
    if (!FirebaseFunctions.axiosLocal) {
      if (Env.getLocalApi()) {
        FirebaseFunctions.initLocal();
      }
    }
  };

  public static setAccessToken = (token: string) => {
    FirebaseFunctions.initIfNot();
    FirebaseFunctions.axios.defaults.headers['X-Api-Key'] = token;
    if (FirebaseFunctions.axiosLocal) {
      FirebaseFunctions.axiosLocal.defaults.headers['X-Api-Key'] = token;
    }
  };

  public static makePath = (a: string, b: string) => {
    if (!a.startsWith('/')) {
      a = '/' + a;
    }
    if (!b.startsWith('/')) {
      b = '/' + b;
    }
    return a + b;
  };

  public static makeOnCallPath = (a: string, b: string) => {
    if (!a.startsWith('-')) {
      a = '-' + a;
    }
    if (!b.startsWith('-')) {
      b = '-' + b;
    }
    return a + b;
  };

  public static post = <Response, Request>(path: string, data: Request, config?: AxiosRequestConfig) => {
    traceRequest(path, data, config);
    FirebaseFunctions.initIfNot();
    return toResponse(FirebaseFunctions.axios.post<Response>(path, data, config));
  };
  public static patch = <Response, Request>(path: string, data: Request, config?: AxiosRequestConfig) => {
    traceRequest(path, data, config);
    FirebaseFunctions.initIfNot();
    return toResponse(FirebaseFunctions.axios.patch<Response>(path, data, config));
  };

  public static patchLocal = <Response, Request>(path: string, data: Request, config?: AxiosRequestConfig) => {
    traceRequest(path, data, config);
    FirebaseFunctions.initIfNot();
    return toResponse(FirebaseFunctions.axiosLocal.patch<Response>(path, data, config));
  };

  public static postLocal = <Response, Request>(path: string, data: Request, config?: AxiosRequestConfig) => {
    traceRequest(path, data, config);
    FirebaseFunctions.initIfNot();
    return toResponse(FirebaseFunctions.axiosLocal.post<Response>(path, data, config));
  };

  public static get = <Response>(path: string, config?: AxiosRequestConfig) => {
    traceRequest(path, null, config);
    FirebaseFunctions.initIfNot();
    return toResponse(FirebaseFunctions.axios.get<Response>(path, config));
  };

  public static getLocal = <Response>(path: string, config?: AxiosRequestConfig) => {
    traceRequest(path, null, config);
    FirebaseFunctions.initIfNot();
    return toResponse(FirebaseFunctions.axiosLocal.get<Response>(path, config));
  };

  /**
   * 下記、既存の招待導線において一部callable関数が残っているのでhttps.onCall関数も用意しておく
   */
  private static defaultApiVersion = 'v1';
  /**
   * 既存導線でcallableをまだ利用しているので残してます
   * 全てAPIに置き換え完了したら排除する
   *
   * @param apiName
   * @param data
   * @param options
   */
  public static call = async (apiName: string, data?: any, options?: any) => {
    if (apiName.startsWith('callable-')) {
      throw new Error('apiName must not starts with "callable-"');
    }
    if (!/^v[0-9]+-/.exec(apiName)) {
      apiName = FirebaseFunctions.defaultApiVersion + apiName;
    }
    apiName = 'callable-' + apiName;
    console.log('ここまではいっている');
    const res = await firebase
      .functions()
      .httpsCallable(
        apiName,
        options
      )(data)
      .catch(e => {
        throw e;
      });
    return res.data;
  };
}
