import {createSlice} from '@reduxjs/toolkit';
import {UserState} from '../state-types/userStateType';
import {NestedReadonly} from '../../types/common-types';

const initialState: NestedReadonly<UserState> = {
  personaId: '',
  organizationId: '',
  name: '',
  organizationName: '',
  accessToken: '',
  password: '',
  identifyValue: '',
  identifyValueMode: 'Fix',
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    set: (state, action: {payload: Partial<UserState>}) => ({...state, ...action.payload}),
    clear: () => initialState,
  },
});
