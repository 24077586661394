export default class BooleanUtil {
  /**
   * 渡ってきた引数をboolean値に変換する
   * boolean型ならそのまま返却,
   * number型なら1ならtrue, それ以外はfalse,
   * string型なら'true'ならtrue, それ以外はfalse,
   * それ以外の型ならfalseを返却する
   * @param v
   */
  public static toVal = (v: boolean | number | string | any): boolean => {
    if (typeof v === 'boolean') {
      return v;
    } else if (typeof v === 'number') {
      return v === 1;
    } else if (typeof v === 'string') {
      return v === 'true';
    }
    return false;
  };

  /**
   * boolean型に変換できるかどうか
   * @param v
   */
  public static isConvertible = (v: boolean | number | string | any): boolean => {
    if (typeof v === 'boolean') {
      return true;
    } else if (typeof v === 'number') {
      return v === 0 || v === 1;
    } else if (typeof v === 'string') {
      return v === 'false' || v === 'true';
    }
    return false;
  };
}
