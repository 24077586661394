// /v1-22ea3af9/

import axios, {AxiosInstance, AxiosPromise} from 'axios';
import moment, {Moment} from 'moment';

export interface ArbitraryProperty {
  attachment: boolean;
  createdAt: number;
  deleted: boolean;
  id: string;
  name: string;
  type: PropertyType;
  updatedAt: number;
  userId: string;
  value: PropertyValue;
}
export interface AuthFailedError extends Error {
  lockout: boolean;
  passcodeRequired: boolean;
  personaId: string;
}
export interface AuthenticationResult {
  accessToken: string;
  organizationId: string;
  personaId: string;
  refreshToken: string;
  userId: string;
}
export type AuthnTypes = string[];
export interface Borrower {
  id: string;
  ownerTypes?: OwnerTypes;
}
export interface CandidatePersona {
  id: string;
  organizationId: string;
  organizationName: string;
  roleId: string;
  roleName: string;
}
export enum CandidateType {
  personality = 'personality',
  email = 'email',
  phone = 'phone',
}
export interface Contact {
  id: string;
  name: string;
  type: ContactType;
  userId: string;
  value: string;
}
export interface ContactIdentifiedPersona {
  contactId: string;
  personaId: string;
}
export enum ContactType {
  email = 'email',
  phone = 'phone',
}
export enum Days {
  sun = 'sun',
  mon = 'mon',
  tue = 'tue',
  wed = 'wed',
  thu = 'thu',
  fri = 'fri',
  sat = 'sat',
}
export interface Deal {
  applyFrom: Personality;
  applyTo: Personality;
  createdAt: number;
  holeId: string;
  id: string;
  objectId: string;
  permittees: Personality[];
  publishPeriod: Period;
  repeatFirstDayOfWeek: 'sun' | 'mon' | 'tue' | 'wed' | 'thu' | 'fri' | 'sat';
  repeatInterval: number;
  repeatStartDate: string;
  repeatType: RepeatTypes;
  repeatUseMinutes: number;
  repeatUseStartAt: number;
  repeatWeekOfMonth: number;
  repeatWeeklyDays: Days[];
  status: DealStatuses;
  updatedAt: number;
  usageLimit: number;
  usagePeriod: Period;
}
export interface DealOffer {
  acceptOnlyOne: boolean;
  acceptanceTimeLimitEpoch: Datetime;
  acceptors: Personality[];
  candidates: Personality[];
  createdAt: number;
  holeId: string;
  id: string;
  offerFrom: Personality;
  publishPeriod: Period;
  repeatFirstDayOfWeek: 'sun' | 'mon' | 'tue' | 'wed' | 'thu' | 'fri' | 'sat';
  repeatInterval: number;
  repeatStartDate: string;
  repeatType: RepeatTypes;
  repeatUseMinutes: number;
  repeatUseStartAt: number;
  repeatWeekOfMonth: number;
  repeatWeeklyDays: Days[];
  updatedAt: number;
  usageLimit: number;
  usagePeriod: Period;
}
export interface DealOfferCandidate {
  candidateType: 'personality' | 'email' | 'phone';
  identityValue: string;
  personality: {
    isOrganization: boolean;
    personalityId: string;
  };
}
export enum DealStatuses {
  applied = 'applied',
  approved = 'approved',
  rejected = 'rejected',
  disabled = 'disabled',
}
export interface DigitalKey {
  signedDigitalKey: string;
}
export interface EcdsaSignature {
  r: string;
  s: string;
}
export interface ElectionSystem {
  electionType: number;
  organizationId: string;
  threshold: number;
}
export interface Elections {
  amountOfVotes: number;
  canUpdateOwner: boolean;
  candidatePersonaId: string;
  organizationId: string;
  percentageOfVotes: number;
}
export interface Error {
  code?: number;
  message: string;
}
export interface Hole {
  activated: boolean;
  code: string;
  createdAt: number;
  deleted: boolean;
  id: string;
  name: string;
  objectId: string;
  owner: Personality;
  ownerType: 'personal' | 'organization' | 'organization_group';
  seed: string;
  updatedAt: number;
  usePersonaSign: boolean;
}
export interface HoleBorrower extends Personality, Period {
  powers: {
    dealControl: boolean;
    holeGetKey: boolean;
    holeLend: boolean;
    holeUpdateKey: boolean;
  };
}
export interface HoleConfiguration {
  signedHole: string;
}
export interface HoleLendContract {
  borrower: Borrower;
  contractId: string;
  contractPeriod: Period;
  createdAt: number;
  disabled: boolean;
  holeId: string;
  lender: Lender;
  powers: {
    dealControl: boolean;
    holeGetKey: boolean;
    holeLend: boolean;
    holeUpdateKey: boolean;
  };
  status: HoleLendContractStatus;
  updatedAt: number;
}
export enum HoleLendContractStatus {
  applied = 'applied',
  requested = 'requested',
  accepted = 'accepted',
  rejected = 'rejected',
}
export interface InitialAuthenticationRequest {
  email?: string;
  mailTemplate?: MailTemplate;
  passCode?: string;
  password: Password;
  phone?: string;
}
export interface JwkObject {
  alg?: 'ES256' | 'RS256';
  crv?: 'P-256';
  e?: string;
  kid?: string;
  kty: 'EC' | 'RSA';
  n?: string;
  x?: string;
  y?: string;
}
export interface JwkSets {
  keys: JwkObject[];
}
export interface Lender {
  id: string;
  ownerTypes?: OwnerTypes;
}
export type MailTemplate = string;
export interface MemberProperty {
  id: string;
  memberPropertyLabelId: string;
  multipleAssignees: boolean;
  organizationId: string;
  propertyName: string;
  propertyValue: PropertyValue;
}
export interface MemberPropertyLabel {
  createdAt: number;
  id: string;
  name: string;
  organizationId: string;
  updatedAt: number;
}
export interface MemberPropertySubmission {
  approved: boolean;
  arbitraryPropertyId: string;
  createdAt: number;
  deleted: boolean;
  id: string;
  memberPropertyLabelId: string;
  multipleAssignees: boolean;
  organizationId: string;
  personaId: string;
  updatedAt: number;
}
export interface MemberPropertyWithPersonaIds extends MemberProperty {
  personaIds: string[];
}
export interface MultipleAuthenticationResult {
  authenticated: AuthenticationResult;
  multipleChoice: {
    personas: CandidatePersona[];
    sessionKey: MultipleChoiceSessionKey;
  };
}
export type MultipleChoiceSessionKey = string;
export interface NullableHolePower extends NullableLentHolePower {
  treasureDealControl?: boolean;
}
export interface NullableHolePowerCaps {
  dealControl: boolean;
  holeGetKey: boolean;
  holeLend: boolean;
  holeUpdateKey: boolean;
  treasureDealControl: boolean;
}
export interface NullableLentHolePower {
  dealControl?: boolean;
  holeGetKey?: boolean;
  holeLend?: boolean;
  holeUpdateKey?: boolean;
}
export interface NullableLentHolePowerCaps {
  dealControl: boolean;
  holeGetKey: boolean;
  holeLend: boolean;
  holeUpdateKey: boolean;
}
export interface NullableObjectPower extends NullableHolePower {
  holeFind?: boolean;
  holeSet?: boolean;
  objectFind?: boolean;
}
export interface NullableObjectPowerCaps extends NullableHolePowerCaps {
  holeFind: boolean;
  holeSet: boolean;
  objectFind: boolean;
}
export interface NullableOrganizationGroupHolePower {
  dealControl?: boolean;
  holeGetKey?: boolean;
  holeLend?: boolean;
  holeUpdateKey?: boolean;
  treasureDealControl?: boolean;
}
export interface NullableOrganizationGroupLentHolePower {
  dealControl?: boolean;
  holeGetKey?: boolean;
  holeLend?: boolean;
  holeUpdateKey?: boolean;
}
export interface NullableOrganizationGroupObjectPower extends NullableOrganizationGroupHolePower {
  holeFind?: boolean;
  holeSet?: boolean;
  objectFind?: boolean;
}
export interface NullableOrganizationGroupPower extends NullableOrganizationGroupObjectPower {
  objectControl?: boolean;
  sharedMemberPropertyControl?: boolean;
  sharedMemberPropertyFind?: boolean;
  treasureBoxBindObject?: boolean;
  treasureBoxDelete?: boolean;
  treasureBoxFind?: boolean;
  treasureBoxLend?: boolean;
  treasureBoxManage?: boolean;
  treasureBoxSet?: boolean;
  treasureDataFind?: boolean;
  treasureDataPresetControl?: boolean;
  treasureDataPresetRegister?: boolean;
}
export interface NullablePower extends NullableObjectPower {
  dealApply?: boolean;
  dealGetKey?: boolean;
  dealOfferAcceptAsOrganization?: boolean;
  dealOfferAcceptAsPersona?: boolean;
  memberPropertyControl?: boolean;
  objectControl?: boolean;
  organizationGroupControl?: boolean;
  organizationGroupControlMember?: boolean;
  organizationGroupFind?: boolean;
  organizationGroupInvitationApproveAsGroup?: boolean;
  organizationGroupInvitationApproveAsOrganization?: boolean;
  organizationGroupPowerControl?: boolean;
  organizationGroupPowerFind?: boolean;
  organizationInviteMember?: boolean;
  organizationListMembers?: boolean;
  organizationPublish?: boolean;
  organizationRemoveMember?: boolean;
  organizationVoteOwner?: boolean;
  roleCreate?: boolean;
  roleFind?: boolean;
  sharedMemberPropertyControl?: boolean;
  sharedMemberPropertyFind?: boolean;
  treasureBoxBindObject?: boolean;
  treasureBoxDelete?: boolean;
  treasureBoxFind?: boolean;
  treasureBoxLend?: boolean;
  treasureBoxManage?: boolean;
  treasureBoxSet?: boolean;
  treasureDataFind?: boolean;
  treasureDataPresetControl?: boolean;
  treasureDataPresetRegister?: boolean;
  treasureDealApply?: boolean;
  treasureDealOfferAcceptAsOrganization?: boolean;
  treasureDealOfferAcceptAsPersona?: boolean;
}
export interface NullablePowerCaps extends NullableObjectPowerCaps {
  objectControl: boolean;
  sharedMemberPropertyControl: boolean;
  sharedMemberPropertyFind: boolean;
  treasureBoxBindObject: boolean;
  treasureBoxDelete: boolean;
  treasureBoxFind: boolean;
  treasureBoxLend: boolean;
  treasureBoxManage: boolean;
  treasureBoxSet: boolean;
  treasureDataFind: boolean;
  treasureDataPresetControl: boolean;
  treasureDataPresetRegister: boolean;
}
export interface OauthAccessToken {
  clientId: string;
  expiresAt: number;
  issuedAt: number;
  personaId: string;
  scope: string;
}
export interface OauthClient {
  frontchannelLogoutUri: string;
  id: string;
  idTokenSignedResponseAlg: string;
  logoUri: string;
  name: string;
  policyUri: string;
  redirectUris: string[];
  tosUri: string;
}
export interface OauthError {
  error: string;
}
export interface BitkeyObject {
  activated: boolean;
  code: string;
  createdAt: number;
  deleted: boolean;
  id: string;
  locked: boolean;
  name: string;
  publicKey: SerializedPublicKey;
  type: 'bitlock';
  updatedAt: number;
}
export interface ObjectTransfer {
  createdAt: number;
  from: Owner;
  id: string;
  objectId: string;
  status: 'applied' | 'accepted' | 'rejected';
  to: Owner;
  updatedAt: number;
}
export enum ObjectTypes {
  bitlock = 'bitlock',
}
export interface OidcUserinfo {
  email: string;
  emailVerified: boolean;
  sub: string;
  uid: string;
}
export interface Organization {
  createdAt: number;
  deleted: boolean;
  id: string;
  name: string;
  ownerPersonaId: string;
  public: boolean;
  switchable: boolean;
  updatedAt: number;
}
export interface OrganizationGroup {
  createdAt: number;
  deleted: boolean;
  id: string;
  name: string;
  ownerOrganizationId: string;
  updatedAt: number;
}
export interface OrganizationGroupHolePowerCaps extends NullableHolePowerCaps {
  enabled: boolean;
  holeId: string;
  organizationGroupId: string;
  organizationId: string;
}
export interface OrganizationGroupInvitation {
  approved: boolean;
  createdAt: number;
  deleted: boolean;
  id: string;
  invitee: OrganizationGroupInvitee;
  organizationGroupId: string;
  updatedAt: number;
}
export interface OrganizationGroupInvitee {
  id: string;
  isOrganizationGroup?: boolean;
}
export interface OrganizationGroupLentHolePowerCaps extends NullableLentHolePowerCaps {
  contractId: string;
  enabled: boolean;
  organizationGroupId: string;
  organizationId: string;
}
export interface OrganizationGroupObjectPowerCaps extends NullableObjectPowerCaps {
  enabled: boolean;
  objectId: string;
  organizationGroupId: string;
  organizationId: string;
}
export interface OrganizationGroupPersonaHoleRight extends NullableOrganizationGroupHolePower {
  holeId: string;
  organizationGroupId: string;
  personaId: string;
}
export interface OrganizationGroupPersonaLentHoleRight extends NullableOrganizationGroupLentHolePower {
  contractId: string;
  organizationGroupId: string;
  personaId: string;
}
export interface OrganizationGroupPersonaObjectRight extends NullableOrganizationGroupObjectPower {
  objectId: string;
  organizationGroupId: string;
  personaId: string;
}
export interface OrganizationGroupPersonaRight extends NullableOrganizationGroupPower {
  organizationGroupId: string;
  personaId: string;
}
export interface OrganizationGroupPowerCaps extends NullablePowerCaps {
  enabled: boolean;
  organizationGroupId: string;
  organizationId: string;
}
export interface OrganizationGroupPropagationHolePowers extends NullableHolePowerCaps {
  childOrganizationGroupId: string;
  holeId: string;
  originOrganizationGroupId: string;
  parentOrganizationGroupId: string;
}
export interface OrganizationGroupPropagationLentHolePowers extends NullableLentHolePowerCaps {
  childOrganizationGroupId: string;
  contractId: string;
  originOrganizationGroupId: string;
  parentOrganizationGroupId: string;
}
export interface OrganizationGroupPropagationObjectPowers extends NullableObjectPowerCaps {
  childOrganizationGroupId: string;
  objectId: string;
  originOrganizationGroupId: string;
  parentOrganizationGroupId: string;
}
export interface OrganizationGroupPropagationPowers extends NullablePowerCaps {
  childOrganizationGroupId: string;
  originOrganizationGroupId: string;
  parentOrganizationGroupId: string;
}
export interface OrganizationGroupRoleHolePower extends NullableOrganizationGroupHolePower {
  holeId: string;
  organizationGroupId: string;
  roleId: string;
}
export interface OrganizationGroupRoleLentHolePower extends NullableOrganizationGroupLentHolePower {
  contractId: string;
  organizationGroupId: string;
  roleId: string;
}
export interface OrganizationGroupRoleObjectPower extends NullableOrganizationGroupObjectPower {
  objectId: string;
  organizationGroupId: string;
  roleId: string;
}
export interface OrganizationGroupRolePower extends NullableOrganizationGroupPower {
  organizationGroupId: string;
  roleId: string;
}
export interface OrganizationInvitation {
  id: string;
}
export interface Owner {
  id: string;
  ownerTypes?: OwnerTypes;
}
export enum OwnerTypes {
  personal = 'personal',
  organization = 'organization',
  organization_group = 'organization_group',
}
export type Password = string;
export interface Period {
  endAtEpoch: Datetime;
  startAtEpoch: Datetime;
}
export interface Persona {
  createdAt: number;
  deleted: boolean;
  id: string;
  organizationId: string;
  public: boolean;
  roleId: string;
  updatedAt: number;
  userId: string;
}
export interface PersonaAuthenticationRequest {
  email?: string;
  mailTemplate?: MailTemplate;
  password: Password;
  phone?: string;
}
export interface PersonaHoleRight extends NullableHolePower {
  holeId: string;
  personaId: string;
}
export interface PersonaLentHoleRight extends NullableLentHolePower {
  contractId: string;
  personaId: string;
}
export interface PersonaObjectRight extends NullableObjectPower {
  objectId: string;
  personaId: string;
}
export interface PersonaRequiredAuthnTypes {
  authnTypes: AuthnTypes;
  roleId: string;
}
export interface PersonaRight extends NullablePower {
  personaId: string;
}
export interface PersonaWithDetails extends Persona {
  organizationName: string;
  roleName: string;
}
export interface Personality {
  isOrganization?: boolean;
  personalityId: string;
}
export interface PersonalityProperty {
  arbitraryPropertyId: string;
  memberPropertyId: string;
  memberPropertyLabelId: string;
  values: PropertyValue[];
}
export interface PersonalityPropertyParameter {
  arbitraryPropertyId: string;
  memberPropertyId: string;
  memberPropertyLabelId: string;
}
export enum PhoneCountryCode {
  JP = 'JP',
}
export interface Principal {
  aud: string;
  expiresAt: number;
  issuedAt: number;
  scope: string;
  sub: string;
  typ: string;
}
export interface PropertyAttachment {
  data: string;
  type: PropertyType;
}
export enum PropertyType {
  'nfc-tag' = 'nfc-tag',
  'face-image' = 'face-image',
}
export type PropertyValue = string;
export enum RepeatTypes {
  unrepeat = 'unrepeat',
  daily = 'daily',
  weekly = 'weekly',
  day_of_month = 'day_of_month',
  day_of_week_of_month = 'day_of_week_of_month',
}
export type ReservationCode = string;
export interface Role {
  createdAt: number;
  deleted: boolean;
  id: string;
  name: string;
  organizationId: string;
  updatedAt: number;
}
export interface RoleHolePower extends NullableHolePower {
  holeId: string;
  roleId: string;
}
export interface RoleLentHolePower extends NullableLentHolePower {
  contractId: string;
  roleId: string;
}
export interface RoleObjectPower extends NullableObjectPower {
  objectId: string;
  roleId: string;
}
export interface RolePower extends NullablePower {
  roleId: string;
}
export interface RoleRequiredAuthnTypes {
  authnTypes: AuthnTypes;
  roleId: string;
}
export type SerializedProtobufMassage = string;
export type SerializedPublicKey = string;
export interface ServiceAccount {
  createdAt: number;
  deleted: boolean;
  id: string;
  name: string;
  owner: Personality;
  roleId: string;
  suspended: boolean;
  updatedAt: number;
  userId: string;
}
export interface ServiceAccountHoleRight extends NullableHolePower {
  holeId: string;
  serviceAccountId: string;
}
export interface ServiceAccountObjectRight extends NullableObjectPower {
  objectId: string;
  serviceAccountId: string;
}
export interface ServiceAccountRight extends NullablePower {
  serviceAccountId: string;
}
export interface ServiceAccountShortPeriodToken {
  accessToken: string;
}
export interface ServiceAccountToken {
  accessToken: string;
  refreshToken: string;
}
export interface SharedMemberProperty {
  createdAt: number;
  memberPropertyId: string;
  organizationGroupId: string;
}
export type SignedTreasureDataSet = string[];
export interface TerminalAuth {
  nonce: string;
  personaPublicKeyId: string;
  signature: EcdsaSignature;
}
export interface TransferDetail {
  operationType: 'delete' | 'disable' | 'update';
  targetId: string;
  targetType:
    | 'object'
    | 'hole'
    | 'treasure_box'
    | 'object_relation'
    | 'treasure_box_object'
    | 'hole_lend_contract'
    | 'role_power'
    | 'role_object_power'
    | 'role_hole_power'
    | 'role_lent_hole_power'
    | 'persona_right'
    | 'persona_object_right'
    | 'persona_hole_right'
    | 'persona_lent_hole_right'
    | 'deal'
    | 'deal_offer'
    | 'treasure_deal'
    | 'treasure_deal_offer'
    | 'treasure_data_preset'
    | 'propagation_power'
    | 'propagation_object_power'
    | 'propagation_hole_power'
    | 'propagation_lent_hole_power';
}
export interface TransferDetails {
  details: TransferDetail[];
  transferId: string;
}
export interface TreasureBox {
  activated: boolean;
  createdAt: number;
  deleted: boolean;
  id: string;
  locked: boolean;
  name: string;
  owner: Personality;
  ownerType: 'personal' | 'organization' | 'organization_group';
  ownershipSignature: string;
  treasureBoxType: 'bitreader' | 'bitface';
  udid: string;
  updatedAt: number;
}
export interface TreasureBoxLendContract {
  borrower: Borrower;
  contractPeriod: Period;
  createdAt: number;
  disabled: boolean;
  holeId: string;
  id: string;
  lender: Lender;
  powers: {
    treasureBoxLend: boolean;
    treasureBoxManage: boolean;
    treasureDataFind: boolean;
    treasureDataPresetRegister: boolean;
    treasureDealControl: boolean;
  };
  treasureBoxId: string;
  updatedAt: number;
}
export interface TreasureBoxLendContractDealOffer {
  acceptOnlyOne: boolean;
  acceptanceTimeLimitEpoch: Datetime;
  acceptors: Personality[];
  candidates: DealOfferCandidate[];
  contractPeriod: Period;
  createdAt: number;
  holeId: string;
  id: string;
  lender: Lender;
  offerFrom: Personality;
  powers: {
    treasureBoxLend: boolean;
    treasureBoxManage: boolean;
    treasureDataFind: boolean;
    treasureDataPresetRegister: boolean;
    treasureDealControl: boolean;
  };
  treasureBoxId: string;
  updatedAt: number;
}
export interface TreasureBoxTransfer {
  createdAt: number;
  from: Owner;
  id: string;
  status: 'applied' | 'accepted' | 'rejected';
  to: Owner;
  treasureBoxId: string;
  updatedAt: number;
}
export enum TreasureBoxType {
  bitreader = 'bitreader',
  bitface = 'bitface',
}
export interface TreasureBroker {
  createdAt: number;
  deleted: boolean;
  id: string;
  name: string;
  requestedBy: Personality;
  suspended: boolean;
  updatedAt: number;
}
export interface TreasureBrokerToken {
  accessToken: string;
  refreshToken: string;
}
export interface TreasureDataPreset {
  holeId: string;
  id: string;
  personalityProperties: PersonalityProperty[];
  treasureBoxId: string;
}
export interface TreasureDataSet {
  boxId: string;
  boxUdid: string;
  signedTreasureDataSet: SignedTreasureDataSet;
}
export interface TreasureDataSetWithPropertySize {
  boxId: string;
  boxUdid: string;
  propertySize: number;
  signedTreasureDataSet: SignedTreasureDataSet;
}
export interface TreasureDeal {
  applyFrom: Personality;
  applyTo: Personality;
  createdAt: number;
  holeId: string;
  id: string;
  objectId: string;
  permitteeProperties: PersonalityProperty[];
  repeatFirstDayOfWeek: 'sun' | 'mon' | 'tue' | 'wed' | 'thu' | 'fri' | 'sat';
  repeatInterval: number;
  repeatStartDate: string;
  repeatType: RepeatTypes;
  repeatUseMinutes: number;
  repeatUseStartAt: number;
  repeatWeekOfMonth: number;
  repeatWeeklyDays: Days[];
  status: DealStatuses;
  treasureBoxId: string;
  updatedAt: number;
  usageLimit: number;
  usagePeriod: Period;
}
export interface TreasureDealOffer {
  acceptOnlyOne: boolean;
  acceptanceTimeLimitEpoch: Datetime;
  acceptorProperties: PersonalityProperty[];
  candidates: Personality[];
  createdAt: number;
  holeId: string;
  id: string;
  offerFrom: Personality;
  repeatFirstDayOfWeek: 'sun' | 'mon' | 'tue' | 'wed' | 'thu' | 'fri' | 'sat';
  repeatInterval: number;
  repeatStartDate: string;
  repeatType: RepeatTypes;
  repeatUseMinutes: number;
  repeatUseStartAt: number;
  repeatWeekOfMonth: number;
  repeatWeeklyDays: Days[];
  treasureBoxId: string;
  updatedAt: number;
  usageLimit: number;
  usagePeriod: Period;
}
export interface PersonaPublicKeyId {
  personaPublicKeyId?: string;
}
const meta = {
  acceptanceTimeLimitEpoch: 'Datetime',
  endAtEpoch: 'Datetime',
  startAtEpoch: 'Datetime',
};

export interface RefreshAccessTokenRequest {
  refreshToken: string;
}

export interface VerifyAccessTokenResponse {
  expiresAt: number;
  issuedAt: number;
  organizationId: string;
  personaId: string;
}

export interface AddDealOfferRequest {
  acceptOnlyOne?: boolean;
  acceptanceTimeLimitEpoch?: Datetime;
  candidates?: Personality[];
  holeId: string;
  issuePassCode?: boolean;
  publishPeriod?: Period;
  repeatFirstDayOfWeek?: 'sun' | 'mon' | 'tue' | 'wed' | 'thu' | 'fri' | 'sat';
  repeatInterval?: number;
  repeatStartDate?: string;
  repeatType?: RepeatTypes;
  repeatUseMinutes?: number;
  repeatUseStartAt?: number;
  repeatWeekOfMonth?: number;
  repeatWeeklyDays?: Days[];
  usageLimit?: number;
  usagePeriod?: Period;
}

export interface AddDealOfferResponse extends DealOffer {
  passCode: string;
}

export interface AddTreasureBoxLendContractDealOfferRequest {
  acceptOnlyOne?: boolean;
  acceptanceTimeLimitEpoch?: Datetime;
  candidates?: DealOfferCandidate[];
  contractPeriod?: Period;
  holeId: string;
  issuePassCode?: boolean;
  lender?: Lender;
  powers: {
    treasureBoxLend?: boolean;
    treasureBoxManage?: boolean;
    treasureDataFind?: boolean;
    treasureDataPresetRegister?: boolean;
    treasureDealControl?: boolean;
  };
  treasureBoxId: string;
}

export interface AddTreasureBoxLendContractDealOfferResponse extends TreasureBoxLendContractDealOffer {
  passCode: string;
}

export interface UpdateTreasureBoxLendContractDealOfferRequest {
  acceptanceTimeLimitEpoch: Datetime;
}

export interface AcceptTreasureBoxLendContractDealOfferRequest {
  isOrganization: boolean;
  passCode?: string;
}

export interface UpdateDealOfferRequest {
  acceptanceTimeLimitEpoch: Datetime;
}

export interface AcceptDealOfferRequest {
  isOrganization: boolean;
  passCode?: string;
}

export interface AddDealRequest {
  applyTo: Personality;
  holeId?: string;
  objectId?: string;
  permittee?: Personality[];
  publishPeriod?: Period;
  repeatFirstDayOfWeek?: 'sun' | 'mon' | 'tue' | 'wed' | 'thu' | 'fri' | 'sat';
  repeatInterval?: number;
  repeatStartDate?: string;
  repeatType?: RepeatTypes;
  repeatUseMinutes?: number;
  repeatUseStartAt?: number;
  repeatWeekOfMonth?: number;
  repeatWeeklyDays?: Days[];
  usageLimit?: number;
  usagePeriod?: Period;
}

export interface ApproveDealRequest {
  holeId?: string;
  objectId?: string;
  publishPeriod?: Period;
  repeatFirstDayOfWeek?: 'sun' | 'mon' | 'tue' | 'wed' | 'thu' | 'fri' | 'sat';
  repeatInterval?: number;
  repeatStartDate?: string;
  repeatType?: 'unrepeat' | 'daily' | 'weekly' | 'day_of_month' | 'day_of_week_of_month';
  repeatUseMinutes?: number;
  repeatUseStartAt?: number;
  repeatWeekOfMonth?: number;
  repeatWeeklyDays?: Days[];
  usageLimit?: number;
  usagePeriod?: Period;
}

export interface AddHoleRequest {
  code?: string;
  name: string;
  objectId: string;
  ownerGroupId?: string;
  usePersonaSign?: boolean;
}

export interface AddHoleResponse extends Hole {
  configuration: HoleConfiguration;
}

export interface AddHoleContractRequest {
  borrowRequest?: boolean;
  borrower?: Borrower;
  contractPeriod?: Period;
  lender?: Lender;
  powers?: {
    dealControl?: boolean;
    holeGetKey?: boolean;
    holeLend?: boolean;
    holeUpdateKey?: boolean;
  };
}

export interface LendHoleRequest extends Personality, Period {
  powers?: {
    dealControl?: boolean;
    holeGetKey?: boolean;
    holeLend?: boolean;
    holeUpdateKey?: boolean;
  };
}

export interface LendHoleResponse {
  borrower: HoleBorrower;
  createdAt: number;
  disabled: boolean;
  id: string;
  lender: Personality;
  updatedAt: number;
}

export interface UpdateHoleKeysResponse extends Hole {
  configuration: HoleConfiguration;
}

export interface JoinToOrganizationRequest {
  invitationIdSignature?: EcdsaSignature;
  password: Password;
  personaPublicKey?: JwkObject;
  phone?: string;
}

export interface SignUpToOrganizationRequest {
  invitationIdSignature?: EcdsaSignature;
  mailTemplate?: MailTemplate;
  password: Password;
  personaPublicKey?: JwkObject;
  phone?: string;
  public?: boolean;
}

export interface OauthTokenResponse {
  accessToken: string;
  expiresIn: number;
  idToken: string;
  refreshToken: string;
  scope: string;
  tokenType: string;
}

export interface FindApprovedOAUTHClientResponse extends OauthClient {
  scope: string;
}

export interface AddObjectRequest {
  name: string;
  ownerGroupId?: string;
}

export interface ActivateObjectRequest {
  code?: string;
  type?: 'bitlock';
}

export interface ActivateObjectKeyPairRequest {
  activateCode: string;
}

export interface CreateObjectKeyPairResponse {
  activateCode: string;
  publicKey: SerializedPublicKey;
  publicKeySignature: EcdsaSignature;
}

export interface AddObjectTransferRequest {
  from: Owner;
  to: Owner;
}

export interface AddOrganizationGroupRequest {
  name: string;
}

export interface UpdateOrganizationGroupRequest {
  name: string;
}

export interface UpdateOrgGrpLentHolePowerCapsRequest extends NullableLentHolePowerCaps {
  enabled?: boolean;
  organizationId: string;
}

export interface UpdateOrgGrpPropagationLentHolePowerRequest extends NullableLentHolePowerCaps {
  childOrganizationGroupId: string;
}

export interface UpdateOrgGrpHolePowerCapsRequest extends NullableHolePowerCaps {
  enabled?: boolean;
  organizationId: string;
}

export interface UpdateOrgGrpPropagationHolePowerRequest extends NullableHolePowerCaps {
  childOrganizationGroupId: string;
}

export interface AddOrganizationGroupInvitationRequest {
  organizationGroupId?: string;
  organizationId?: string;
}

export interface UpdateOrgGrpObjPowerCapsRequest extends NullableObjectPowerCaps {
  enabled?: boolean;
  organizationId: string;
}

export interface UpdateOrgGrpPropagationObjectPowerRequest extends NullableObjectPowerCaps {
  childOrganizationGroupId: string;
}

export interface UpdateOrgGrpPowerCapsRequest extends NullablePowerCaps {
  enabled?: boolean;
  organizationId: string;
}

export interface UpdateOrgGrpPropagationPowerRequest extends NullablePowerCaps {
  childOrganizationGroupId: string;
  originOrganizationGroupId: string;
}

export interface AddOrganizationRequest {
  name: string;
  public: boolean;
}

export interface UpdateOrganizationRequest {
  name: string;
}

export interface UpdateOrganizationOwnerElectionSystemRequest {
  electionType: number;
  threshold: number;
}

export interface AddOrganizationOwnerElectionSystemRequest {
  electionType: number;
  threshold: number;
}

export interface InviteToOrganizationRequest {
  email?: string;
  mailTemplate?: MailTemplate;
  phone?: string;
  phoneCountryCode?: PhoneCountryCode;
  public?: boolean;
  roleId: string;
}

export interface AddMemberPropertyRequest {
  memberPropertyLabelId?: string;
  multipleAssignees?: boolean;
  propertyName?: string;
  propertyValue: PropertyValue;
}

export interface UpdateMemberPropertyRequest {
  memberPropertyLabelId?: string;
  multipleAssignees?: boolean;
  propertyName?: string;
  propertyValue?: string;
}

export interface AddSharedMemberPropertyRequest {
  organizationGroupId: string;
}

export interface DeleteSharedMemberPropertyRequest {
  organizationGroupId: string;
}

export interface AddMemberPropertyLabelRequest {
  name: string;
}

export interface UpdateMemberPropertyLabelRequest {
  name: string;
}

export interface AddMemberPropertySubmissionsRequest {
  arbitraryPropertyId: string;
  memberPropertyLabelId: string;
  multipleAssignees?: boolean;
}

export interface AddPersonaToOrganizationRequest {
  mailTemplate?: MailTemplate;
  public?: boolean;
  roleId?: string;
  userAliasCode?: string;
  userId?: string;
}

export interface UpdatePersonaRoleRequest {
  newRoleId: string;
}

export interface UpdateOrganizationOwnerRequest {
  newOwnerPersonaId: string;
}

export interface AddRoleRequest {
  name?: string;
}

export interface AddServiceAccountRequest {
  name: string;
  roleId?: string;
}

export interface AddServiceAccountResponse extends ServiceAccount {
  token: ServiceAccountToken;
}

export interface UpdateServiceAccountRequest {
  name: string;
  roleId?: string;
}

export interface RefreshServiceAccountAccessTokenRequest {
  refreshToken: string;
}

export interface VerifyServiceAccountAccessTokenResponse {
  expiresAt: number;
  issuedAt: number;
  serviceAccountId: string;
}

export interface DeactivateServiceAccountRefreshTokensRequest {
  refreshToken: string;
}

export interface UpdateServiceAccountSuspendedRequest {
  suspended?: boolean;
}

export interface UpdateSwitchableRequest {
  switchable: boolean;
}

export interface ResetPasswordBulklyRequest {
  bulkResetToken: string;
  mailTemplate?: MailTemplate;
  password: Password;
  sessionId?: string;
}

export interface IssueBulkPasswordResetTokenRequest {
  email?: string;
  mailTemplate?: MailTemplate;
  phone?: string;
}

export interface IssueBulkPasswordResetTokenResponse {
  sessionId: string;
}

export interface UpdatePersonaPasswordBulklyRequest {
  newPassword: Password;
  oldPassword: Password;
}

export interface ResetPasswordRequest {
  password: Password;
  resetToken: string;
}

export interface IssuePasswordResetTokenRequest {
  email?: string;
  mailTemplate?: MailTemplate;
  personaId?: string;
  phone?: string;
}

export interface UpdatePersonaEmailRequest {
  emailUpdateCode: string;
}

export interface StartUpdatePersonaEmailRequest {
  email: string;
  mailTemplate?: MailTemplate;
}

export interface UpdatePersonaPhoneRequest {
  phoneUpdateCode: string;
}

export interface StartUpdatePersonaPhoneRequest {
  mailTemplate?: MailTemplate;
  phone: string;
  phoneCountryCode?: PhoneCountryCode;
}

export interface AuthenticatePersonaRequest {
  mailTemplate?: MailTemplate;
  multipleChoiceSessionKey?: string;
  password?: Password;
  terminalAuth?: TerminalAuth;
}

export interface IssueNonceResponse {
  nonce: string;
}

export interface AuthenticatePersonaWithPassCodeRequest {
  mailTemplate?: MailTemplate;
  multipleChoiceSessionKey?: string;
  passCode: string;
  password?: Password;
}

export interface SwitchPersonaWithPassCodeRequest {
  passCode: string;
}

export interface UpdatePersonaPasswordRequest {
  newPassword: Password;
  oldPassword: Password;
}

export interface AddPersonaMemberPropertyRequest {
  memberPropertyId: string;
}

export interface UpdatePersonaPublicRequest {
  public?: boolean;
}

export interface RegisterPersonaPublicKeyRequest {
  nonce: string;
  nonceSignature: EcdsaSignature;
  personaPublicKey: JwkObject;
}

export interface RegisterPersonaPublicKeyResponse {
  personaPublicKeyId: string;
}

export interface SwitchPersonaRequest {
  mailTemplate?: MailTemplate;
  terminalAuth?: TerminalAuth;
}

export interface UpdatePersonaTerminalAuthConfigRequest {
  enabled?: boolean;
}

export interface DeactivateRefreshTokensRequest {
  refreshToken: string;
}

export interface AddServiceAccountByPersonalOrOrgRequest {
  name: string;
  roleId?: string;
}

export interface AddServiceAccountByPersonalOrOrgResponse extends ServiceAccount {
  token: ServiceAccountToken;
}

export interface IssueServiceAccountShortPeriodTokenRequest {
  reservationCode: string;
  treasureBoxId: string;
}

export interface UpdateServiceAccountByPersonalOrOrgRequest {
  name: string;
  roleId?: string;
}

export interface RefreshServiceAccountAccessTokenByPersonalOrOrgRequest {
  refreshToken: string;
}

export interface VerifyServiceAccountAccessTokenByPersonalOrOrgResponse {
  expiresAt: number;
  issuedAt: number;
  serviceAccountId: string;
}

export interface DeactivateServiceAccountRefreshTokensByPersonalOrOrgRequest {
  refreshToken: string;
}

export interface IssueServiceAccountTokenReservationCodeRequest {
  treasureBoxIds: string[];
}

export interface AddTreasureBoxRequest {
  name: string;
  ownerGroupId?: string;
  treasureBoxType?: 'bitreader' | 'bitface';
}

export interface AddTreasureBoxResponse extends TreasureBox {
  treasureBoxSetRequest: SerializedProtobufMassage;
}

export interface DeleteTreasureBoxResponse {
  treasureBoxDeleteRequest: SerializedProtobufMassage;
}

export interface ActivateTreasureBoxRequest {
  treasureBoxSetResponse: SerializedProtobufMassage;
  udid: string;
}

export interface AddTreasureBoxTransferRequest {
  from: Owner;
  to: Owner;
}

export interface GetLastUpdatedAtResponse {
  lastUpdatedAt: number;
}

export interface AddTreasureBrokerRequest {
  name: string;
  ownerGroupId?: string;
}

export interface AddTreasureBrokerResponse extends TreasureBroker {
  token: TreasureBrokerToken;
}

export interface RefreshBrokerAccessTokenRequest {
  refreshToken: string;
}

export interface VerifyBrokerAccessTokenResponse {
  expiresAt: number;
  issuedAt: number;
  treasureBrokerId: string;
}

export interface UpdateTreasureBrokerNameRequest {
  name: string;
}

export interface DeactivateBrokerRefreshTokensRequest {
  refreshToken: string;
}

export interface UpdateTreasureBrokerSuspendedRequest {
  suspended?: boolean;
}

export interface AddTreasureDataPresetRequest {
  holeId: string;
  personalityProperties?: PersonalityPropertyParameter[];
  treasureBoxId: string;
}

export interface UpdateTreasureDataPresetRequest {
  personalityProperties?: PersonalityPropertyParameter[];
}

export interface DeleteTreasureDataPresetResponse {
  deleteTreasureDataRequest: SerializedProtobufMassage;
}

export interface AddTreasureDataPresetsWithoutHoleRequest {
  personalityProperties?: PersonalityPropertyParameter[];
  treasureBoxId: string;
}

export interface AddTreasureDealOfferRequest {
  acceptOnlyOne?: boolean;
  acceptanceTimeLimitEpoch?: Datetime;
  candidates?: Personality[];
  holeId: string;
  issuePassCode?: boolean;
  repeatFirstDayOfWeek?: 'sun' | 'mon' | 'tue' | 'wed' | 'thu' | 'fri' | 'sat';
  repeatInterval?: number;
  repeatStartDate?: string;
  repeatType?: RepeatTypes;
  repeatUseMinutes?: number;
  repeatUseStartAt?: number;
  repeatWeekOfMonth?: number;
  repeatWeeklyDays?: Days[];
  treasureBoxId: string;
  usageLimit?: number;
  usagePeriod?: Period;
}

export interface AddTreasureDealOfferResponse extends TreasureDealOffer {
  passCode: string;
}

export interface UpdateTreasureDealOfferRequest {
  acceptanceTimeLimitEpoch: Datetime;
}

export interface DeleteTreasureDealOfferResponse {
  deleteTreasureDataRequest: SerializedProtobufMassage;
}

export interface AcceptTreasureDealOfferRequest {
  arbitraryPropertyId?: string;
  isOrganization: boolean;
  memberPropertyId?: string;
  memberPropertyLabelId?: string;
  passCode?: string;
}

export interface AddTreasureDealRequest {
  applyTo: Personality;
  holeId?: string;
  objectId?: string;
  permitteeProperties?: PersonalityPropertyParameter[];
  repeatFirstDayOfWeek?: 'sun' | 'mon' | 'tue' | 'wed' | 'thu' | 'fri' | 'sat';
  repeatInterval?: number;
  repeatStartDate?: string;
  repeatType?: RepeatTypes;
  repeatUseMinutes?: number;
  repeatUseStartAt?: number;
  repeatWeekOfMonth?: number;
  repeatWeeklyDays?: Days[];
  treasureBoxId?: string;
  usageLimit?: number;
  usagePeriod?: Period;
}

export interface ApproveTreasureDealRequest {
  holeId?: string;
  objectId?: string;
  repeatFirstDayOfWeek?: 'sun' | 'mon' | 'tue' | 'wed' | 'thu' | 'fri' | 'sat';
  repeatInterval?: number;
  repeatStartDate?: string;
  repeatType?: 'unrepeat' | 'daily' | 'weekly' | 'day_of_month' | 'day_of_week_of_month';
  repeatUseMinutes?: number;
  repeatUseStartAt?: number;
  repeatWeekOfMonth?: number;
  repeatWeeklyDays?: Days[];
  treasureBoxId?: string;
  usageLimit?: number;
  usagePeriod?: Period;
}

export interface DisableTreasureDealResponse {
  deleteTreasureDataRequest: SerializedProtobufMassage;
}

export interface AddUserRequest {
  email?: string;
  mailTemplate?: MailTemplate;
  phone?: string;
  phoneCountryCode?: PhoneCountryCode;
}

export interface AddUserResponse {
  signupSessionId: string;
}

export interface ActivateUserRequest {
  authorizationCode: string;
  authorizationCodeSignature?: EcdsaSignature;
  password: Password;
  personaPublicKey?: JwkObject;
  public?: boolean;
  signupSessionId: string;
}

export interface ActivateUserResponse extends AuthenticationResult {
  personaPublicKeyId: string;
}

export interface IssueUserAliasCodeResponse {
  userAliasCode: string;
}

export interface AddArbitraryPropertyRequest {
  name: string;
  value: PropertyValue;
}

export interface UpdateArbitraryPropertyNameRequest {
  name: string;
}

export interface UpdateArbitraryPropertyValueRequest {
  value: string;
}

export interface StartContactRegistrationRequest {
  name: string;
  templateType?: MailTemplate;
  type: ContactType;
  value: string;
}

export interface ActivateContactRequest {
  activationCode: string;
}

export interface UpdateUserEmailRequest {
  emailUpdateCode: string;
}

export interface StartUpdateUserEmailRequest {
  email: string;
  mailTemplate?: MailTemplate;
}

export interface ActivatePersonaRequest {
  emailVerificationCode?: string;
  nonce?: string;
  nonceSignature?: EcdsaSignature;
  password: Password;
  personaPublicKey?: JwkObject;
}

export interface ActivatePersonaResponse extends AuthenticationResult {
  personaPublicKeyId: string;
}

export interface ReissueUserIdResponse {
  newUserId: string;
}

export interface GetVersionResponse {
  gitCommit: string;
  gitTag: string;
}

export interface ListDealOffersQuery {
  limit?: number;
  offset?: number;
}

export interface ListTreasureBoxLendContractDealOffersQuery {
  limit?: number;
  offset?: number;
}

export enum FindDealsDealType {
  applicant = 'applicant',
  approver = 'approver',
}

export enum FindDealsDealStatus {
  applied = 'applied',
  approved = 'approved',
  rejected = 'rejected',
  disabled = 'disabled',
}

export interface FindDealsQuery {
  dealType?: FindDealsDealType;
  dealStatus?: FindDealsDealStatus;
  applyAsOrganization?: boolean;
  limit?: number;
  offset?: number;
}

export interface ListHoleContractsQuery {
  state?: string;
  organizationGroupId?: string;
  disabled?: boolean;
  before?: number;
  after?: number;
  object?: string;
  limit?: number;
  offset?: number;
  status?: string;
}

export interface ListBorrowersQuery {
  limit?: number;
  offset?: number;
}

export interface ListContractsByHoleQuery {
  limit?: number;
  offset?: number;
}

export enum ListObjectTransfersByToOwnerIDStatus {
  applied = 'applied',
  accepted = 'accepted',
  rejected = 'rejected',
}

export interface ListObjectTransfersByToOwnerIDQuery {
  status?: ListObjectTransfersByToOwnerIDStatus;
  limit?: number;
  offset?: number;
}

export interface ListObjectTransferDetailsQuery {
  organizationGroupId?: string;
  limit?: number;
  offset?: number;
}

export interface ListObjectsQuery {
  organizationGroupId?: string;
}

export interface ListObjectTransferQuery {
  limit?: number;
  offset?: number;
  organizationGroupId?: string;
}

export interface ListOrganizationGroupAncestorsQuery {
  limit?: number;
  offset?: number;
}

export interface ListOrganizationGroupDescendantsQuery {
  limit?: number;
  offset?: number;
}

export interface ListOrgGrpLentHolePowerCapsQuery {
  limit?: number;
  offset?: number;
  organizationId?: string;
}

export interface ListOrgGrpPropagationLentHolePowersQuery {
  limit?: number;
  offset?: number;
  childOrganizationGroupId?: string;
}

export interface ListOrgGrpHolePowerCapsQuery {
  limit?: number;
  offset?: number;
  organizationId?: string;
}

export interface ListOrgGrpPropagationHolePowersQuery {
  limit?: number;
  offset?: number;
  childOrganizationGroupId?: string;
}

export interface ListOrganizationGroupInvitationQuery {
  isInvitee?: boolean;
  limit?: number;
  offset?: number;
}

export interface ListOrgGrpObjPowerCapsQuery {
  limit?: number;
  offset?: number;
  organizationId?: string;
}

export interface ListOrgGrpPropagationObjectPowersQuery {
  limit?: number;
  offset?: number;
  childOrganizationGroupId?: string;
}

export interface ListOrganizationsByOrganizationGroupQuery {
  limit?: number;
  offset?: number;
}

export interface ListOrgGrpPowerCapsQuery {
  limit?: number;
  offset?: number;
  organizationId?: string;
}

export interface ListOrgGrpPropagationPowersQuery {
  limit?: number;
  offset?: number;
  childOrganizationGroupId?: string;
}

export interface ListSharedMemberPropertiesQuery {
  limit?: number;
  offset?: number;
  organizationId?: string;
}

export interface ListGroupInvitationQuery {
  limit?: number;
  offset?: number;
}

export interface ListMemberPropertyQuery {
  limit?: number;
  offset?: number;
  assigned?: boolean;
  embed?: 'persona_id'[];
}

export interface ListMemberPropertyLabelsQuery {
  limit?: number;
  offset?: number;
}

export interface ListMemberPropertySubmissionsQuery {
  limit?: number;
  offset?: number;
  personaId?: string;
}

export interface ListOrganizationGroupsByOrganizationQuery {
  limit?: number;
  offset?: number;
}

export interface ListTreasureBoxLendContractsQuery {
  state?: string;
  organizationGroupId?: string;
  disabled?: boolean;
  before?: number;
  after?: number;
  treasureBoxId?: string;
  limit?: number;
  offset?: number;
}

export enum ListTreasureBoxTransfersByToOwnerIDStatus {
  applied = 'applied',
  accepted = 'accepted',
  rejected = 'rejected',
}

export interface ListTreasureBoxTransfersByToOwnerIDQuery {
  status?: ListTreasureBoxTransfersByToOwnerIDStatus;
  limit?: number;
  offset?: number;
}

export interface ListTreasureBoxTransferDetailsQuery {
  organizationGroupId?: string;
  limit?: number;
  offset?: number;
}

export enum ListTreasureBoxesTreasureBoxType {
  bitreader = 'bitreader',
  bitface = 'bitface',
}

export interface ListTreasureBoxesQuery {
  limit?: number;
  offset?: number;
  activated?: boolean;
  deleted?: boolean;
  ownerGroupId?: string;
  treasureBoxType?: ListTreasureBoxesTreasureBoxType;
}

export enum ListTreasureDataInSpecificBoxDatasourceType {
  treasure_data_preset = 'treasure_data_preset',
}

export interface ListTreasureDataInSpecificBoxQuery {
  startAtEpoch?: number;
  expiredAtEpoch?: number;
  datasourceType: ListTreasureDataInSpecificBoxDatasourceType;
  limit?: number;
  offset?: number;
}

export interface ListTreasureBrokersQuery {
  organizationGroupId?: string;
}

export enum ListTreasureDataDatasourceType {
  treasure_deal = 'treasure_deal',
  treasure_deal_offer = 'treasure_deal_offer',
  treasure_data_preset = 'treasure_data_preset',
}

export interface ListTreasureDataQuery {
  boxIds: string[];
  startAtEpoch?: number;
  expiredAtEpoch?: number;
  datasourceId?: string;
  datasourceType?: ListTreasureDataDatasourceType;
}

export interface ListTreasureDataPresetsQuery {
  treasureBoxId: string;
  embed?: 'property_value'[];
  propertyValue?: string;
  memberPropertyLabelId?: string;
  limit?: number;
  offset?: number;
  deleted?: boolean;
}

export interface ListTreasureDealOffersQuery {
  limit?: number;
  offset?: number;
  deleted?: boolean;
}

export enum ListTreasureDealsDealType {
  applicant = 'applicant',
  approver = 'approver',
}

export enum ListTreasureDealsDealStatus {
  applied = 'applied',
  approved = 'approved',
  rejected = 'rejected',
  disabled = 'disabled',
}

export interface ListTreasureDealsQuery {
  dealType?: ListTreasureDealsDealType;
  dealStatus?: ListTreasureDealsDealStatus;
  applyAsOrganization?: boolean;
  limit?: number;
  offset?: number;
}

export interface ListArbitraryPropertyQuery {
  limit?: number;
  offset?: number;
}

export type Datetime = number | Date | string | Moment;
const camel = val => val.replace(/_\w/g, match => match.charAt(1).toUpperCase());
const snake = val => val.replace(/[A-Z]/g, match => '_' + match.toLowerCase());

function toCamel(data) {
  if (!(data instanceof Object)) {
    return data;
  }
  if (Array.isArray(data)) {
    const res: any[] = [];
    for (const d of data) {
      res.push(toCamel(d));
    }
    return res;
  }
  const res = {};
  for (const k of Object.keys(data)) {
    res[camel(k)] = toCamel(data[k]);
  }
  return res;
}

function toSnake(data) {
  if (!(data instanceof Object)) {
    return data;
  }
  if (Array.isArray(data)) {
    const res: any[] = [];
    for (const d of data) {
      res.push(toSnake(d));
    }
    return res;
  }
  const res = {};
  for (const k of Object.keys(data)) {
    res[snake(k)] = toSnake(data[k]);
  }
  return res;
}

const toDate = (value: number) => new Date(value);
const fromDate = (value: Date) => (value.getTime ? value.getTime() : value);
const toUTCString = (value: number) => new Date(value).toUTCString();
const fromUTCString = (value: string) => new Date(value).getTime();
const toMoment = (value: number) => moment(value);
const fromMoment = (value: Moment) => value.unix();

export interface Logger {
  log: (...s: any[]) => void;
  warn: (...s: any[]) => void;
  error: (...s: any[]) => void;
}

export enum DatetimeType {
  UNIX = 'UNIX',
  UTC_STRING = 'UTC_STRING',
  DATE = 'DATE',
  MOMENT = 'MOMENT',
}

export enum Typ {
  OAuth = 'oc',
  Persona = 'p',
}

export enum TokenType {
  OAuthToken = 'OAuthToken',
  PersonaToken = 'PersonaToken',
  Unknown = 'Unknown',
}

export interface Option {
  datetimeType?: DatetimeType;
  tokenType?: TokenType;
  logger?: Logger;
  updateTokens?: (accessToken: string, refreshToken: string) => Promise<void>;
}
export default class BitkeyPlatformAPI {
  private static instance: BitkeyPlatformAPI;
  private logger: Logger;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  private refreshToken: string;

  public static init(bkpUrl: string, option?: Option): void {
    BitkeyPlatformAPI.instance = new BitkeyPlatformAPI(bkpUrl, option);
  }

  public static getInstance = () => BitkeyPlatformAPI.instance;

  private axiosWithToken: AxiosInstance;
  private axiosWithoutToken: AxiosInstance;
  private datetimeConverter: {
    toRequest: (val) => any;
    toResponse: (val) => any;
  };
  private readonly bkpUrl: string;

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  private updateTokens: (accessToken: string, refreshToken: string) => Promise<void>;

  private constructor(bkpUrl: string, option?: Option) {
    if (!option) {
      option = {};
    }
    if (!option.logger) {
      option.logger = {
        log: console.log,
        warn: console.warn,
        error: console.error,
      };
    }
    if (!option.datetimeType) {
      option.datetimeType = DatetimeType.DATE;
    }
    this.bkpUrl = bkpUrl;
    this.logger = option.logger!;
    switch (option.datetimeType!) {
      case DatetimeType.DATE:
        this.datetimeConverter = {
          toRequest: fromDate,
          toResponse: toDate,
        };
        break;
      case DatetimeType.UNIX:
        this.datetimeConverter = {
          toRequest: val => val,
          toResponse: val => val,
        };
        break;
      case DatetimeType.UTC_STRING:
        this.datetimeConverter = {
          toRequest: fromUTCString,
          toResponse: toUTCString,
        };
        break;
      case DatetimeType.MOMENT:
        this.datetimeConverter = {
          toRequest: fromMoment,
          toResponse: toMoment,
        };
        break;
      default:
        this.datetimeConverter = {
          toRequest: fromDate,
          toResponse: toDate,
        };
        break;
    }
    if (option.updateTokens) {
      this.updateTokens = option.updateTokens;
    }
    this.axiosWithToken = axios.create({
      baseURL: bkpUrl,
      headers: {'Content-Type': 'application/json'},
    });
    this.axiosWithoutToken = axios.create({
      baseURL: bkpUrl,
      headers: {'Content-Type': 'application/json'},
    });
  }

  public static setTokens = (accessToken: string, refreshToken: string) => {
    BitkeyPlatformAPI.instance.axiosWithToken.defaults.headers['X-Api-Key'] = accessToken;
    BitkeyPlatformAPI.instance.refreshToken = refreshToken;
  };

  public static getAccessToken = async () => {
    let authResult: AuthenticationResult;
    try {
      const verifyResult = await BitkeyPlatformAPI.verifyAccessToken();
      if (moment(verifyResult.expiresAt * 1000).isBefore(moment().add(10, 'm'))) {
        try {
          authResult = await BitkeyPlatformAPI.refreshAccessToken({
            refreshToken: BitkeyPlatformAPI.instance.refreshToken,
          });
        } catch (e) {
          // どうしようもないやつ。TODO 今時間ないのでとりあえず空文字返すけどthrowしてログイン画面に飛ばさないとだめ。わりとすぐやる
          return '';
        }
      } else {
        return BitkeyPlatformAPI.instance.axiosWithToken.defaults.headers['X-Api-Key'];
      }
    } catch (e) {
      try {
        authResult = await BitkeyPlatformAPI.refreshAccessToken({
          refreshToken: BitkeyPlatformAPI.instance.refreshToken,
        });
      } catch (e) {
        // どうしようもないやつ。TODO 今時間ないのでとりあえず空文字返すけどthrowしてログイン画面に飛ばさないとだめ。わりとすぐやる
        return '';
      }
    }
    await BitkeyPlatformAPI.instance.updateTokens(authResult.accessToken, authResult.refreshToken);
    BitkeyPlatformAPI.setTokens(authResult.accessToken, authResult.refreshToken);
    return authResult.accessToken;
  };

  public static testMode = testApiUrl => {
    if (BitkeyPlatformAPI.instance.axiosWithToken.defaults.baseURL === testApiUrl) return;
    BitkeyPlatformAPI.instance.axiosWithToken.defaults.baseURL = testApiUrl;
    BitkeyPlatformAPI.instance.axiosWithoutToken.defaults.baseURL = testApiUrl;
  };

  public static restoreFromTestMode = () => {
    if (BitkeyPlatformAPI.instance.axiosWithToken.defaults.baseURL === BitkeyPlatformAPI.instance.bkpUrl) return;
    BitkeyPlatformAPI.instance.axiosWithToken.defaults.baseURL = BitkeyPlatformAPI.instance.bkpUrl;
    BitkeyPlatformAPI.instance.axiosWithoutToken.defaults.baseURL = BitkeyPlatformAPI.instance.bkpUrl;
  };

  private static convertDateTime = (target: any, fieldName: string) => {
    if (!target) return;
    if (!target[fieldName]) return;
    if (meta[fieldName] && meta[fieldName] === 'Datetime') {
      target[fieldName] = BitkeyPlatformAPI.instance.datetimeConverter.toRequest(target[fieldName]);
    } else if (typeof target[fieldName] === 'object') {
      for (const fName of Object.keys(target[fieldName])) {
        BitkeyPlatformAPI.convertDateTime(target[fieldName], fName);
      }
    }
  };

  private static toRequest = request => {
    for (const fieldName of Object.keys(request)) {
      BitkeyPlatformAPI.convertDateTime(request, fieldName);
    }
    request = toSnake(request);
    BitkeyPlatformAPI.instance.logger.log(`[BKP Request]`, request);
    return request;
  };

  private static toResponse = async <T>(promise: AxiosPromise<T>): Promise<T> => {
    try {
      const response = await promise;
      BitkeyPlatformAPI.instance.logger.log(`[BKP Response]`, response);
      const data = response.data;
      if (!data) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore for void
        return;
      }
      const responseData = toCamel(data);
      for (const fieldName of Object.keys(responseData)) {
        if (meta[fieldName] && meta[fieldName] === 'Datetime' && responseData[fieldName]) {
          responseData[fieldName] = BitkeyPlatformAPI.instance.datetimeConverter.toResponse(responseData[fieldName]);
        }
      }
      return responseData;
    } catch (e) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const err = e.response;
      BitkeyPlatformAPI.instance.logger.warn(`[BKP Error]`, err);
      throw err;
    }
  };

  private static callApi = async <T>(axiosWithTokenFunction: () => AxiosPromise<T>): Promise<T> => {
    try {
      return await BitkeyPlatformAPI.toResponse<T>(axiosWithTokenFunction());
    } catch (e) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (e && e.data && (e.data.code === 401 || e.data.code === '401')) {
        if (!BitkeyPlatformAPI.instance.axiosWithToken.defaults.headers['X-Api-Key']) {
          throw e;
        }
        if (!BitkeyPlatformAPI.instance.refreshToken) {
          console.log('If you set refresh token to BitkeyPlatformAPI, token will be refreshed automatically.');
          throw e;
        }
        if (!BitkeyPlatformAPI.instance.updateTokens) {
          console.log(
            'If you set updateTokens function to BitkeyPlatformAPI.init to store new tokens to your storage, you can refresh tokens automatically.'
          );
          throw e;
        }
        let auth;
        try {
          auth = await BitkeyPlatformAPI.refreshAccessToken({
            refreshToken: BitkeyPlatformAPI.instance.refreshToken,
          });
        } catch (e) {
          console.log(e); // avoid no-useless-catch
          throw e;
        }
        await BitkeyPlatformAPI.instance.updateTokens(auth.accessToken, auth.refreshToken);
        BitkeyPlatformAPI.setTokens(auth.accessToken, auth.refreshToken);
        return await BitkeyPlatformAPI.toResponse<T>(axiosWithTokenFunction());
      } else {
        throw e;
      }
    }
  };

  /**
   * リフレッシュトークンを利用してアクセストークンを発行し直します。
   */
  public static async refreshAccessToken(requestBody: RefreshAccessTokenRequest): Promise<AuthenticationResult> {
    return BitkeyPlatformAPI.toResponse<AuthenticationResult>(
      BitkeyPlatformAPI.instance.axiosWithoutToken.post(
        `/access_tokens/refresh`,
        BitkeyPlatformAPI.toRequest(requestBody)
      )
    );
  }

  /**
   * HTTP ヘッダーにセットされたアクセストークンを検証します。
   */
  public static async verifyAccessToken(): Promise<VerifyAccessTokenResponse> {
    return BitkeyPlatformAPI.callApi<VerifyAccessTokenResponse>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.get(`/access_tokens/verify`)
    );
  }

  /**
   * 自分が提案した譲渡の一覧を取得します。
   *
   * 譲渡の提案の一覧を取得します。
   * 削除済み、もしくは、 `end_at_epoch` を過ぎた提案は表示されません。
   *
   * ペルソナが組織に所属している場合は、組織として提案した一覧が取得できます。
   * それ以外は、個人として提案した一覧が取得できます。
   */
  public static async listDealOffers(queryParameters: ListDealOffersQuery): Promise<DealOffer[]> {
    return BitkeyPlatformAPI.callApi<DealOffer[]>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.get(`/deal_offers`, {
        params: BitkeyPlatformAPI.toRequest(queryParameters),
      })
    );
  }

  /**
   * One-time key の譲渡を提案します。
   *
   * 直接相手の Personality を指定するか、Pass Code を生成させることで提案先を制限します。
   * 提案を受け入れることで譲渡が成立し、 Digital Key が発行できるようになります。
   *
   * 提案先 Personality と Pass Code 生成可否の組み合わせごとの受け入れ制限は以下のようになります。
   *
   * - 提案先 Personality: 無指定 かつ Pass Code: 生成しない 場合、受け入れ制限がない提案となる
   * - 提案先 Personality: 指定 かつ Pass Code: 生成しない 場合、提案先の Personality のみ受け入れ可能な提案となる
   * - 提案先 Personality: 無指定 かつ Pass Code: 生成する 場合、Pass Code を提示することで受け入れ可能な提案となる
   * - 提案先 Personality: 指定 かつ Pass Code: 生成する 場合、提案先の Personality に一致、
   *   もしくは Pass Code を提示することで受け入れ可能な提案となる
   * Candidate が Pass Code を入力した場合、 Pass Code で譲渡が成立したとみなします。
   */
  public static async addDealOffer(requestBody: AddDealOfferRequest): Promise<AddDealOfferResponse> {
    return BitkeyPlatformAPI.callApi<AddDealOfferResponse>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.post(`/deal_offers`, BitkeyPlatformAPI.toRequest(requestBody))
    );
  }

  /**
   * 自分が提案した TreasureBox 貸し出し取引の一覧を取得します。
   *
   * 提案の一覧を取得します。
   * 削除済み、もしくは、 `end_at_epoch` を過ぎた提案は表示されません。
   *
   * ペルソナが組織に所属している場合は、組織として提案した一覧が取得できます。
   * それ以外は、個人として提案した一覧が取得できます。
   */
  public static async listTreasureBoxLendContractDealOffers(
    queryParameters: ListTreasureBoxLendContractDealOffersQuery
  ): Promise<TreasureBoxLendContractDealOffer[]> {
    return BitkeyPlatformAPI.callApi<TreasureBoxLendContractDealOffer[]>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.get(`/deal_offers/treasure_box_lend_contracts`, {
        params: BitkeyPlatformAPI.toRequest(queryParameters),
      })
    );
  }

  /**
   * TreasureBox の貸し出し契約を提案します。
   *
   * 提案相手の Personality, もしくは外部 ID として登録されている Email, Phone を指定するか、Pass Code を生成させることで提案先を制限します。
   * 提案を受け入れることで貸し出しが成立します。
   *
   * 提案先 Personality(もしくは外部 ID) と Pass Code 生成可否の組み合わせごとの受け入れ制限は以下のようになります。
   *
   * - 提案先 Personality: 無指定 かつ Pass Code: 生成しない 場合、受け入れ制限がない提案となる
   * - 提案先 Personality: 指定 かつ Pass Code: 生成しない 場合、提案先の Personality のみ受け入れ可能な提案となる
   * - 提案先 Personality: 無指定 かつ Pass Code: 生成する 場合、Pass Code を提示することで受け入れ可能な提案となる
   * - 提案先 Personality: 指定 かつ Pass Code: 生成する 場合、提案先の Personality に一致、
   *   もしくは Pass Code を提示することで受け入れ可能な提案となる
   * Candidate が Pass Code を入力した場合、 Pass Code で貸し出しが成立したとみなします。
   */
  public static async addTreasureBoxLendContractDealOffer(
    requestBody: AddTreasureBoxLendContractDealOfferRequest
  ): Promise<AddTreasureBoxLendContractDealOfferResponse> {
    return BitkeyPlatformAPI.callApi<AddTreasureBoxLendContractDealOfferResponse>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.post(
        `/deal_offers/treasure_box_lend_contracts`,
        BitkeyPlatformAPI.toRequest(requestBody)
      )
    );
  }

  /**
   * TreasureBox の貸し出し契約の提案を取得します。
   */
  public static async findTreasureBoxLendContractDealOffer(
    dealOfferId: string
  ): Promise<TreasureBoxLendContractDealOffer> {
    return BitkeyPlatformAPI.callApi<TreasureBoxLendContractDealOffer>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.get(`/deal_offers/treasure_box_lend_contracts/${dealOfferId}`)
    );
  }

  /**
   * TreasureBox の貸し出し契約の提案内容を変更します。
   *
   * 提案内容を変更します。
   * 提案時に指定された TreasureBox の所有者、もしくは借用者のみが変更可能です。
   * TreasureBox の借用者が貸し出し提案したのちに権利を放棄した場合でも、変更ができるように、提案者であることが条件とはしていません。
   */
  public static async updateTreasureBoxLendContractDealOffer(
    dealOfferId: string,
    requestBody: UpdateTreasureBoxLendContractDealOfferRequest
  ): Promise<TreasureBoxLendContractDealOffer> {
    return BitkeyPlatformAPI.callApi<TreasureBoxLendContractDealOffer>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.put(
        `/deal_offers/treasure_box_lend_contracts/${dealOfferId}`,
        BitkeyPlatformAPI.toRequest(requestBody)
      )
    );
  }

  /**
   * TreasureBox の貸し出し契約の提案を削除します。
   *
   * 提案時に指定された TreasureBox の所有者、もしくは借用者のみが削除可能です。
   * TreasureBox の借用者が貸し出しを提案したのちに権利を放棄した場合でも、削除ができるように、提案者であることが条件とはしていません。
   * 削除済みの提案を元に TreasureBox 貸し出し契約を新たに発行することはできなくなります。
   * 発行済みのTreasureBox 貸し出し契約を破棄する機能は提供していません。
   */
  public static async deleteTreasureBoxLendContractDealOffer(dealOfferId: string): Promise<void> {
    return BitkeyPlatformAPI.callApi<void>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.delete(`/deal_offers/treasure_box_lend_contracts/${dealOfferId}`)
    );
  }

  /**
   * TreasureBox の貸し出し契約の提案を受け入れます。
   *
   * 以下の2種類の処理が可能です。
   * 1. 貸し出し対象に指定されている人が提案を受け入れます。
   * 2. 他の媒体を介してパスコードを知っている人がパスコードを使って提案を受け入れます。
   * 一度受け入れると変更することはできません。
   */
  public static async acceptTreasureBoxLendContractDealOffer(
    dealOfferId: string,
    requestBody: AcceptTreasureBoxLendContractDealOfferRequest
  ): Promise<void> {
    return BitkeyPlatformAPI.callApi<void>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.post(
        `/deal_offers/treasure_box_lend_contracts/${dealOfferId}/accept`,
        BitkeyPlatformAPI.toRequest(requestBody)
      )
    );
  }

  /**
   * TreasureBox の貸し出し契約の提案を拒否します。
   *
   * 貸し出し対象に指定されている人が提案を拒否することができます。
   * 一度拒否すると変更することはできません。
   */
  public static async rejectTreasureBoxLendContractDealOffer(dealOfferId: string): Promise<void> {
    return BitkeyPlatformAPI.callApi<void>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.post(`/deal_offers/treasure_box_lend_contracts/${dealOfferId}/reject`)
    );
  }

  /**
   * 譲渡の提案を取得します。
   */
  public static async findDealOffer(dealOfferId: string): Promise<DealOffer> {
    return BitkeyPlatformAPI.callApi<DealOffer>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.get(`/deal_offers/${dealOfferId}`)
    );
  }

  /**
   * 譲渡の提案内容を変更します。
   *
   * 譲渡の提案内容を変更します。
   * 提案時に指定された Hole の所有者、もしくは借用者のみが変更可能です。
   * Hole の借用者が譲渡を提案したのちに権利を放棄した場合でも、変更ができるように、提案者であることが条件とはしていません。
   */
  public static async updateDealOffer(dealOfferId: string, requestBody: UpdateDealOfferRequest): Promise<DealOffer> {
    return BitkeyPlatformAPI.callApi<DealOffer>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.put(
        `/deal_offers/${dealOfferId}`,
        BitkeyPlatformAPI.toRequest(requestBody)
      )
    );
  }

  /**
   * 譲渡の提案を削除します。
   *
   * 提案時に指定された Hole の所有者、もしくは借用者のみが削除可能です。
   * Hole の借用者が譲渡を提案したのちに権利を放棄した場合でも、削除ができるように、提案者であることが条件とはしていません。
   * 削除済みの譲渡の提案を元にデジタルキーを新たに発行することはできなくなります。
   * 発行済みの鍵を止める機能は提供していません。
   */
  public static async deleteDealOffer(dealOfferId: string): Promise<void> {
    return BitkeyPlatformAPI.callApi<void>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.delete(`/deal_offers/${dealOfferId}`)
    );
  }

  /**
   * 譲渡の提案を受け入れます。
   *
   * 以下の2種類の処理が可能です。
   * 1. 譲渡対象に指定されている人が提案を受け入れます。
   * 2. 他の媒体を介してパスコードを知っている人がパスコードを使って提案を受け入れます。
   * 一度受け入れると変更することはできません。
   */
  public static async acceptDealOffer(dealOfferId: string, requestBody: AcceptDealOfferRequest): Promise<void> {
    return BitkeyPlatformAPI.callApi<void>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.post(
        `/deal_offers/${dealOfferId}/accept`,
        BitkeyPlatformAPI.toRequest(requestBody)
      )
    );
  }

  /**
   * 譲渡の提案に基づいたワンタイムキーを取得します。
   *
   * 譲渡の提案を受け入れた後に利用できます。
   * `publish_start_at_epoch` から `publish_end_at_epoch`
   * の間でなければ発行できません。
   */
  public static async getDealOfferOnetimeKey(
    dealOfferId: string,
    requestBody: PersonaPublicKeyId
  ): Promise<DigitalKey> {
    return BitkeyPlatformAPI.callApi<DigitalKey>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.post(
        `/deal_offers/${dealOfferId}/digital_keys`,
        BitkeyPlatformAPI.toRequest(requestBody)
      )
    );
  }

  /**
   * 譲渡の提案を拒否します。
   *
   * 譲渡対象に指定されている人が提案を拒否することができます。
   * 一度拒否すると変更することはできません。
   */
  public static async rejectDealOffer(dealOfferId: string): Promise<void> {
    return BitkeyPlatformAPI.callApi<void>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.post(`/deal_offers/${dealOfferId}/reject`)
    );
  }

  /**
   * 条件に一致する Deal の情報を参照します。
   */
  public static async findDeals(queryParameters: FindDealsQuery): Promise<Deal[]> {
    return BitkeyPlatformAPI.callApi<Deal[]>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.get(`/deals`, {
        params: BitkeyPlatformAPI.toRequest(queryParameters),
      })
    );
  }

  /**
   * Deal を登録します。
   *
   * 宛先や条件を指定して Deal を登録します。
   */
  public static async addDeal(requestBody: AddDealRequest): Promise<Deal> {
    return BitkeyPlatformAPI.callApi<Deal>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.post(`/deals`, BitkeyPlatformAPI.toRequest(requestBody))
    );
  }

  /**
   * Deal の情報を参照します。
   */
  public static async findDeal(dealId: string): Promise<Deal> {
    return BitkeyPlatformAPI.callApi<Deal>(() => BitkeyPlatformAPI.instance.axiosWithToken.get(`/deals/${dealId}`));
  }

  /**
   * Deal の申請を取り消します。
   *
   * ステータスが applied の Deal のみ、取り消しが可能です。
   */
  public static async deleteDeal(dealId: string): Promise<void> {
    return BitkeyPlatformAPI.callApi<void>(() => BitkeyPlatformAPI.instance.axiosWithToken.delete(`/deals/${dealId}`));
  }

  /**
   * Deal を承認します。
   *
   * 以下条件で、Deal を承認することができます。
   *  1. 宛先として指定されていること
   *  2. Deal で指定されている Hole 、もしくは、承認時に指定した Hole  の所有者、もしくは借用者であること
   *
   * 承認されると Deal の status プロパティが更新されます。
   * 承認と同時に Deal の一部の条件を更新することができます。
   * 指定していない、またはnullで渡された値は更新対象外となります。
   */
  public static async approveDeal(dealId: string, requestBody: ApproveDealRequest): Promise<Deal> {
    return BitkeyPlatformAPI.callApi<Deal>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.put(
        `/deals/${dealId}/approve`,
        BitkeyPlatformAPI.toRequest(requestBody)
      )
    );
  }

  /**
   * Deal に基づいたワンタイムキーを発行します。
   *
   * Deal に基づいてデジタルキーを発行します。
   * 対象の Deal は承認済みである必要があります。
   */
  public static async getOnetimeKey(dealId: string, requestBody: PersonaPublicKeyId): Promise<DigitalKey> {
    return BitkeyPlatformAPI.callApi<DigitalKey>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.post(
        `/deals/${dealId}/digital_keys`,
        BitkeyPlatformAPI.toRequest(requestBody)
      )
    );
  }

  /**
   * 承認済みの Deal を無効化します。
   *
   * ステータスが approved の Deal のみ、無効化できます。
   * また、無効化できるのは以下の条件を満たすペルソナもしくは権利を持った組織のメンバーのみです。
   *  1. 宛先として指定されていること
   *  2. Hole の所有者、もしくは借用者であること
   *
   * 無効化後は該当の Deal に対するワンタイムキーは発行できなくなります。
   */
  public static async disableDeal(dealId: string): Promise<Deal> {
    return BitkeyPlatformAPI.callApi<Deal>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.put(`/deals/${dealId}/disable`)
    );
  }

  /**
   * Deal を却下します。
   *
   * 宛先として指定されたペルソナもしくは組織のメンバーの権限に基づき、、却下することが可能です。
   * 却下されると Deal の status プロパティが更新されます。
   */
  public static async rejectDeal(dealId: string): Promise<Deal> {
    return BitkeyPlatformAPI.callApi<Deal>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.put(`/deals/${dealId}/reject`)
    );
  }

  /**
   * ヘルスチェックを返します。
   */
  public static async getHealth(): Promise<void> {
    return BitkeyPlatformAPI.toResponse<void>(BitkeyPlatformAPI.instance.axiosWithoutToken.get(`/health`));
  }

  /**
   * 自身に関わるホールの貸出/借出契約の一覧を取得します.
   */
  public static async listHoleContracts(queryParameters: ListHoleContractsQuery): Promise<HoleLendContract[]> {
    return BitkeyPlatformAPI.callApi<HoleLendContract[]>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.get(`/hole_contracts`, {
        params: BitkeyPlatformAPI.toRequest(queryParameters),
      })
    );
  }

  /**
   * Holeの貸出/借出契約を参照します。
   *
   * Holeの貸出/借出契約を参照します。
   * 貸出/借出実行者もしくは、貸出/借出対象者が参照可能です。
   */
  public static async findHoleContract(contractId: string): Promise<HoleLendContract> {
    return BitkeyPlatformAPI.callApi<HoleLendContract>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.get(`/hole_contracts/${contractId}`)
    );
  }

  /**
   * ホールを発行します。
   *
   * オブジェクトに対してホールを発行します。
   * 操作実行者のペルソナが組織に所属している場合、組織がホールの所有者となります。
   * それ以外は、操作実行者のペルソナが所有者となります。
   */
  public static async addHole(requestBody: AddHoleRequest): Promise<AddHoleResponse> {
    return BitkeyPlatformAPI.callApi<AddHoleResponse>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.post(`/holes`, BitkeyPlatformAPI.toRequest(requestBody))
    );
  }

  /**
   * ホールの情報を参照します。
   */
  public static async findHole(holeId: string): Promise<Hole> {
    return BitkeyPlatformAPI.callApi<Hole>(() => BitkeyPlatformAPI.instance.axiosWithToken.get(`/holes/${holeId}`));
  }

  /**
   * ホールを削除します。
   */
  public static async deleteHole(holeId: string): Promise<void> {
    return BitkeyPlatformAPI.callApi<void>(() => BitkeyPlatformAPI.instance.axiosWithToken.delete(`/holes/${holeId}`));
  }

  /**
   * 登録されたホールを有効化します。
   */
  public static async activateHole(holeId: string): Promise<Hole> {
    return BitkeyPlatformAPI.callApi<Hole>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.put(`/holes/${holeId}/activate`)
    );
  }

  /**
   * holeの借用者一覧を取得します。
   */
  public static async listBorrowers(holeId: string, queryParameters: ListBorrowersQuery): Promise<HoleBorrower[]> {
    return BitkeyPlatformAPI.callApi<HoleBorrower[]>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.get(`/holes/${holeId}/borrowers`, {
        params: BitkeyPlatformAPI.toRequest(queryParameters),
      })
    );
  }

  /**
   * Hole の貸出/借出契約一覧を取得します。
   */
  public static async listContractsByHole(
    holeId: string,
    queryParameters: ListContractsByHoleQuery
  ): Promise<HoleLendContract[]> {
    return BitkeyPlatformAPI.callApi<HoleLendContract[]>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.get(`/holes/${holeId}/contracts`, {
        params: BitkeyPlatformAPI.toRequest(queryParameters),
      })
    );
  }

  /**
   * Hole の貸し出し申請を行います。
   *
   * Hole の貸出/借出申請を行います。
   * borrow_request が false の場合、貸出申請になり、 true の場合、借出申請になります。
   * borrow_request は省略可能な場合には省略できます。
   * その際、lender と borrower の指定の仕方によって、貸出申請なのか借出申請なのかが区別されます。
   * 具体的には以下のようになります。
   * lender が操作者の場合: 省略可能(borrow_request = true 相当)
   * borrower が操作者の場合: 省略可能(borrow_request = false 相当)
   * Hole を借りた人は、プリンシパルキーの発行、Dealの承認・却下、Holeの貸し出しが可能になります。
   */
  public static async addHoleContract(holeId: string, requestBody: AddHoleContractRequest): Promise<HoleLendContract> {
    return BitkeyPlatformAPI.callApi<HoleLendContract>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.post(
        `/holes/${holeId}/contracts`,
        BitkeyPlatformAPI.toRequest(requestBody)
      )
    );
  }

  /**
   * Holeの貸出/借出契約を参照します。
   *
   * Holeの貸出/借出を参照します。
   * 貸出/借出実行者もしくは、貸出/借出対象者が参照可能です。
   */
  public static async findHoleLendContract(holeId: string, contractId: string): Promise<HoleLendContract> {
    return BitkeyPlatformAPI.callApi<HoleLendContract>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.get(`/holes/${holeId}/contracts/${contractId}`)
    );
  }

  /**
   * Holeの貸し出しを取り消します。
   *
   * 取り消しは、貸し出し実行者もしくは借用者自身のみ実施可能です。
   */
  public static async deleteHoleLendContract(holeId: string, contractId: string): Promise<void> {
    return BitkeyPlatformAPI.callApi<void>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.delete(`/holes/${holeId}/contracts/${contractId}`)
    );
  }

  /**
   * hole lend contract を受諾します。
   *
   * Hole を貸す側が申請をした場合に、申請を受けた人がその申請を受諾します。
   * 一度受諾すると変更することはできません。
   */
  public static async acceptHoleLendContract(holeId: string, contractId: string): Promise<HoleLendContract> {
    return BitkeyPlatformAPI.callApi<HoleLendContract>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.put(`/holes/${holeId}/contracts/${contractId}/accept`)
    );
  }

  /**
   * hole lend contract を承認します。
   *
   * Hole の借出申請がなされた場合に、申請を受けた人がその申請を承認します。
   * 一度承認すると変更することはできません。
   */
  public static async approveHoleLendContract(holeId: string, contractId: string): Promise<HoleLendContract> {
    return BitkeyPlatformAPI.callApi<HoleLendContract>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.put(`/holes/${holeId}/contracts/${contractId}/approve`)
    );
  }

  /**
   * hole lend contract を却下します。
   *
   * Hole の貸出/借出に関わる申請を受けた人がその申請を却下します。具体的には以下のようになります。
   *   Hole の貸出申請がなされた場合: Hole 貸出対象に指定されている人が申請を却下します。
   *   Hole の借出申請がなされた場合: Hole 借出対象に指定されている人が申請を却下します。
   * 一度却下すると変更することはできません。
   */
  public static async rejectHoleLendContract(holeId: string, contractId: string): Promise<HoleLendContract> {
    return BitkeyPlatformAPI.callApi<HoleLendContract>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.put(`/holes/${holeId}/contracts/${contractId}/reject`)
    );
  }

  /**
   * ホールに対するプリンシパルキーを発行します。
   */
  public static async getPrincipalKey(holeId: string, requestBody: PersonaPublicKeyId): Promise<DigitalKey> {
    return BitkeyPlatformAPI.callApi<DigitalKey>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.post(
        `/holes/${holeId}/digital_keys`,
        BitkeyPlatformAPI.toRequest(requestBody)
      )
    );
  }

  /**
   * Hole を貸し出します。
   *
   * Hole の利用権を与えて、 Hole を貸し出します。
   * プリンシパルキーの発行、Dealの承認・却下、Holeの貸し出しが可能になります。
   */
  public static async lendHole(holeId: string, requestBody: LendHoleRequest): Promise<LendHoleResponse> {
    return BitkeyPlatformAPI.callApi<LendHoleResponse>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.post(
        `/holes/${holeId}/lending`,
        BitkeyPlatformAPI.toRequest(requestBody)
      )
    );
  }

  /**
   * すでに生成済みのホールの共通鍵と鍵ペアを再生成します。
   *
   * すでに生成済みのホールの共通鍵と鍵ペアを再生成します。
   * 過去に設置したホールに対する鍵が発行できなくなります。
   * また、このホールを設置すると過去に発行したプリンシパルキーが利用できなくなります。
   * 新たな鍵を取得するには POST /holes/{hole_id}/digital_keys を利用してください。
   */
  public static async updateHoleKeys(holeId: string): Promise<UpdateHoleKeysResponse> {
    return BitkeyPlatformAPI.callApi<UpdateHoleKeysResponse>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.put(`/holes/${holeId}/update_hole_keys`)
    );
  }

  /**
   * ユーザが招待された組織に参加します。
   *
   * ユーザが招待された組織に参加します。
   * emailで招待した場合は、招待メールの `dynamic link` に `id` という URLパラメータが付与されます。
   * それが `inivitation_id` となります。
   * 端末認証用の公開鍵を登録する場合は `inivitation_id` を16byteの `nonce` として署名を作成し、 `body` に入力してください。
   * body の `phone` は空にしてください。
   * 電話番号で招待した場合は、SMSの本文に6桁の数字によるパスコードが含まれますので、それを invitation_id として入力してください。
   * また、電話番号で招待した場合は body の phone に電話番号を入力してください。
   */
  public static async joinToOrganization(
    invitationId: string,
    requestBody: JoinToOrganizationRequest
  ): Promise<AuthenticationResult> {
    return BitkeyPlatformAPI.callApi<AuthenticationResult>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.put(
        `/invitations/${invitationId}`,
        BitkeyPlatformAPI.toRequest(requestBody)
      )
    );
  }

  /**
   * 新規ユーザがアカウントを作成しつつ招待された組織に参加します。
   *
   * 新規ユーザがアカウントを作成しつつ招待された組織に参加します。
   * emailで招待した場合は、招待メールの `dynamic link` に `id` という URLパラメータが付与されます。
   * それが `inivitation_id` となります。
   * 端末認証用の公開鍵を登録する場合は `inivitation_id` を16byteの `nonce` として署名を作成し、 `body` に入力してください。
   * responseとして、 `authentication_result` の配列を返します。
   * `authentication_result` の中に、 `organization_id` および `role_id` に値が入っている要素が組織用のペルソナとなります。
   * body の `phone` は空にしてください。
   * 電話番号で招待した場合は、SMSの本文に6桁の数字によるパスコードが含まれますので、それを invitation_id として入力してください。
   * また、電話番号で招待した場合は body の phone に電話番号を入力してください。
   */
  public static async signUpToOrganization(
    invitationId: string,
    requestBody: SignUpToOrganizationRequest
  ): Promise<AuthenticationResult[]> {
    return BitkeyPlatformAPI.toResponse<AuthenticationResult[]>(
      BitkeyPlatformAPI.instance.axiosWithoutToken.post(
        `/invitations/${invitationId}`,
        BitkeyPlatformAPI.toRequest(requestBody)
      )
    );
  }

  /**
   * OAuth のアクセストークンを検証します。
   */
  public static async oauthVerifyToken(): Promise<OauthAccessToken> {
    return BitkeyPlatformAPI.callApi<OauthAccessToken>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.get(`/oauth/access_tokens/verify`)
    );
  }

  /**
   * OAuth の認証フローを実際に開始する API です。
   */
  public static async oauthAuthentication(): Promise<any /* 型変換失敗 */> {
    return BitkeyPlatformAPI.toResponse<any /* 型変換失敗 */>(
      BitkeyPlatformAPI.instance.axiosWithoutToken.post(`/oauth/authenticate`)
    );
  }

  /**
   * ID Token を検証するための公開鍵を取得します。
   *
   * 複数の鍵のうち、検証したい ID Token の `kid` ヘッダによって鍵を特定してください。
   */
  public static async oauthJwks(): Promise<JwkSets> {
    return BitkeyPlatformAPI.toResponse<JwkSets>(BitkeyPlatformAPI.instance.axiosWithoutToken.get(`/oauth/certs`));
  }

  /**
   * OAuth の Client を取得します。
   */
  public static async oauthFindClient(clientId: string): Promise<OauthClient> {
    return BitkeyPlatformAPI.toResponse<OauthClient>(
      BitkeyPlatformAPI.instance.axiosWithoutToken.get(`/oauth/clients/${clientId}`)
    );
  }

  /**
   * OAuth のトークンの無効化を行います。
   *
   * OAuth のトークン の無効化を行います。
   * すでに無効なトークンを指定した場合でも 200 を返します。
   */
  public static async oauthRevokeToken(): Promise<void> {
    return BitkeyPlatformAPI.toResponse<void>(
      BitkeyPlatformAPI.instance.axiosWithoutToken.post(`/oauth/refresh_tokens/revoke`)
    );
  }

  /**
   * Access Token と ID Token 、 Refresh Token の取得を行います。
   *
   * `grant_type` の値によって異なる動作をします。
   * <dl>
   *   <dt>authorization_code</dt>
   *   <dd>
   *     Authorization Code Flow における、
   *     Code による ID Token の取得を要求します。
   *   </dd>
   *   <dt>refresh_token</dt>
   *   <dd>
   *     Access Token のリフレッシュを行います。
   *   </dd>
   * </dl
   */
  public static async oauthToken(): Promise<OauthTokenResponse> {
    return BitkeyPlatformAPI.toResponse<OauthTokenResponse>(
      BitkeyPlatformAPI.instance.axiosWithoutToken.post(`/oauth/tokens`)
    );
  }

  /**
   * Open ID Connect の アクセストークンによって、ユーザ情報を取得します。
   */
  public static async oauthGetUserInfo(): Promise<OidcUserinfo> {
    return BitkeyPlatformAPI.toResponse<OidcUserinfo>(
      BitkeyPlatformAPI.instance.axiosWithoutToken.get(`/oauth/user_info`)
    );
  }

  /**
   * Open ID Connect の アクセストークンによって、ユーザ情報を取得します。
   *
   * Open ID Connect の アクセストークンによって、ユーザ情報を取得します。
   * アクセストークンは Body、もしくは Header のどちらかに Bearer Token としてセットしてください。
   */
  public static async oauthPostUserInfo(): Promise<OidcUserinfo> {
    return BitkeyPlatformAPI.toResponse<OidcUserinfo>(
      BitkeyPlatformAPI.instance.axiosWithoutToken.post(`/oauth/user_info`)
    );
  }

  /**
   * 承認済の OAuth のクライアント情報を参照します。
   */
  public static async findApprovedOAUTHClient(clientId: string): Promise<FindApprovedOAUTHClientResponse> {
    return BitkeyPlatformAPI.callApi<FindApprovedOAUTHClientResponse>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.get(`/oauth_clients/${clientId}`)
    );
  }

  /**
   * 自分が移譲提案されたオブジェクトの移譲提案の一覧を取得できます
   *
   * 自分が移譲提案されたオブジェクトの移譲提案の一覧を取得できます
   * statusでfilterをかけることができます
   * 操作者が組織ペルソナの場合は、組織内で object_control 権限を保持している必要があります。
   */
  public static async listObjectTransfersByToOwnerID(
    queryParameters: ListObjectTransfersByToOwnerIDQuery
  ): Promise<ObjectTransfer[]> {
    return BitkeyPlatformAPI.callApi<ObjectTransfer[]>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.get(`/object_transfers`, {
        params: BitkeyPlatformAPI.toRequest(queryParameters),
      })
    );
  }

  /**
   * オブジェクトの移譲提案を参照します
   *
   * オブジェクトの移譲提案を参照します
   * 操作者が組織ペルソナの場合は、組織内で object_control 権限を保持している必要があります。
   */
  public static async findObjectTransfer(objectTransferId: string): Promise<ObjectTransfer> {
    return BitkeyPlatformAPI.callApi<ObjectTransfer>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.get(`/object_transfers/${objectTransferId}`)
    );
  }

  /**
   * オブジェクトの移譲提案を受け入れます
   *
   * 提案を受け入れると該当のオブジェクトやそれに紐づくホールの所有権が移譲されます。
   * 操作者が組織ペルソナの場合は、組織内で object_control 権限を保持している必要があります。
   */
  public static async acceptObjectTransfer(objectTransferId: string): Promise<ObjectTransfer> {
    return BitkeyPlatformAPI.callApi<ObjectTransfer>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.put(`/object_transfers/${objectTransferId}/accept`)
    );
  }

  /**
   * オブジェクトを移譲した際の詳細を取得します。
   *
   * オブジェクトの移譲に伴って、変更されたデータのIDを一覧で取得できます。
   * 操作者が組織ペルソナの場合は、組織内で object_control 権限を保持している必要があります。
   */
  public static async listObjectTransferDetails(
    objectTransferId: string,
    queryParameters: ListObjectTransferDetailsQuery
  ): Promise<TransferDetails> {
    return BitkeyPlatformAPI.callApi<TransferDetails>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.get(`/object_transfers/${objectTransferId}/details`, {
        params: BitkeyPlatformAPI.toRequest(queryParameters),
      })
    );
  }

  /**
   * オブジェクトの移譲提案を拒否します
   *
   * 拒否すると、合わせて該当のオブジェクトやそれに紐づくホールの凍結が解除されます。
   * 操作者が組織ペルソナの場合は組織内で object_control 権限を保持している必要があります。
   */
  public static async rejectObjectTransfer(objectTransferId: string): Promise<ObjectTransfer> {
    return BitkeyPlatformAPI.callApi<ObjectTransfer>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.put(`/object_transfers/${objectTransferId}/reject`)
    );
  }

  /**
   * ペルソナや組織が所有するオブジェクトを参照します。
   *
   * `organizationg_group_id` を指定しない場合、
   * 操作実行者のペルソナが組織に所属している場合、その組織が所有しているオブジェクトが対象です。
   * それ以外は、操作実行者のペルソナが所有するオブジェクトが対象となります。
   * `organizationg_group_id` を指定する場合、
   * 指定した組織グループの所有しているオブジェクトが対象です。
   */
  public static async listObjects(queryParameters: ListObjectsQuery): Promise<BitkeyObject[]> {
    return BitkeyPlatformAPI.callApi<BitkeyObject[]>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.get(`/objects`, {
        params: BitkeyPlatformAPI.toRequest(queryParameters),
      })
    );
  }

  /**
   * ペルソナや組織、組織グループが所有するオブジェクトを登録します。
   *
   * 操作実行者のペルソナが組織に所属している場合、
   * `owner_group_id` が未指定だと該当組織の所有物となります。
   * `owner_group_id` が指定されていると該当組織グループの所有物となります。
   *
   * 操作実行者のペルソナが組織に所属していない場合は、操作実行者のペルソナの所有物となります。
   */
  public static async addObject(requestBody: AddObjectRequest): Promise<BitkeyObject> {
    return BitkeyPlatformAPI.callApi<BitkeyObject>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.post(`/objects`, BitkeyPlatformAPI.toRequest(requestBody))
    );
  }

  /**
   * オブジェクトの情報を参照します。
   */
  public static async findObject(objectId: string): Promise<BitkeyObject> {
    return BitkeyPlatformAPI.callApi<BitkeyObject>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.get(`/objects/${objectId}`)
    );
  }

  /**
   * オブジェクトの情報を削除します。
   */
  public static async deleteObject(objectId: string): Promise<void> {
    return BitkeyPlatformAPI.callApi<void>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.delete(`/objects/${objectId}`)
    );
  }

  /**
   * 登録されたオブジェクトを有効化します。
   *
   * 有効化をすることでホールの発行が可能となります。
   */
  public static async activateObject(objectId: string, requestBody: ActivateObjectRequest): Promise<BitkeyObject> {
    return BitkeyPlatformAPI.callApi<BitkeyObject>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.put(
        `/objects/${objectId}/activate`,
        BitkeyPlatformAPI.toRequest(requestBody)
      )
    );
  }

  /**
   * オブジェクトに対して発行されているholeの一覧を取得します。
   */
  public static async listHoles(objectId: string): Promise<Hole[]> {
    return BitkeyPlatformAPI.callApi<Hole[]>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.get(`/objects/${objectId}/holes`)
    );
  }

  /**
   * 新しいキーペアがデバイスに設定されたことを確定します。
   *
   * これ以降の Hole などの情報は、新しい秘密鍵で署名されます。
   */
  public static async activateObjectKeyPair(
    objectId: string,
    requestBody: ActivateObjectKeyPairRequest
  ): Promise<void> {
    return BitkeyPlatformAPI.callApi<void>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.put(
        `/objects/${objectId}/key_pairs`,
        BitkeyPlatformAPI.toRequest(requestBody)
      )
    );
  }

  /**
   * オブジェクト用に新しいキーペアを発行します。
   *
   * 秘密鍵はサーバで保持され、新しい公開鍵と古い鍵による署名を返します。
   * もし、未確定状態の鍵が存在していた場合は、その鍵は上書きされ、別の鍵が発行されます。
   */
  public static async createObjectKeyPair(objectId: string): Promise<CreateObjectKeyPairResponse> {
    return BitkeyPlatformAPI.callApi<CreateObjectKeyPairResponse>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.post(`/objects/${objectId}/key_pairs`)
    );
  }

  /**
   * 所有しているオブジェクトの移譲提案の一覧を参照します
   *
   * 操作者が組織ペルソナの場合は組織内で object_control 権限を保持している必要があります。
   */
  public static async listObjectTransfer(
    objectId: string,
    queryParameters: ListObjectTransferQuery
  ): Promise<ObjectTransfer[]> {
    return BitkeyPlatformAPI.callApi<ObjectTransfer[]>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.get(`/objects/${objectId}/transfers`, {
        params: BitkeyPlatformAPI.toRequest(queryParameters),
      })
    );
  }

  /**
   * 所有しているオブジェクトの移譲提案を登録します
   *
   * オブジェクトの移譲提案を登録すると、該当のオブジェクトとそれに紐づくホールが一時的に凍結されます。
   * 凍結されたオブジェクトは、 Object.locked が true になります。
   * 凍結がされるとそれらを指定した取引の登録ができなくなります。
   * 提案の受け入れ・却下、もしくは提案が取り消されることで凍結は解除されます。
   *
   * 提案が受け入れられた際、該当オブジェクトに紐づくホールの所有者も同時に移譲されます。
   * また、それらに紐づく取引や権限情報は削除・無効化されます。
   *   e.g. ホールの貸し出し, One-time key の申請・譲渡提案, 組織内での特定のホールに対する権限, など
   *
   * 操作者が組織ペルソナの場合は、組織内で object_control 権限を保持している必要があります。
   */
  public static async addObjectTransfer(
    objectId: string,
    requestBody: AddObjectTransferRequest
  ): Promise<ObjectTransfer> {
    return BitkeyPlatformAPI.callApi<ObjectTransfer>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.post(
        `/objects/${objectId}/transfers`,
        BitkeyPlatformAPI.toRequest(requestBody)
      )
    );
  }

  /**
   * 所有しているオブジェクトの移譲提案を参照します
   *
   * 操作者が組織ペルソナの場合は、組織内で object_control 権限を保持している必要があります。
   */
  public static async findObjectTransferWithObjectID(
    objectId: string,
    objectTransferId: string
  ): Promise<ObjectTransfer> {
    return BitkeyPlatformAPI.callApi<ObjectTransfer>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.get(`/objects/${objectId}/transfers/${objectTransferId}`)
    );
  }

  /**
   * 所有しているオブジェクトの移譲提案を取り消します
   *
   * 取り消しをすると、合わせて該当のオブジェクトやそれに紐づくホールの凍結が解除されます。
   * 操作者が組織ペルソナの場合は、組織内で object_control 権限を保持している必要があります。
   */
  public static async deleteObjectTransfer(objectId: string, objectTransferId: string): Promise<void> {
    return BitkeyPlatformAPI.callApi<void>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.delete(`/objects/${objectId}/transfers/${objectTransferId}`)
    );
  }

  /**
   * 組織グループを追加します。
   *
   * 初期的には登録実行者の組織が該当グループの管理者として設定されます。
   */
  public static async addOrganizationGroup(requestBody: AddOrganizationGroupRequest): Promise<OrganizationGroup> {
    return BitkeyPlatformAPI.callApi<OrganizationGroup>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.post(`/organization_groups`, BitkeyPlatformAPI.toRequest(requestBody))
    );
  }

  /**
   * 組織グループの情報を参照します。
   *
   * 組織グループに所属している組織のペルソナのみ可能です。
   */
  public static async findOrganizationGroup(organizationGroupId: string): Promise<OrganizationGroup> {
    return BitkeyPlatformAPI.callApi<OrganizationGroup>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.get(`/organization_groups/${organizationGroupId}`)
    );
  }

  /**
   * 組織グループの情報を更新します
   *
   * 組織グループのオーナー組織のペルソナのみ可能です。
   */
  public static async updateOrganizationGroup(
    organizationGroupId: string,
    requestBody: UpdateOrganizationGroupRequest
  ): Promise<OrganizationGroup> {
    return BitkeyPlatformAPI.callApi<OrganizationGroup>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.put(
        `/organization_groups/${organizationGroupId}`,
        BitkeyPlatformAPI.toRequest(requestBody)
      )
    );
  }

  /**
   * 組織グループを削除します。
   *
   * 組織グループのオーナー組織のペルソナのみ可能です。
   */
  public static async deleteOrganizationGroup(organizationGroupId: string): Promise<void> {
    return BitkeyPlatformAPI.callApi<void>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.delete(`/organization_groups/${organizationGroupId}`)
    );
  }

  /**
   * Organization Group の Ancestors の一覧を取得する。
   */
  public static async listOrganizationGroupAncestors(
    organizationGroupId: string,
    queryParameters: ListOrganizationGroupAncestorsQuery
  ): Promise<OrganizationGroup[]> {
    return BitkeyPlatformAPI.callApi<OrganizationGroup[]>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.get(`/organization_groups/${organizationGroupId}/ancestors`, {
        params: BitkeyPlatformAPI.toRequest(queryParameters),
      })
    );
  }

  /**
   * Organization Group の Ancestors の情報を参照します。
   */
  public static async findOrganizationGroupAncestor(
    organizationGroupId: string,
    ancestorGroupId: string
  ): Promise<OrganizationGroup> {
    return BitkeyPlatformAPI.callApi<OrganizationGroup>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.get(
        `/organization_groups/${organizationGroupId}/ancestors/${ancestorGroupId}`
      )
    );
  }

  /**
   * Organization Group の Descendant の一覧を取得する。
   */
  public static async listOrganizationGroupDescendants(
    organizationGroupId: string,
    queryParameters: ListOrganizationGroupDescendantsQuery
  ): Promise<OrganizationGroup[]> {
    return BitkeyPlatformAPI.callApi<OrganizationGroup[]>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.get(`/organization_groups/${organizationGroupId}/descendants`, {
        params: BitkeyPlatformAPI.toRequest(queryParameters),
      })
    );
  }

  /**
   * Organization Group の Descendant の情報を参照します。
   */
  public static async findOrganizationGroupDescendant(
    organizationGroupId: string,
    descendantGroupId: string
  ): Promise<OrganizationGroup> {
    return BitkeyPlatformAPI.callApi<OrganizationGroup>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.get(
        `/organization_groups/${organizationGroupId}/descendants/${descendantGroupId}`
      )
    );
  }

  /**
   * Organization group から Descendant から排除します。
   */
  public static async removeOrganizationGroupDescendant(
    organizationGroupId: string,
    descendantGroupId: string
  ): Promise<void> {
    return BitkeyPlatformAPI.callApi<void>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.delete(
        `/organization_groups/${organizationGroupId}/descendants/${descendantGroupId}`
      )
    );
  }

  /**
   * 組織グループが組織に許可している特定のホールに関する権限を参照します。
   *
   * これにより制御される権限は、組織グループが所有するオブジェクト等を指定した組織のメンバーが操作する際に参照されます。
   */
  public static async listOrgGrpLentHolePowerCaps(
    organizationGroupId: string,
    contractId: string,
    queryParameters: ListOrgGrpLentHolePowerCapsQuery
  ): Promise<OrganizationGroupLentHolePowerCaps[]> {
    return BitkeyPlatformAPI.callApi<OrganizationGroupLentHolePowerCaps[]>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.get(
        `/organization_groups/${organizationGroupId}/hole_contracts/${contractId}/power_caps`,
        {
          params: BitkeyPlatformAPI.toRequest(queryParameters),
        }
      )
    );
  }

  /**
   * 組織グループが組織に許可している特定のホールに関する権限を更新します。
   *
   * これにより制御される権限は、組織グループが所有するオブジェクト等を指定した組織のメンバーが操作する際に参照されます。
   */
  public static async updateOrgGrpLentHolePowerCaps(
    organizationGroupId: string,
    contractId: string,
    requestBody: UpdateOrgGrpLentHolePowerCapsRequest
  ): Promise<OrganizationGroupLentHolePowerCaps> {
    return BitkeyPlatformAPI.callApi<OrganizationGroupLentHolePowerCaps>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.put(
        `/organization_groups/${organizationGroupId}/hole_contracts/${contractId}/power_caps`,
        BitkeyPlatformAPI.toRequest(requestBody)
      )
    );
  }

  /**
   * 組織グループが子の組織グループに許可している伝搬権限を参照します。
   *
   * これにより制御される権限は、指定した借用契約で借りている hole を child の組織グループのメンバーが操作する際に参照されます。
   * Organization Group Power Caps で直接権限を許可している場合は、そちらが優先されます。
   */
  public static async listOrgGrpPropagationLentHolePowers(
    organizationGroupId: string,
    contractId: string,
    queryParameters: ListOrgGrpPropagationLentHolePowersQuery
  ): Promise<OrganizationGroupPropagationLentHolePowers[]> {
    return BitkeyPlatformAPI.callApi<OrganizationGroupPropagationLentHolePowers[]>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.get(
        `/organization_groups/${organizationGroupId}/hole_contracts/${contractId}/propagation_powers`,
        {
          params: BitkeyPlatformAPI.toRequest(queryParameters),
        }
      )
    );
  }

  /**
   * 親の組織グループが子の組織グループに許可している伝搬権限を更新します。
   *
   * これにより制御される権限は、指定した借用契約で借りている hole を child の組織グループのメンバーが操作する際に参照されます。
   * Organization Group Power Caps で直接権限を許可している場合は、そちらが優先されます。
   */
  public static async updateOrgGrpPropagationLentHolePower(
    organizationGroupId: string,
    contractId: string,
    requestBody: UpdateOrgGrpPropagationLentHolePowerRequest
  ): Promise<OrganizationGroupPropagationLentHolePowers> {
    return BitkeyPlatformAPI.callApi<OrganizationGroupPropagationLentHolePowers>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.put(
        `/organization_groups/${organizationGroupId}/hole_contracts/${contractId}/propagation_powers`,
        BitkeyPlatformAPI.toRequest(requestBody)
      )
    );
  }

  /**
   * 組織グループが組織に許可している特定のホールに関する権限を参照します。
   *
   * これにより制御される権限は、組織グループが所有するオブジェクト等を指定した組織のメンバーが操作する際に参照されます。
   */
  public static async listOrgGrpHolePowerCaps(
    organizationGroupId: string,
    holeId: string,
    queryParameters: ListOrgGrpHolePowerCapsQuery
  ): Promise<OrganizationGroupHolePowerCaps[]> {
    return BitkeyPlatformAPI.callApi<OrganizationGroupHolePowerCaps[]>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.get(
        `/organization_groups/${organizationGroupId}/holes/${holeId}/power_caps`,
        {
          params: BitkeyPlatformAPI.toRequest(queryParameters),
        }
      )
    );
  }

  /**
   * 組織グループが組織に許可している特定のホールに関する権限を更新します。
   *
   * これにより制御される権限は、組織グループが所有するオブジェクト等を指定した組織のメンバーが操作する際に参照されます。
   */
  public static async updateOrgGrpHolePowerCaps(
    organizationGroupId: string,
    holeId: string,
    requestBody: UpdateOrgGrpHolePowerCapsRequest
  ): Promise<OrganizationGroupHolePowerCaps> {
    return BitkeyPlatformAPI.callApi<OrganizationGroupHolePowerCaps>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.put(
        `/organization_groups/${organizationGroupId}/holes/${holeId}/power_caps`,
        BitkeyPlatformAPI.toRequest(requestBody)
      )
    );
  }

  /**
   * 組織グループが子の組織グループに許可している伝搬権限を参照します。
   *
   * これにより制御される権限は、指定した hole を Child の組織グループのメンバーが操作する際に参照されます。
   * Organization Group Power Caps で直接権限を許可している場合は、そちらが優先されます。
   */
  public static async listOrgGrpPropagationHolePowers(
    organizationGroupId: string,
    holeId: string,
    queryParameters: ListOrgGrpPropagationHolePowersQuery
  ): Promise<OrganizationGroupPropagationHolePowers[]> {
    return BitkeyPlatformAPI.callApi<OrganizationGroupPropagationHolePowers[]>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.get(
        `/organization_groups/${organizationGroupId}/holes/${holeId}/propagation_powers`,
        {
          params: BitkeyPlatformAPI.toRequest(queryParameters),
        }
      )
    );
  }

  /**
   * 親の組織グループが子の組織グループに許可している伝搬権限を更新します。
   *
   * これにより制御される権限は、指定した hole を Child の組織グループのメンバーが操作する際に参照されます。
   * Organization Group Power Caps で直接権限を許可している場合は、そちらが優先されます。
   */
  public static async updateOrgGrpPropagationHolePower(
    organizationGroupId: string,
    holeId: string,
    requestBody: UpdateOrgGrpPropagationHolePowerRequest
  ): Promise<OrganizationGroupPropagationHolePowers> {
    return BitkeyPlatformAPI.callApi<OrganizationGroupPropagationHolePowers>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.put(
        `/organization_groups/${organizationGroupId}/holes/${holeId}/propagation_powers`,
        BitkeyPlatformAPI.toRequest(requestBody)
      )
    );
  }

  /**
   * 組織グループへの招待情報の一覧を取得します。
   *
   * 組織グループに所属しているオーナー組織のペルソナのみ可能です。
   */
  public static async listOrganizationGroupInvitation(
    organizationGroupId: string,
    queryParameters: ListOrganizationGroupInvitationQuery
  ): Promise<OrganizationGroupInvitation[]> {
    return BitkeyPlatformAPI.callApi<OrganizationGroupInvitation[]>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.get(`/organization_groups/${organizationGroupId}/invitations`, {
        params: BitkeyPlatformAPI.toRequest(queryParameters),
      })
    );
  }

  /**
   * 組織グループへ組織、または組織グループを招待します。
   *
   * 組織グループのオーナー組織のペルソナのみ実行可能です。
   * 組織を招待したい場合は、 `organization_id` を
   * 組織グループを招待したい場合は、 `organization_group_id` を
   * どちらか片方を指定してください。
   */
  public static async addOrganizationGroupInvitation(
    organizationGroupId: string,
    requestBody: AddOrganizationGroupInvitationRequest
  ): Promise<OrganizationGroupInvitation> {
    return BitkeyPlatformAPI.callApi<OrganizationGroupInvitation>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.post(
        `/organization_groups/${organizationGroupId}/invitations`,
        BitkeyPlatformAPI.toRequest(requestBody)
      )
    );
  }

  /**
   * 組織グループへの招待情報を参照します。
   *
   * 組織グループに所属しているオーナー組織のペルソナのみ可能です。
   */
  public static async findOrganizationGroupInvitation(
    organizationGroupId: string,
    organizationGroupInvitationId: string
  ): Promise<OrganizationGroupInvitation> {
    return BitkeyPlatformAPI.callApi<OrganizationGroupInvitation>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.get(
        `/organization_groups/${organizationGroupId}/invitations/${organizationGroupInvitationId}`
      )
    );
  }

  /**
   * 組織グループへの招待を取り消します。
   *
   * 組織グループのオーナー組織のペルソナのみ可能です。
   */
  public static async deleteOrganizationGroupInvitation(
    organizationGroupId: string,
    organizationGroupInvitationId: string
  ): Promise<void> {
    return BitkeyPlatformAPI.callApi<void>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.delete(
        `/organization_groups/${organizationGroupId}/invitations/${organizationGroupInvitationId}`
      )
    );
  }

  /**
   * 組織グループへの組織グループの招待を承認して、組織グループに参加します。
   *
   * 組織グループに所属しているオーナー組織のペルソナのみ可能です。
   */
  public static async approveOrgGrpInvitationToOrgGrp(
    organizationGroupId: string,
    organizationGroupInvitationId: string
  ): Promise<void> {
    return BitkeyPlatformAPI.callApi<void>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.put(
        `/organization_groups/${organizationGroupId}/invitations/${organizationGroupInvitationId}/approve`
      )
    );
  }

  /**
   * 組織グループへの組織グループの招待を却下します。
   *
   * 組織グループの招待を受けている組織グループのオーナーのみ可能です。
   */
  public static async rejectOrgGrpInvitationToOrgGrp(
    organizationGroupId: string,
    organizationGroupInvitationId: string
  ): Promise<void> {
    return BitkeyPlatformAPI.callApi<void>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.put(
        `/organization_groups/${organizationGroupId}/invitations/${organizationGroupInvitationId}/reject`
      )
    );
  }

  /**
   * 組織グループが組織に許可している特定のオブジェクトに関する権限を参照します。
   *
   * これにより制御される権限は、組織グループが所有するオブジェクト等を指定した組織のメンバーが操作する際に参照されます。
   */
  public static async listOrgGrpObjPowerCaps(
    organizationGroupId: string,
    objectId: string,
    queryParameters: ListOrgGrpObjPowerCapsQuery
  ): Promise<OrganizationGroupObjectPowerCaps[]> {
    return BitkeyPlatformAPI.callApi<OrganizationGroupObjectPowerCaps[]>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.get(
        `/organization_groups/${organizationGroupId}/objects/${objectId}/power_caps`,
        {
          params: BitkeyPlatformAPI.toRequest(queryParameters),
        }
      )
    );
  }

  /**
   * 組織グループが組織に許可している特定のオブジェクトに関する権限を更新します。
   *
   * これにより制御される権限は、組織グループが所有するオブジェクト等を指定した組織のメンバーが操作する際に参照されます。
   */
  public static async updateOrgGrpObjPowerCaps(
    organizationGroupId: string,
    objectId: string,
    requestBody: UpdateOrgGrpObjPowerCapsRequest
  ): Promise<OrganizationGroupObjectPowerCaps> {
    return BitkeyPlatformAPI.callApi<OrganizationGroupObjectPowerCaps>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.put(
        `/organization_groups/${organizationGroupId}/objects/${objectId}/power_caps`,
        BitkeyPlatformAPI.toRequest(requestBody)
      )
    );
  }

  /**
   * 組織グループが子の組織グループに許可している伝搬権限を参照します。
   *
   * これにより制御される権限は、path で指定したオブジェクトを Child の組織グループのメンバーが操作する際に参照されます。
   * Organization Group Power Caps で直接権限を許可している場合は、そちらが優先されます。
   */
  public static async listOrgGrpPropagationObjectPowers(
    organizationGroupId: string,
    objectId: string,
    queryParameters: ListOrgGrpPropagationObjectPowersQuery
  ): Promise<OrganizationGroupPropagationObjectPowers[]> {
    return BitkeyPlatformAPI.callApi<OrganizationGroupPropagationObjectPowers[]>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.get(
        `/organization_groups/${organizationGroupId}/objects/${objectId}/propagation_powers`,
        {
          params: BitkeyPlatformAPI.toRequest(queryParameters),
        }
      )
    );
  }

  /**
   * 親の組織グループが子の組織グループに許可している伝搬権限を更新します。
   *
   * これにより制御される権限は、path で指定したオブジェクトを Child の組織グループのメンバーが操作する際に参照されます。
   * Organization Group Power Caps で直接権限を許可している場合は、そちらが優先されます。
   */
  public static async updateOrgGrpPropagationObjectPower(
    organizationGroupId: string,
    objectId: string,
    requestBody: UpdateOrgGrpPropagationObjectPowerRequest
  ): Promise<OrganizationGroupPropagationObjectPowers> {
    return BitkeyPlatformAPI.callApi<OrganizationGroupPropagationObjectPowers>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.put(
        `/organization_groups/${organizationGroupId}/objects/${objectId}/propagation_powers`,
        BitkeyPlatformAPI.toRequest(requestBody)
      )
    );
  }

  /**
   * Organization group に所属している Organization の一覧を取得します。
   */
  public static async listOrganizationsByOrganizationGroup(
    organizationGroupId: string,
    queryParameters: ListOrganizationsByOrganizationGroupQuery
  ): Promise<Organization[]> {
    return BitkeyPlatformAPI.callApi<Organization[]>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.get(`/organization_groups/${organizationGroupId}/organizations`, {
        params: BitkeyPlatformAPI.toRequest(queryParameters),
      })
    );
  }

  /**
   * Organization group に所属している Organization の情報を参照します。
   */
  public static async findOrganizationByOrganizationGroup(
    organizationGroupId: string,
    organizationId: string
  ): Promise<Organization> {
    return BitkeyPlatformAPI.callApi<Organization>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.get(
        `/organization_groups/${organizationGroupId}/organizations/${organizationId}`
      )
    );
  }

  /**
   * Organization group から Organization から排除します。
   */
  public static async removeOrganizationByOrganizationGroup(
    organizationGroupId: string,
    organizationId: string
  ): Promise<void> {
    return BitkeyPlatformAPI.callApi<void>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.delete(
        `/organization_groups/${organizationGroupId}/organizations/${organizationId}`
      )
    );
  }

  /**
   * 組織グループに貸し出されたホールに対してペルソナが保持する権限を参照します。
   */
  public static async findOrgGroupPersonaLentHoleRight(
    organizationGroupId: string,
    personaId: string,
    contractId: string
  ): Promise<OrganizationGroupPersonaLentHoleRight> {
    return BitkeyPlatformAPI.callApi<OrganizationGroupPersonaLentHoleRight>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.get(
        `/organization_groups/${organizationGroupId}/personas/${personaId}/hole_contracts/${contractId}/rights`
      )
    );
  }

  /**
   * 組織グループに貸し出されたホールに対してペルソナが保持する権限を更新します。
   */
  public static async updateOrgGroupPersonaLentHoleRight(
    organizationGroupId: string,
    personaId: string,
    contractId: string,
    requestBody: NullableOrganizationGroupLentHolePower
  ): Promise<OrganizationGroupPersonaLentHoleRight> {
    return BitkeyPlatformAPI.callApi<OrganizationGroupPersonaLentHoleRight>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.put(
        `/organization_groups/${organizationGroupId}/personas/${personaId}/hole_contracts/${contractId}/rights`,
        BitkeyPlatformAPI.toRequest(requestBody)
      )
    );
  }

  /**
   * 組織グループ内のロールに対する特定のホールの権限を参照します。
   */
  public static async findOrgGroupPersonaHoleRight(
    organizationGroupId: string,
    personaId: string,
    holeId: string
  ): Promise<OrganizationGroupPersonaHoleRight> {
    return BitkeyPlatformAPI.callApi<OrganizationGroupPersonaHoleRight>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.get(
        `/organization_groups/${organizationGroupId}/personas/${personaId}/holes/${holeId}/rights`
      )
    );
  }

  /**
   * 組織グループ内のロールに対する特定のホールの権限を更新します。
   */
  public static async updateOrgGroupPersonaHoleRight(
    organizationGroupId: string,
    personaId: string,
    holeId: string,
    requestBody: NullableOrganizationGroupHolePower
  ): Promise<OrganizationGroupPersonaHoleRight> {
    return BitkeyPlatformAPI.callApi<OrganizationGroupPersonaHoleRight>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.put(
        `/organization_groups/${organizationGroupId}/personas/${personaId}/holes/${holeId}/rights`,
        BitkeyPlatformAPI.toRequest(requestBody)
      )
    );
  }

  /**
   * 組織グループ内のロールに対する特定のオブジェクトの権限を参照します。
   */
  public static async findOrgGroupPersonaObjectRight(
    organizationGroupId: string,
    personaId: string,
    objectId: string
  ): Promise<OrganizationGroupPersonaObjectRight> {
    return BitkeyPlatformAPI.callApi<OrganizationGroupPersonaObjectRight>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.get(
        `/organization_groups/${organizationGroupId}/personas/${personaId}/objects/${objectId}/rights`
      )
    );
  }

  /**
   * 組織グループ内のロールに対する特定のオブジェクトの権限を更新します。
   */
  public static async updateOrgGroupPersonaObjectRight(
    organizationGroupId: string,
    personaId: string,
    objectId: string,
    requestBody: NullableOrganizationGroupObjectPower
  ): Promise<OrganizationGroupPersonaObjectRight> {
    return BitkeyPlatformAPI.callApi<OrganizationGroupPersonaObjectRight>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.put(
        `/organization_groups/${organizationGroupId}/personas/${personaId}/objects/${objectId}/rights`,
        BitkeyPlatformAPI.toRequest(requestBody)
      )
    );
  }

  /**
   * 組織グループ内のロールが持つ権限を参照します。
   */
  public static async findOrgGroupPersonaRight(
    organizationGroupId: string,
    personaId: string
  ): Promise<OrganizationGroupPersonaRight> {
    return BitkeyPlatformAPI.callApi<OrganizationGroupPersonaRight>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.get(
        `/organization_groups/${organizationGroupId}/personas/${personaId}/rights`
      )
    );
  }

  /**
   * 組織グループ内のロールが持つ権限を更新します。
   */
  public static async updateOrgGroupPersonaRight(
    organizationGroupId: string,
    personaId: string,
    requestBody: NullableOrganizationGroupPower
  ): Promise<OrganizationGroupPersonaRight> {
    return BitkeyPlatformAPI.callApi<OrganizationGroupPersonaRight>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.put(
        `/organization_groups/${organizationGroupId}/personas/${personaId}/rights`,
        BitkeyPlatformAPI.toRequest(requestBody)
      )
    );
  }

  /**
   * 組織グループが組織に許可している権限を参照します。
   *
   * これにより制御される権限は、組織グループが所有するオブジェクト等を指定した組織のメンバーが操作する際に参照されます。
   */
  public static async listOrgGrpPowerCaps(
    organizationGroupId: string,
    queryParameters: ListOrgGrpPowerCapsQuery
  ): Promise<OrganizationGroupPowerCaps[]> {
    return BitkeyPlatformAPI.callApi<OrganizationGroupPowerCaps[]>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.get(`/organization_groups/${organizationGroupId}/power_caps`, {
        params: BitkeyPlatformAPI.toRequest(queryParameters),
      })
    );
  }

  /**
   * 組織グループが組織に許可している権限を更新します。
   *
   * これにより制御される権限は、組織グループが所有するオブジェクト等を指定した組織のメンバーが操作する際に参照されます。
   */
  public static async updateOrgGrpPowerCaps(
    organizationGroupId: string,
    requestBody: UpdateOrgGrpPowerCapsRequest
  ): Promise<OrganizationGroupPowerCaps> {
    return BitkeyPlatformAPI.callApi<OrganizationGroupPowerCaps>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.put(
        `/organization_groups/${organizationGroupId}/power_caps`,
        BitkeyPlatformAPI.toRequest(requestBody)
      )
    );
  }

  /**
   * 組織グループが子の組織グループに許可している伝搬権限を参照します。
   *
   * これにより制御される権限は、Origin の組織グループが所有するオブジェクト等を Child の組織グループのメンバーが操作する際に参照されます。
   * Organization Group Power Caps で直接権限を許可している場合は、そちらが優先されます。
   */
  public static async listOrgGrpPropagationPowers(
    organizationGroupId: string,
    queryParameters: ListOrgGrpPropagationPowersQuery
  ): Promise<OrganizationGroupPropagationPowers[]> {
    return BitkeyPlatformAPI.callApi<OrganizationGroupPropagationPowers[]>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.get(`/organization_groups/${organizationGroupId}/propagation_powers`, {
        params: BitkeyPlatformAPI.toRequest(queryParameters),
      })
    );
  }

  /**
   * 親の組織グループが子の組織グループに許可している伝搬権限を更新します。
   *
   * これにより制御される権限は、Origin の組織グループが所有するオブジェクト等を Child の組織グループのメンバーが操作する際に参照されます。
   * Organization Group Power Caps で直接権限を許可している場合は、そちらが優先されます。
   */
  public static async updateOrgGrpPropagationPower(
    organizationGroupId: string,
    requestBody: UpdateOrgGrpPropagationPowerRequest
  ): Promise<OrganizationGroupPropagationPowers> {
    return BitkeyPlatformAPI.callApi<OrganizationGroupPropagationPowers>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.put(
        `/organization_groups/${organizationGroupId}/propagation_powers`,
        BitkeyPlatformAPI.toRequest(requestBody)
      )
    );
  }

  /**
   * 組織グループに貸し出されたホールに対してロールが保持する権限を参照します。
   */
  public static async findOrgGroupRoleLentHolePower(
    organizationGroupId: string,
    roleId: string,
    contractId: string
  ): Promise<OrganizationGroupRoleLentHolePower> {
    return BitkeyPlatformAPI.callApi<OrganizationGroupRoleLentHolePower>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.get(
        `/organization_groups/${organizationGroupId}/roles/${roleId}/hole_contracts/${contractId}/powers`
      )
    );
  }

  /**
   * 組織グループに貸し出されたホールに対してロールが保持する権限を更新します。
   */
  public static async updateOrgGroupRoleLentHolePower(
    organizationGroupId: string,
    roleId: string,
    contractId: string,
    requestBody: NullableOrganizationGroupLentHolePower
  ): Promise<OrganizationGroupRoleLentHolePower> {
    return BitkeyPlatformAPI.callApi<OrganizationGroupRoleLentHolePower>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.put(
        `/organization_groups/${organizationGroupId}/roles/${roleId}/hole_contracts/${contractId}/powers`,
        BitkeyPlatformAPI.toRequest(requestBody)
      )
    );
  }

  /**
   * 組織グループ内のロールに対する特定のホールの権限を参照します。
   */
  public static async findOrgGroupRoleHolePower(
    organizationGroupId: string,
    roleId: string,
    holeId: string
  ): Promise<OrganizationGroupRoleHolePower> {
    return BitkeyPlatformAPI.callApi<OrganizationGroupRoleHolePower>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.get(
        `/organization_groups/${organizationGroupId}/roles/${roleId}/holes/${holeId}/powers`
      )
    );
  }

  /**
   * 組織グループ内のロールに対する特定のホールの権限を更新します。
   */
  public static async updateOrgGroupRoleHolePower(
    organizationGroupId: string,
    roleId: string,
    holeId: string,
    requestBody: NullableOrganizationGroupHolePower
  ): Promise<OrganizationGroupRoleHolePower> {
    return BitkeyPlatformAPI.callApi<OrganizationGroupRoleHolePower>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.put(
        `/organization_groups/${organizationGroupId}/roles/${roleId}/holes/${holeId}/powers`,
        BitkeyPlatformAPI.toRequest(requestBody)
      )
    );
  }

  /**
   * 組織グループ内のロールに対する特定のオブジェクトの権限を参照します。
   */
  public static async findOrgGroupRoleObjectPower(
    organizationGroupId: string,
    roleId: string,
    objectId: string
  ): Promise<OrganizationGroupRoleObjectPower> {
    return BitkeyPlatformAPI.callApi<OrganizationGroupRoleObjectPower>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.get(
        `/organization_groups/${organizationGroupId}/roles/${roleId}/objects/${objectId}/powers`
      )
    );
  }

  /**
   * 組織グループ内のロールに対する特定のオブジェクトの権限を更新します。
   */
  public static async updateOrgGroupRoleObjectPower(
    organizationGroupId: string,
    roleId: string,
    objectId: string,
    requestBody: NullableOrganizationGroupObjectPower
  ): Promise<OrganizationGroupRoleObjectPower> {
    return BitkeyPlatformAPI.callApi<OrganizationGroupRoleObjectPower>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.put(
        `/organization_groups/${organizationGroupId}/roles/${roleId}/objects/${objectId}/powers`,
        BitkeyPlatformAPI.toRequest(requestBody)
      )
    );
  }

  /**
   * 組織グループ内のロールが持つ権限を参照します。
   */
  public static async findOrgGroupRolePower(
    organizationGroupId: string,
    roleId: string
  ): Promise<OrganizationGroupRolePower> {
    return BitkeyPlatformAPI.callApi<OrganizationGroupRolePower>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.get(
        `/organization_groups/${organizationGroupId}/roles/${roleId}/powers`
      )
    );
  }

  /**
   * 組織グループ内のロールが持つ権限を更新します。
   */
  public static async updateOrgGroupRolePower(
    organizationGroupId: string,
    roleId: string,
    requestBody: NullableOrganizationGroupPower
  ): Promise<OrganizationGroupRolePower> {
    return BitkeyPlatformAPI.callApi<OrganizationGroupRolePower>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.put(
        `/organization_groups/${organizationGroupId}/roles/${roleId}/powers`,
        BitkeyPlatformAPI.toRequest(requestBody)
      )
    );
  }

  /**
   * 組織グループに対して共有されているメンバー付与情報の一覧を取得します
   *
   * レスポンスにデータが含まれる場合、組織グループから、対象のメンバー付与情報が利用できます。
   * member_property_control権限がない場合は403エラーとなります。
   */
  public static async listSharedMemberProperties(
    organizationGroupId: string,
    queryParameters: ListSharedMemberPropertiesQuery
  ): Promise<MemberProperty[]> {
    return BitkeyPlatformAPI.callApi<MemberProperty[]>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.get(
        `/organization_groups/${organizationGroupId}/shared_member_properties`,
        {
          params: BitkeyPlatformAPI.toRequest(queryParameters),
        }
      )
    );
  }

  /**
   * 共有されたメンバー付与情報を参照します
   *
   * 共有されたメンバー付与情報の詳細を参照できます。
   * 削除されていた場合は404エラーとなります。
   * member_property_control権限がない場合は403エラーとなります。
   */
  public static async findSharedMemberProperty(
    organizationGroupId: string,
    memberPropertyId: string
  ): Promise<MemberProperty> {
    return BitkeyPlatformAPI.callApi<MemberProperty>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.get(
        `/organization_groups/${organizationGroupId}/shared_member_properties/${memberPropertyId}`
      )
    );
  }

  /**
   * メンバー付与情報の共有を拒否します
   *
   * member_property_control権限がない場合は403エラーとなります。
   */
  public static async rejectSharedMemberProperty(organizationGroupId: string, memberPropertyId: string): Promise<void> {
    return BitkeyPlatformAPI.callApi<void>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.delete(
        `/organization_groups/${organizationGroupId}/shared_member_properties/${memberPropertyId}`
      )
    );
  }

  /**
   * 組織を追加します。
   *
   * 初期的には登録実行者が該当組織の管理者として設定されます。
   */
  public static async addOrganization(requestBody: AddOrganizationRequest): Promise<Organization> {
    return BitkeyPlatformAPI.callApi<Organization>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.post(`/organizations`, BitkeyPlatformAPI.toRequest(requestBody))
    );
  }

  /**
   * 組織の情報を参照します。
   *
   * 組織の情報を参照します。
   * 組織に所属しているペルソナのみ可能です。
   */
  public static async findOrganization(organizationId: string): Promise<Organization> {
    return BitkeyPlatformAPI.callApi<Organization>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.get(`/organizations/${organizationId}`)
    );
  }

  /**
   * 組織の情報を更新します
   *
   * 組織の情報を更新します。
   * 組織のアドミンのみ実行可能です。
   */
  public static async updateOrganization(
    organizationId: string,
    requestBody: UpdateOrganizationRequest
  ): Promise<Organization> {
    return BitkeyPlatformAPI.callApi<Organization>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.put(
        `/organizations/${organizationId}`,
        BitkeyPlatformAPI.toRequest(requestBody)
      )
    );
  }

  /**
   * 組織を削除します。
   *
   * 組織の情報を削除します。
   * 組織のオーナーのみ可能です。
   */
  public static async deleteOrganization(organizationId: string): Promise<void> {
    return BitkeyPlatformAPI.callApi<void>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.delete(`/organizations/${organizationId}`)
    );
  }

  /**
   * 組織オーナー選出設定を参照します
   */
  public static async findOrganizationOwnerElectionSystem(organizationId: string): Promise<ElectionSystem> {
    return BitkeyPlatformAPI.callApi<ElectionSystem>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.get(`/organizations/${organizationId}/election_systems`)
    );
  }

  /**
   * 組織オーナー選出設定を更新します
   */
  public static async updateOrganizationOwnerElectionSystem(
    organizationId: string,
    requestBody: UpdateOrganizationOwnerElectionSystemRequest
  ): Promise<ElectionSystem> {
    return BitkeyPlatformAPI.callApi<ElectionSystem>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.put(
        `/organizations/${organizationId}/election_systems`,
        BitkeyPlatformAPI.toRequest(requestBody)
      )
    );
  }

  /**
   * 組織オーナー選出設定を追加します
   */
  public static async addOrganizationOwnerElectionSystem(
    organizationId: string,
    requestBody: AddOrganizationOwnerElectionSystemRequest
  ): Promise<ElectionSystem> {
    return BitkeyPlatformAPI.callApi<ElectionSystem>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.post(
        `/organizations/${organizationId}/election_systems`,
        BitkeyPlatformAPI.toRequest(requestBody)
      )
    );
  }

  /**
   * 組織オーナー選出設定を削除します
   */
  public static async deleteOrganizationOwnerElectionSystem(organizationId: string): Promise<void> {
    return BitkeyPlatformAPI.callApi<void>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.delete(`/organizations/${organizationId}/election_systems`)
    );
  }

  /**
   * 組織のオーナーの選挙の進捗を参照します
   */
  public static async findOrganizationOwnerElection(organizationId: string): Promise<Elections> {
    return BitkeyPlatformAPI.callApi<Elections>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.get(`/organizations/${organizationId}/elections`)
    );
  }

  /**
   * 組織のオーナーの候補者として立候補し、選挙を開始します。
   */
  public static async startOrganizationOwnerElection(organizationId: string): Promise<void> {
    return BitkeyPlatformAPI.callApi<void>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.post(`/organizations/${organizationId}/elections`)
    );
  }

  /**
   * 組織グループへの招待情報の一覧を取得します。
   *
   * 組織のアドミンのみ参照可能です。
   */
  public static async listGroupInvitation(
    organizationId: string,
    queryParameters: ListGroupInvitationQuery
  ): Promise<OrganizationGroupInvitation[]> {
    return BitkeyPlatformAPI.callApi<OrganizationGroupInvitation[]>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.get(`/organizations/${organizationId}/group_invitations`, {
        params: BitkeyPlatformAPI.toRequest(queryParameters),
      })
    );
  }

  /**
   * 組織グループへの招待情報の一覧を取得します。
   *
   * 組織のアドミンのみ参照可能です。
   */
  public static async findGroupInvitation(
    organizationId: string,
    organizationGroupInvitationId: string
  ): Promise<OrganizationGroupInvitation> {
    return BitkeyPlatformAPI.callApi<OrganizationGroupInvitation>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.get(
        `/organizations/${organizationId}/group_invitations/${organizationGroupInvitationId}`
      )
    );
  }

  /**
   * 組織グループへの組織の招待を承認して、組織グループに参加します。
   *
   * 組織グループの招待を受けている組織のアドミンのみ可能です。
   */
  public static async approveOrganizationGroupInvitation(
    organizationId: string,
    organizationGroupInvitationId: string
  ): Promise<void> {
    return BitkeyPlatformAPI.callApi<void>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.put(
        `/organizations/${organizationId}/group_invitations/${organizationGroupInvitationId}/approve`
      )
    );
  }

  /**
   * 組織グループへの招待を却下します。
   *
   * 組織グループの招待を受けている組織のアドミンのみ可能です。
   */
  public static async rejectOrganizationGroupInvitation(
    organizationId: string,
    organizationGroupInvitationId: string
  ): Promise<void> {
    return BitkeyPlatformAPI.callApi<void>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.put(
        `/organizations/${organizationId}/group_invitations/${organizationGroupInvitationId}/reject`
      )
    );
  }

  /**
   * ユーザを組織に招待します。
   *
   * ユーザを組織に招待します。
   * bodyに入力されたメールアドレス(or 電話番号)宛に、招待メール(or 招待SMS)を送付します。
   * メールアドレス と 電話番号の両方を指定された場合、電話番号は無視されます。
   */
  public static async inviteToOrganization(
    organizationId: string,
    requestBody: InviteToOrganizationRequest
  ): Promise<OrganizationInvitation> {
    return BitkeyPlatformAPI.callApi<OrganizationInvitation>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.post(
        `/organizations/${organizationId}/invitations`,
        BitkeyPlatformAPI.toRequest(requestBody)
      )
    );
  }

  /**
   * 組織が管理するペルソナに割り当てられる固有の属性を一覧取得します
   */
  public static async listMemberProperty(
    organizationId: string,
    queryParameters: ListMemberPropertyQuery
  ): Promise<MemberPropertyWithPersonaIds[]> {
    return BitkeyPlatformAPI.callApi<MemberPropertyWithPersonaIds[]>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.get(`/organizations/${organizationId}/member_properties`, {
        params: BitkeyPlatformAPI.toRequest(queryParameters),
      })
    );
  }

  /**
   * 組織が管理するペルソナに割り当てられる固有の属性を登録します。
   *
   * この API によって定義された属性は、
   * `/personas/{persona_id}/properties` リソースとして
   * `addPersonaMemberProperty` などの操作を行うことができます。
   */
  public static async addMemberProperty(
    organizationId: string,
    requestBody: AddMemberPropertyRequest
  ): Promise<MemberProperty> {
    return BitkeyPlatformAPI.callApi<MemberProperty>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.post(
        `/organizations/${organizationId}/member_properties`,
        BitkeyPlatformAPI.toRequest(requestBody)
      )
    );
  }

  /**
   * メンバー付与情報を参照します。
   *
   * 組織が管理するペルソナに割り当てられる固有の属性を参照します。
   */
  public static async findMemberProperty(organizationId: string, memberPropertyId: string): Promise<MemberProperty> {
    return BitkeyPlatformAPI.callApi<MemberProperty>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.get(
        `/organizations/${organizationId}/member_properties/${memberPropertyId}`
      )
    );
  }

  /**
   * メンバー付与情報を更新します。
   *
   * 組織が管理するペルソナに割り当てられる固有の属性。付与情報とメンバーの紐づけは管理しません。
   * member_property_label_id だけ変えたい場合でも property_name と property_value 入れないと
   * データが空になってしまいます。
   * （property_name/property_valueだけ更新したい場合も同様）
   */
  public static async updateMemberProperty(
    organizationId: string,
    memberPropertyId: string,
    requestBody: UpdateMemberPropertyRequest
  ): Promise<MemberProperty> {
    return BitkeyPlatformAPI.callApi<MemberProperty>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.put(
        `/organizations/${organizationId}/member_properties/${memberPropertyId}`,
        BitkeyPlatformAPI.toRequest(requestBody)
      )
    );
  }

  /**
   * メンバー付与情報を削除します。
   *
   * 組織が管理するペルソナに割り当てられる固有の属性を削除します。付与情報とメンバーの紐づけもなくなります。
   */
  public static async deleteMemberProperty(organizationId: string, memberPropertyId: string): Promise<void> {
    return BitkeyPlatformAPI.callApi<void>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.delete(
        `/organizations/${organizationId}/member_properties/${memberPropertyId}`
      )
    );
  }

  /**
   * メンバー付与情報を、指定した組織グループに対して共有します
   *
   * 利用許可した場合は、組織グループ所有の Treasure Box にメンバー付与情報を紐づけることができます。
   * `shared_member_property_control` 権限がない場合は403エラーとなります。
   */
  public static async addSharedMemberProperty(
    organizationId: string,
    memberPropertyId: string,
    requestBody: AddSharedMemberPropertyRequest
  ): Promise<SharedMemberProperty> {
    return BitkeyPlatformAPI.callApi<SharedMemberProperty>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.put(
        `/organizations/${organizationId}/member_properties/${memberPropertyId}/share`,
        BitkeyPlatformAPI.toRequest(requestBody)
      )
    );
  }

  /**
   * メンバー付与情報の組織グループに対する共有を解除します。
   *
   * 共有を解除すると、組織グループ所有の Treasure Box にメンバー付与情報を紐づけることができなくなります。
   * `shared_member_property_control` 権限がない場合は403エラーとなります。
   */
  public static async deleteSharedMemberProperty(
    organizationId: string,
    memberPropertyId: string,
    requestBody: DeleteSharedMemberPropertyRequest
  ): Promise<void> {
    return BitkeyPlatformAPI.callApi<void>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.put(
        `/organizations/${organizationId}/member_properties/${memberPropertyId}/unshare`,
        BitkeyPlatformAPI.toRequest(requestBody)
      )
    );
  }

  /**
   * 外部IDのラベルを一覧で取得します
   */
  public static async listMemberPropertyLabels(
    organizationId: string,
    queryParameters: ListMemberPropertyLabelsQuery
  ): Promise<MemberPropertyLabel[]> {
    return BitkeyPlatformAPI.callApi<MemberPropertyLabel[]>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.get(`/organizations/${organizationId}/member_property_labels`, {
        params: BitkeyPlatformAPI.toRequest(queryParameters),
      })
    );
  }

  /**
   * 外部IDのラベルを追加します
   */
  public static async addMemberPropertyLabel(
    organizationId: string,
    requestBody: AddMemberPropertyLabelRequest
  ): Promise<MemberPropertyLabel> {
    return BitkeyPlatformAPI.callApi<MemberPropertyLabel>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.post(
        `/organizations/${organizationId}/member_property_labels`,
        BitkeyPlatformAPI.toRequest(requestBody)
      )
    );
  }

  /**
   * 対象となる外部IDのラベルを参照します
   */
  public static async findMemberPropertyLabel(
    organizationId: string,
    memberPropertyLabelId: string
  ): Promise<MemberPropertyLabel> {
    return BitkeyPlatformAPI.callApi<MemberPropertyLabel>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.get(
        `/organizations/${organizationId}/member_property_labels/${memberPropertyLabelId}`
      )
    );
  }

  /**
   * 対象となる外部IDのラベルを変更します
   */
  public static async updateMemberPropertyLabel(
    organizationId: string,
    memberPropertyLabelId: string,
    requestBody: UpdateMemberPropertyLabelRequest
  ): Promise<MemberPropertyLabel> {
    return BitkeyPlatformAPI.callApi<MemberPropertyLabel>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.put(
        `/organizations/${organizationId}/member_property_labels/${memberPropertyLabelId}`,
        BitkeyPlatformAPI.toRequest(requestBody)
      )
    );
  }

  /**
   * 対象となる外部IDのラベルを削除します
   */
  public static async deleteMemberPropertyLabel(organizationId: string, memberPropertyLabelId: string): Promise<void> {
    return BitkeyPlatformAPI.callApi<void>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.delete(
        `/organizations/${organizationId}/member_property_labels/${memberPropertyLabelId}`
      )
    );
  }

  /**
   * ユーザ任意のデータを組織のメンバー付与情報の登録申請を一覧で取得します
   */
  public static async listMemberPropertySubmissions(
    organizationId: string,
    queryParameters: ListMemberPropertySubmissionsQuery
  ): Promise<MemberPropertySubmission[]> {
    return BitkeyPlatformAPI.callApi<MemberPropertySubmission[]>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.get(`/organizations/${organizationId}/member_property_submissions`, {
        params: BitkeyPlatformAPI.toRequest(queryParameters),
      })
    );
  }

  /**
   * ユーザ任意のデータを組織のメンバー付与情報として登録申請を行います
   */
  public static async addMemberPropertySubmissions(
    organizationId: string,
    requestBody: AddMemberPropertySubmissionsRequest
  ): Promise<MemberPropertySubmission> {
    return BitkeyPlatformAPI.callApi<MemberPropertySubmission>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.post(
        `/organizations/${organizationId}/member_property_submissions`,
        BitkeyPlatformAPI.toRequest(requestBody)
      )
    );
  }

  /**
   * ユーザ任意のデータを組織のメンバー付与情報の登録申請をキャンセルします
   */
  public static async deleteMemberPropertySubmissions(
    organizationId: string,
    memberPropertySubmissionId: string
  ): Promise<void> {
    return BitkeyPlatformAPI.callApi<void>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.delete(
        `/organizations/${organizationId}/member_property_submissions/${memberPropertySubmissionId}`
      )
    );
  }

  /**
   * ユーザ任意のデータを組織のメンバー付与情報の登録申請を承認します
   */
  public static async approveMemberPropertySubmissions(
    organizationId: string,
    memberPropertySubmissionId: string
  ): Promise<MemberPropertySubmission> {
    return BitkeyPlatformAPI.callApi<MemberPropertySubmission>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.put(
        `/organizations/${organizationId}/member_property_submissions/${memberPropertySubmissionId}/approve`
      )
    );
  }

  /**
   * 組織に所属しているペルソナを取得します。
   */
  public static async searchPersonaByOrganization(organizationId: string): Promise<Persona[]> {
    return BitkeyPlatformAPI.callApi<Persona[]>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.get(`/organizations/${organizationId}/members`)
    );
  }

  /**
   * 組織にペルソナを作成し、ユーザを追加します。
   *
   * 組織にペルソナを作成し、ユーザを追加します。
   * user_id と user_alias_code はどちらかを入力してください。
   * user_alias_code は `POST /users/alias_codes` のレスポンスで取得できる alias_code です。
   * user_id と user_alias_code を両方入力した場合は、 user_alias_code は無視されます。
   */
  public static async addPersonaToOrganization(
    organizationId: string,
    requestBody: AddPersonaToOrganizationRequest
  ): Promise<Persona> {
    return BitkeyPlatformAPI.callApi<Persona>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.post(
        `/organizations/${organizationId}/members`,
        BitkeyPlatformAPI.toRequest(requestBody)
      )
    );
  }

  /**
   * ペルソナを組織内から探します。
   */
  public static async findPersona(organizationId: string, personaId: string): Promise<Persona> {
    return BitkeyPlatformAPI.callApi<Persona>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.get(`/organizations/${organizationId}/members/${personaId}`)
    );
  }

  /**
   * ペルソナを組織から排除します。
   */
  public static async removePersonaFromOrganization(organizationId: string, personaId: string): Promise<Persona> {
    return BitkeyPlatformAPI.callApi<Persona>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.delete(`/organizations/${organizationId}/members/${personaId}`)
    );
  }

  /**
   * 組織に貸し出されたホールに対してペルソナが保持する権限を参照します。
   */
  public static async findPersonaLentHoleRights(
    organizationId: string,
    personaId: string,
    contractId: string
  ): Promise<PersonaLentHoleRight> {
    return BitkeyPlatformAPI.callApi<PersonaLentHoleRight>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.get(
        `/organizations/${organizationId}/members/${personaId}/hole_contract/${contractId}/rights`
      )
    );
  }

  /**
   * 組織に貸し出されたホールに対してペルソナが保持する権限を更新します。
   */
  public static async updatePersonaLentHoleRights(
    organizationId: string,
    personaId: string,
    contractId: string,
    requestBody: NullableLentHolePower
  ): Promise<PersonaLentHoleRight> {
    return BitkeyPlatformAPI.callApi<PersonaLentHoleRight>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.put(
        `/organizations/${organizationId}/members/${personaId}/hole_contract/${contractId}/rights`,
        BitkeyPlatformAPI.toRequest(requestBody)
      )
    );
  }

  /**
   * 組織内のペルソナに対する特定のホールの権限を取得します。
   */
  public static async findPersonaHoleRights(
    organizationId: string,
    personaId: string,
    holeId: string
  ): Promise<PersonaHoleRight> {
    return BitkeyPlatformAPI.callApi<PersonaHoleRight>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.get(
        `/organizations/${organizationId}/members/${personaId}/holes/${holeId}/rights`
      )
    );
  }

  /**
   * 組織内のペルソナに対する特定のホールの権限を更新します。
   */
  public static async updatePersonaHoleRights(
    organizationId: string,
    personaId: string,
    holeId: string,
    requestBody: NullableHolePower
  ): Promise<PersonaHoleRight> {
    return BitkeyPlatformAPI.callApi<PersonaHoleRight>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.put(
        `/organizations/${organizationId}/members/${personaId}/holes/${holeId}/rights`,
        BitkeyPlatformAPI.toRequest(requestBody)
      )
    );
  }

  /**
   * 組織内のペルソナに対する特定のオブジェクトの権限を取得します。
   */
  public static async findPersonaObjectRights(
    organizationId: string,
    personaId: string,
    objectId: string
  ): Promise<PersonaObjectRight> {
    return BitkeyPlatformAPI.callApi<PersonaObjectRight>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.get(
        `/organizations/${organizationId}/members/${personaId}/objects/${objectId}/rights`
      )
    );
  }

  /**
   * 組織内のペルソナに対する特定のオブジェクトの権限を更新します。
   */
  public static async updatePersonaObjectRights(
    organizationId: string,
    personaId: string,
    objectId: string,
    requestBody: NullableObjectPower
  ): Promise<PersonaObjectRight> {
    return BitkeyPlatformAPI.callApi<PersonaObjectRight>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.put(
        `/organizations/${organizationId}/members/${personaId}/objects/${objectId}/rights`,
        BitkeyPlatformAPI.toRequest(requestBody)
      )
    );
  }

  /**
   * 組織内のペルソナに対する権限を取得します。
   */
  public static async findPersonaRights(organizationId: string, personaId: string): Promise<PersonaRight> {
    return BitkeyPlatformAPI.callApi<PersonaRight>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.get(`/organizations/${organizationId}/members/${personaId}/rights`)
    );
  }

  /**
   * 組織内のペルソナに対する権限を更新します。
   */
  public static async updatePersonaRights(
    organizationId: string,
    personaId: string,
    requestBody: NullablePower
  ): Promise<PersonaRight> {
    return BitkeyPlatformAPI.callApi<PersonaRight>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.put(
        `/organizations/${organizationId}/members/${personaId}/rights`,
        BitkeyPlatformAPI.toRequest(requestBody)
      )
    );
  }

  /**
   * ペルソナに紐づいているロールを更新します。
   *
   * ｜ 対象組織の管理者権限に基づいて、ペルソナのロールを更新します。 組織オーナーペルソナのロールは更新できません。
   */
  public static async updatePersonaRole(
    organizationId: string,
    personaId: string,
    requestBody: UpdatePersonaRoleRequest
  ): Promise<Persona> {
    return BitkeyPlatformAPI.callApi<Persona>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.put(
        `/organizations/${organizationId}/members/${personaId}/update_role`,
        BitkeyPlatformAPI.toRequest(requestBody)
      )
    );
  }

  /**
   * Organization が所属している Organization Group の一覧を取得します。
   *
   * Organization group に所属している Organization の一覧を取得します。
   */
  public static async listOrganizationGroupsByOrganization(
    organizationId: string,
    queryParameters: ListOrganizationGroupsByOrganizationQuery
  ): Promise<OrganizationGroup[]> {
    return BitkeyPlatformAPI.callApi<OrganizationGroup[]>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.get(`/organizations/${organizationId}/organization_groups`, {
        params: BitkeyPlatformAPI.toRequest(queryParameters),
      })
    );
  }

  /**
   * Organization が所属している Organization Group の情報を参照します。
   *
   * Organization group に所属している Organization の情報を参照します。
   */
  public static async findOrganizationGroupByOrganization(
    organizationId: string,
    organizationGroupId: string
  ): Promise<OrganizationGroup> {
    return BitkeyPlatformAPI.callApi<OrganizationGroup>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.get(
        `/organizations/${organizationId}/organization_groups/${organizationGroupId}`
      )
    );
  }

  /**
   * 組織のオーナーを新しいオーナーに移譲します。
   */
  public static async updateOrganizationOwner(
    organizationId: string,
    requestBody: UpdateOrganizationOwnerRequest
  ): Promise<Organization> {
    return BitkeyPlatformAPI.callApi<Organization>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.put(
        `/organizations/${organizationId}/owners`,
        BitkeyPlatformAPI.toRequest(requestBody)
      )
    );
  }

  /**
   * 組織を指定した個人または組織に対して公開します
   */
  public static async addOrganizationPublishSetting(organizationId: string, requestBody: Personality): Promise<void> {
    return BitkeyPlatformAPI.callApi<void>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.post(
        `/organizations/${organizationId}/publish_settings`,
        BitkeyPlatformAPI.toRequest(requestBody)
      )
    );
  }

  /**
   * 組織内のロールの一覧を参照します。
   */
  public static async findRoles(organizationId: string): Promise<Role[]> {
    return BitkeyPlatformAPI.callApi<Role[]>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.get(`/organizations/${organizationId}/roles`)
    );
  }

  /**
   * 組織内にロールを追加します。
   *
   * 対象組織の管理者権限に基づいて、ロールを追加します。
   */
  public static async addRole(organizationId: string, requestBody: AddRoleRequest): Promise<Role> {
    return BitkeyPlatformAPI.callApi<Role>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.post(
        `/organizations/${organizationId}/roles`,
        BitkeyPlatformAPI.toRequest(requestBody)
      )
    );
  }

  /**
   * 組織内のロールを参照します。
   */
  public static async findRole(organizationId: string, roleId: string): Promise<Role> {
    return BitkeyPlatformAPI.callApi<Role>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.get(`/organizations/${organizationId}/roles/${roleId}`)
    );
  }

  /**
   * 組織内のロールを削除します。
   *
   * 対象組織の管理者権限に基づいて、組織内のロールを削除します。
   * 削除対象のロールに管理権限がついていないことが条件です。
   */
  public static async deleteRole(organizationId: string, roleId: string): Promise<void> {
    return BitkeyPlatformAPI.callApi<void>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.delete(`/organizations/${organizationId}/roles/${roleId}`)
    );
  }

  /**
   * 組織に貸し出されたホールに対してロールが保持する権限を参照します。
   */
  public static async findRoleLentHolePower(
    organizationId: string,
    roleId: string,
    contractId: string
  ): Promise<RoleLentHolePower> {
    return BitkeyPlatformAPI.callApi<RoleLentHolePower>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.get(
        `/organizations/${organizationId}/roles/${roleId}/hole_contracts/${contractId}/powers`
      )
    );
  }

  /**
   * 組織に貸し出されたホールに対してロールが保持する権限を更新します。
   *
   * ロールが属する組織の管理者権限に基づいて、ロールの権限を更新します。
   * ロールが持つ権限のうち、所属する組織の貸し出された特定のホールに対する権限を
   * 上書きしたい場合に設定します。
   */
  public static async updateRoleLentHolePower(
    organizationId: string,
    roleId: string,
    contractId: string,
    requestBody: NullableLentHolePower
  ): Promise<RoleLentHolePower> {
    return BitkeyPlatformAPI.callApi<RoleLentHolePower>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.put(
        `/organizations/${organizationId}/roles/${roleId}/hole_contracts/${contractId}/powers`,
        BitkeyPlatformAPI.toRequest(requestBody)
      )
    );
  }

  /**
   * 組織内のロールに対する特定のホールの権限を参照します。
   */
  public static async findRoleHolePower(
    organizationId: string,
    roleId: string,
    holeId: string
  ): Promise<RoleHolePower> {
    return BitkeyPlatformAPI.callApi<RoleHolePower>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.get(
        `/organizations/${organizationId}/roles/${roleId}/holes/${holeId}/powers`
      )
    );
  }

  /**
   * 組織内のロールに対する特定のホールの権限を更新します。
   *
   * ロールが属する組織の管理者権限に基づいて、ロールの権限を更新します。
   * ロールが持つ権限のうち、特定のホールに対する権限を上書きしたい場合に設定します。
   */
  public static async updateRoleHolePower(
    organizationId: string,
    roleId: string,
    holeId: string,
    requestBody: NullableHolePower
  ): Promise<RoleHolePower> {
    return BitkeyPlatformAPI.callApi<RoleHolePower>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.put(
        `/organizations/${organizationId}/roles/${roleId}/holes/${holeId}/powers`,
        BitkeyPlatformAPI.toRequest(requestBody)
      )
    );
  }

  /**
   * 組織内のロールに対する特定のオブジェクトの権限を参照します。
   */
  public static async findRoleObjectPower(
    organizationId: string,
    roleId: string,
    objectId: string
  ): Promise<RoleObjectPower> {
    return BitkeyPlatformAPI.callApi<RoleObjectPower>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.get(
        `/organizations/${organizationId}/roles/${roleId}/objects/${objectId}/powers`
      )
    );
  }

  /**
   * 組織内のロールに対する特定のオブジェクトの権限を更新します。
   *
   * ロールが属する組織の管理者権限に基づいて、ロールの権限を更新します。
   * ロールが持つ権限のうち、特定のオブジェクトに対する権限を上書きしたい場合に設定します。
   */
  public static async updateRoleObjectPower(
    organizationId: string,
    roleId: string,
    objectId: string,
    requestBody: NullableObjectPower
  ): Promise<RoleObjectPower> {
    return BitkeyPlatformAPI.callApi<RoleObjectPower>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.put(
        `/organizations/${organizationId}/roles/${roleId}/objects/${objectId}/powers`,
        BitkeyPlatformAPI.toRequest(requestBody)
      )
    );
  }

  /**
   * 組織内のロールが持つ権限を参照します。
   */
  public static async findRolePower(organizationId: string, roleId: string): Promise<RolePower> {
    return BitkeyPlatformAPI.callApi<RolePower>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.get(`/organizations/${organizationId}/roles/${roleId}/powers`)
    );
  }

  /**
   * 組織内のロールが持つ権限を更新します。
   *
   * ロールが属する組織の管理者権限に基づいて、ロールの権限を更新します。
   */
  public static async updateRolePower(
    organizationId: string,
    roleId: string,
    requestBody: NullablePower
  ): Promise<RolePower> {
    return BitkeyPlatformAPI.callApi<RolePower>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.put(
        `/organizations/${organizationId}/roles/${roleId}/powers`,
        BitkeyPlatformAPI.toRequest(requestBody)
      )
    );
  }

  /**
   * ロールの認証に必要な認証タイプを取得します。
   *
   * ロールに該当するペルソナが認証する際に必要な認証タイプを取得します。
   * 認証タイプが複数の場合は、そのすべての認証タイプがペルソナの認証時に要求されます。
   * 実行には管理者権限が必要です。
   */
  public static async findRoleRequiredAuthnTypes(
    organizationId: string,
    roleId: string
  ): Promise<RoleRequiredAuthnTypes> {
    return BitkeyPlatformAPI.callApi<RoleRequiredAuthnTypes>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.get(
        `/organizations/${organizationId}/roles/${roleId}/required_authn_types`
      )
    );
  }

  /**
   * ロールの認証に必要な認証タイプを変更します。
   *
   * ロールに該当するペルソナが認証する際に必要な認証タイプを変更します。
   * 認証タイプを複数個のリクエストに含めた場合は、そのすべての認証タイプがペルソナの認証時に要求されます。
   * 実行には管理者権限が必要です。
   */
  public static async updateRoleRequiredAuthnTypes(
    organizationId: string,
    roleId: string,
    requestBody: AuthnTypes
  ): Promise<RoleRequiredAuthnTypes> {
    return BitkeyPlatformAPI.callApi<RoleRequiredAuthnTypes>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.put(
        `/organizations/${organizationId}/roles/${roleId}/required_authn_types`,
        BitkeyPlatformAPI.toRequest(requestBody)
      )
    );
  }

  /**
   * サービスアカウントの一覧を取得します。
   *
   * 操作者が所有するサービスアカウントの一覧を取得します。
   */
  public static async listServiceAccounts(organizationId: string): Promise<ServiceAccount[]> {
    return BitkeyPlatformAPI.callApi<ServiceAccount[]>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.get(`/organizations/${organizationId}/service_accounts`)
    );
  }

  /**
   * サービスアカウントを登録します。
   *
   * 登録するとアクセストークンとリフレッシュトークンが発行されます。
   */
  public static async addServiceAccount(
    organizationId: string,
    requestBody: AddServiceAccountRequest
  ): Promise<AddServiceAccountResponse> {
    return BitkeyPlatformAPI.callApi<AddServiceAccountResponse>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.post(
        `/organizations/${organizationId}/service_accounts`,
        BitkeyPlatformAPI.toRequest(requestBody)
      )
    );
  }

  /**
   * サービスアカウントを取得します。
   *
   * 操作者が所有するサービスアカウントのみ取得可能です。
   */
  public static async findServiceAccount(organizationId: string, serviceAccountId: string): Promise<ServiceAccount> {
    return BitkeyPlatformAPI.callApi<ServiceAccount>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.get(
        `/organizations/${organizationId}/service_accounts/${serviceAccountId}`
      )
    );
  }

  /**
   * サービスアカウントの名称、および、ロールを更新します。
   *
   * 操作者が所有するサービスアカウントに対してのみ実行可能です。
   */
  public static async updateServiceAccount(
    organizationId: string,
    serviceAccountId: string,
    requestBody: UpdateServiceAccountRequest
  ): Promise<ServiceAccount> {
    return BitkeyPlatformAPI.callApi<ServiceAccount>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.put(
        `/organizations/${organizationId}/service_accounts/${serviceAccountId}`,
        BitkeyPlatformAPI.toRequest(requestBody)
      )
    );
  }

  /**
   * サービスアカウントを削除します。
   *
   * 操作者が所有するサービスアカウントに対してのみ実行可能です。
   */
  public static async deleteServiceAccount(organizationId: string, serviceAccountId: string): Promise<void> {
    return BitkeyPlatformAPI.callApi<void>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.delete(
        `/organizations/${organizationId}/service_accounts/${serviceAccountId}`
      )
    );
  }

  /**
   * サービスアカウント用のアクセストークンを再発行します。
   */
  public static async issueServiceAccountToken(
    organizationId: string,
    serviceAccountId: string
  ): Promise<ServiceAccountToken> {
    return BitkeyPlatformAPI.callApi<ServiceAccountToken>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.post(
        `/organizations/${organizationId}/service_accounts/${serviceAccountId}/access_tokens`
      )
    );
  }

  /**
   * リフレッシュトークンを利用してアクセストークンを発行し直します。
   */
  public static async refreshServiceAccountAccessToken(
    organizationId: string,
    serviceAccountId: string,
    requestBody: RefreshServiceAccountAccessTokenRequest
  ): Promise<ServiceAccountToken> {
    return BitkeyPlatformAPI.toResponse<ServiceAccountToken>(
      BitkeyPlatformAPI.instance.axiosWithoutToken.post(
        `/organizations/${organizationId}/service_accounts/${serviceAccountId}/access_tokens/refresh`,
        BitkeyPlatformAPI.toRequest(requestBody)
      )
    );
  }

  /**
   * HTTP ヘッダーにセットされたサービスアカウント用のアクセストークンを検証します。
   */
  public static async verifyServiceAccountAccessToken(
    organizationId: string,
    serviceAccountId: string
  ): Promise<VerifyServiceAccountAccessTokenResponse> {
    return BitkeyPlatformAPI.callApi<VerifyServiceAccountAccessTokenResponse>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.post(
        `/organizations/${organizationId}/service_accounts/${serviceAccountId}/access_tokens/verify`
      )
    );
  }

  /**
   * リフレッシュトークンを無効化します。
   */
  public static async deactivateServiceAccountRefreshTokens(
    organizationId: string,
    serviceAccountId: string,
    requestBody: DeactivateServiceAccountRefreshTokensRequest
  ): Promise<void> {
    return BitkeyPlatformAPI.callApi<void>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.put(
        `/organizations/${organizationId}/service_accounts/${serviceAccountId}/refresh_tokens/deactivate`,
        BitkeyPlatformAPI.toRequest(requestBody)
      )
    );
  }

  /**
   * サービスアカウントのサスペンド状況を更新します。
   *
   * 操作者が所有するサービスアカウントに対してのみ実行可能です。
   */
  public static async updateServiceAccountSuspended(
    organizationId: string,
    serviceAccountId: string,
    requestBody: UpdateServiceAccountSuspendedRequest
  ): Promise<ServiceAccount> {
    return BitkeyPlatformAPI.callApi<ServiceAccount>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.put(
        `/organizations/${organizationId}/service_accounts/${serviceAccountId}/suspended`,
        BitkeyPlatformAPI.toRequest(requestBody)
      )
    );
  }

  /**
   * 組織が他の組織のペルソナからの切り替えを許可するかを設定します。
   *
   * `switchable` パラメータを変更します。
   * 組織のアドミンのみ実行可能です。
   */
  public static async updateSwitchable(organizationId: string, requestBody: UpdateSwitchableRequest): Promise<void> {
    return BitkeyPlatformAPI.callApi<void>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.put(
        `/organizations/${organizationId}/switchable`,
        BitkeyPlatformAPI.toRequest(requestBody)
      )
    );
  }

  /**
   * 組織を指定した個人または組織に対して非公開にします
   */
  public static async addOrganizationUnpublishSetting(organizationId: string, requestBody: Personality): Promise<void> {
    return BitkeyPlatformAPI.callApi<void>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.post(
        `/organizations/${organizationId}/unpublish_settings`,
        BitkeyPlatformAPI.toRequest(requestBody)
      )
    );
  }

  /**
   * 組織のオーナーの選挙に賛成票を投票します
   */
  public static async addVoteOrganizationOwner(organizationId: string): Promise<void> {
    return BitkeyPlatformAPI.callApi<void>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.post(`/organizations/${organizationId}/votes`)
    );
  }

  /**
   * トークンを利用してパスワードを一括でリセットします。
   *
   * トークンを利用してパスワードをリセットします。
   * 操作者のメールアドレス(or 電話番号)に紐づくペルソナ全てのパスワードをリセットします。
   */
  public static async resetPasswordBulkly(
    requestBody: ResetPasswordBulklyRequest
  ): Promise<MultipleAuthenticationResult> {
    return BitkeyPlatformAPI.toResponse<MultipleAuthenticationResult>(
      BitkeyPlatformAPI.instance.axiosWithoutToken.put(
        `/passwords/bulk_reset`,
        BitkeyPlatformAPI.toRequest(requestBody)
      )
    );
  }

  /**
   * 一括パスワードリセットのためのトークンを発行します。
   *
   * 指定されたメールアドレス(or 電話番号)に紐づくペルソナ全てのパスワードをリセットするためのトークンを発行します。
   * メールアドレス と 電話番号の両方を指定された場合、電話番号は無視されます。
   * ※ システム上に登録されていないメールアドレス(or 電話番号)には送信されません。
   */
  public static async issueBulkPasswordResetToken(
    requestBody: IssueBulkPasswordResetTokenRequest
  ): Promise<IssueBulkPasswordResetTokenResponse> {
    return BitkeyPlatformAPI.toResponse<IssueBulkPasswordResetTokenResponse>(
      BitkeyPlatformAPI.instance.axiosWithoutToken.post(
        `/passwords/bulk_reset`,
        BitkeyPlatformAPI.toRequest(requestBody)
      )
    );
  }

  /**
   * パスワードを一括更新します。
   */
  public static async updatePersonaPasswordBulkly(requestBody: UpdatePersonaPasswordBulklyRequest): Promise<void> {
    return BitkeyPlatformAPI.callApi<void>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.put(`/passwords/bulk_update`, BitkeyPlatformAPI.toRequest(requestBody))
    );
  }

  /**
   * トークンを利用してパスワードをリセットします。
   */
  public static async resetPassword(requestBody: ResetPasswordRequest): Promise<AuthenticationResult> {
    return BitkeyPlatformAPI.toResponse<AuthenticationResult>(
      BitkeyPlatformAPI.instance.axiosWithoutToken.put(`/passwords/reset`, BitkeyPlatformAPI.toRequest(requestBody))
    );
  }

  /**
   * パスワードリセットのためのトークンを発行します。
   *
   * システム上に登録されていないメールアドレス、もしくは電話番号には送信されません。
   * persona_id が未指定の場合はデフォルトのペルソナが対象となります。
   */
  public static async issuePasswordResetToken(requestBody: IssuePasswordResetTokenRequest): Promise<void> {
    return BitkeyPlatformAPI.toResponse<void>(
      BitkeyPlatformAPI.instance.axiosWithoutToken.post(`/passwords/reset`, BitkeyPlatformAPI.toRequest(requestBody))
    );
  }

  /**
   * ペルソナを認証してAPIトークンを発行します。
   *
   * 指定された認証情報を元にペルソナを認証して、APIトークンを発行します。
   * メールアドレス と 電話番号の両方を指定された場合、電話番号は無視されます。
   */
  public static async authenticatePersonaDirectly(
    requestBody: PersonaAuthenticationRequest
  ): Promise<MultipleAuthenticationResult> {
    return BitkeyPlatformAPI.toResponse<MultipleAuthenticationResult>(
      BitkeyPlatformAPI.instance.axiosWithoutToken.post(`/personas/auth`, BitkeyPlatformAPI.toRequest(requestBody))
    );
  }

  /**
   * デフォルトペルソナのメールアドレスを変更します。
   *
   * 事前にセッションを開始し、パスコードをメールから入手してください。
   */
  public static async updatePersonaEmail(requestBody: UpdatePersonaEmailRequest): Promise<void> {
    return BitkeyPlatformAPI.callApi<void>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.put(`/personas/email`, BitkeyPlatformAPI.toRequest(requestBody))
    );
  }

  /**
   * ログインしているペルソナのメール変更のセッションを開始します。
   *
   * 新しいメールアドレス宛にコードが発行されます。
   */
  public static async startUpdatePersonaEmail(requestBody: StartUpdatePersonaEmailRequest): Promise<void> {
    return BitkeyPlatformAPI.callApi<void>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.post(`/personas/email`, BitkeyPlatformAPI.toRequest(requestBody))
    );
  }

  /**
   * ログインしているペルソナの電話番号を変更します。
   *
   * 事前にセッションを開始し、パスコードをSMSから入手してください。
   */
  public static async updatePersonaPhone(requestBody: UpdatePersonaPhoneRequest): Promise<void> {
    return BitkeyPlatformAPI.callApi<void>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.put(`/personas/phone`, BitkeyPlatformAPI.toRequest(requestBody))
    );
  }

  /**
   * ログインしているペルソナの電話番号変更のセッションを開始します。
   *
   * 新しい電話番号宛にコードが発行されます。
   */
  public static async startUpdatePersonaPhone(requestBody: StartUpdatePersonaPhoneRequest): Promise<void> {
    return BitkeyPlatformAPI.callApi<void>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.post(`/personas/phone`, BitkeyPlatformAPI.toRequest(requestBody))
    );
  }

  /**
   * ペルソナIDを指定して認証し、APIトークンを発行します。
   *
   * 指定された認証情報を元にペルソナを認証して、APIトークンを発行します。
   * ペルソナIDに対応するpasswordもしくはmultiple_choice_session_keyが含まれている必要があります。
   * 端末認証を利用する場合は、事前に署名対象となる乱数を取得して、
   * 署名結果とともにパラメータに指定する必要があります。
   * 端末認証を利用する設定となっており、かつ登録端末以外からの操作の場合、
   * 別途送付されるパスコードを利用してペルソナの認証(/pass_code_auth)を実行し、その後、端末の追加を実行することができます。
   * パスコードは一度トライして失敗した場合無効となるため、再度パスコードなしでリクエストを実行し、パスコードを送付する必要がある。
   */
  public static async authenticatePersona(
    personaId: string,
    requestBody: AuthenticatePersonaRequest
  ): Promise<AuthenticationResult> {
    return BitkeyPlatformAPI.toResponse<AuthenticationResult>(
      BitkeyPlatformAPI.instance.axiosWithoutToken.post(
        `/personas/${personaId}/auth`,
        BitkeyPlatformAPI.toRequest(requestBody)
      )
    );
  }

  /**
   * 公開鍵の登録・認証に必要な乱数を生成します。
   *
   * この API によって生成された乱数は、
   * 公開鍵の登録・秘密鍵を所有していることを根拠とする認証時に署名されます。
   * サーバが生成した毎度異なる値を署名することで、
   * 過去に手に入れた署名を再度利用するリプレイ攻撃の発生を防ぎます。
   */
  public static async issueNonce(personaId: string): Promise<IssueNonceResponse> {
    return BitkeyPlatformAPI.toResponse<IssueNonceResponse>(
      BitkeyPlatformAPI.instance.axiosWithoutToken.post(`/personas/${personaId}/nonce`)
    );
  }

  /**
   * ペルソナが承認した OAuth のクライアントの一覧を取得します、
   */
  public static async listOAUTHClientsByPersona(personaId: string): Promise<OauthClient[]> {
    return BitkeyPlatformAPI.callApi<OauthClient[]>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.get(`/personas/${personaId}/oauth_clients`)
    );
  }

  /**
   * OAuth のクライアントの連携を取り消します。
   */
  public static async removeOAUTHClient(personaId: string, clientId: string): Promise<void> {
    return BitkeyPlatformAPI.callApi<void>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.delete(`/personas/${personaId}/oauth_clients/${clientId}`)
    );
  }

  /**
   * パスコードによってペルソナを認証してAPIトークンを発行します。
   *
   * ペルソナIDに対応するpasswordもしくはmultiple_choice_session_keyが含まれている必要があります。
   * 端末認証を利用する設定がされており、登録端末による署名が生成できない場合、
   * メール or SMSで送付されるパスコードを利用してペルソナを認証することができます。
   */
  public static async authenticatePersonaWithPassCode(
    personaId: string,
    requestBody: AuthenticatePersonaWithPassCodeRequest
  ): Promise<AuthenticationResult> {
    return BitkeyPlatformAPI.toResponse<AuthenticationResult>(
      BitkeyPlatformAPI.instance.axiosWithoutToken.post(
        `/personas/${personaId}/pass_code_auth`,
        BitkeyPlatformAPI.toRequest(requestBody)
      )
    );
  }

  /**
   * 他のペルソナから切り替えます。
   *
   * 組織で有効になっている場合、パスワード無しでペルソナを切り替えることができます。
   */
  public static async switchPersonaWithPassCode(
    personaId: string,
    requestBody: SwitchPersonaWithPassCodeRequest
  ): Promise<AuthenticationResult> {
    return BitkeyPlatformAPI.callApi<AuthenticationResult>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.post(
        `/personas/${personaId}/pass_code_switch`,
        BitkeyPlatformAPI.toRequest(requestBody)
      )
    );
  }

  /**
   * Update password
   */
  public static async updatePersonaPassword(
    personaId: string,
    requestBody: UpdatePersonaPasswordRequest
  ): Promise<void> {
    return BitkeyPlatformAPI.callApi<void>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.put(
        `/personas/${personaId}/password`,
        BitkeyPlatformAPI.toRequest(requestBody)
      )
    );
  }

  /**
   * ペルソナに設定された認証タイプを取得します。
   *
   * 認証タイプが複数の場合は、そのすべての認証タイプがペルソナの認証時に要求されます。
   * ペルソナがロールをもつ場合は、そのロールに設定された認証タイプも合わせて要求されます。
   */
  public static async findRequiredAuthnTypes(personaId: string): Promise<PersonaRequiredAuthnTypes> {
    return BitkeyPlatformAPI.callApi<PersonaRequiredAuthnTypes>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.get(`/personas/${personaId}/persona_required_authn_types`)
    );
  }

  /**
   * ペルソナに割り当てられた組織固有の属性を検索します。
   */
  public static async searchMemberPropertiesByPersona(personaId: string): Promise<MemberProperty[]> {
    return BitkeyPlatformAPI.callApi<MemberProperty[]>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.get(`/personas/${personaId}/properties`)
    );
  }

  /**
   * ペルソナに組織固有の属性を追加します。
   */
  public static async addPersonaMemberProperty(
    personaId: string,
    requestBody: AddPersonaMemberPropertyRequest
  ): Promise<MemberPropertyWithPersonaIds> {
    return BitkeyPlatformAPI.callApi<MemberPropertyWithPersonaIds>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.post(
        `/personas/${personaId}/properties`,
        BitkeyPlatformAPI.toRequest(requestBody)
      )
    );
  }

  /**
   * ペルソナに割り当てられた組織固有の属性を参照します。
   */
  public static async findPersonaMemberProperty(personaId: string, memberPropertyId: string): Promise<MemberProperty> {
    return BitkeyPlatformAPI.callApi<MemberProperty>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.get(`/personas/${personaId}/properties/${memberPropertyId}`)
    );
  }

  /**
   * ペルソナに割り当てられた組織固有の属性を削除します。
   */
  public static async deletePersonaMemberProperty(personaId: string, memberPropertyId: string): Promise<void> {
    return BitkeyPlatformAPI.callApi<void>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.delete(`/personas/${personaId}/properties/${memberPropertyId}`)
    );
  }

  /**
   * ペルソナの公開設定を更新します。
   */
  public static async updatePersonaPublic(
    personaId: string,
    requestBody: UpdatePersonaPublicRequest
  ): Promise<Persona> {
    return BitkeyPlatformAPI.callApi<Persona>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.put(
        `/personas/${personaId}/public`,
        BitkeyPlatformAPI.toRequest(requestBody)
      )
    );
  }

  /**
   * 利用する端末を追加します。
   *
   * nonce に対する署名を操作端末上で作成してパラメータとして指定することで、
   * 端末とその端末に保存された秘密鍵の公開鍵を登録します。
   */
  public static async registerPersonaPublicKey(
    personaId: string,
    requestBody: RegisterPersonaPublicKeyRequest
  ): Promise<RegisterPersonaPublicKeyResponse> {
    return BitkeyPlatformAPI.callApi<RegisterPersonaPublicKeyResponse>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.post(
        `/personas/${personaId}/public_keys`,
        BitkeyPlatformAPI.toRequest(requestBody)
      )
    );
  }

  /**
   * ペルソナを指定した個人または組織に対して公開します
   */
  public static async addPersonaPublishSetting(personaId: string, requestBody: Personality): Promise<void> {
    return BitkeyPlatformAPI.callApi<void>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.post(
        `/personas/${personaId}/publish_settings`,
        BitkeyPlatformAPI.toRequest(requestBody)
      )
    );
  }

  /**
   * ペルソナのロールに設定された認証タイプを取得します。
   *
   * 認証タイプが複数の場合は、そのすべての認証タイプがペルソナの認証時に要求されます。
   * ペルソナがロールをもたない場合は空のレスポンスが返ります。
   * 自身のペルソナであれば誰でも実行できます。
   */
  public static async findRequiredAuthnTypesByPersona(personaId: string): Promise<RoleRequiredAuthnTypes> {
    return BitkeyPlatformAPI.callApi<RoleRequiredAuthnTypes>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.get(`/personas/${personaId}/role_required_authn_types`)
    );
  }

  /**
   * 他のペルソナから切り替えます。
   *
   * 組織で有効になっている場合、パスワード無しでペルソナを切り替えることができます。
   * ペルソナが端末認証を利用する設定になっている場合は、事前に署名対象となる乱数を取得して、
   * 署名結果とともにパラメータに指定する必要があります。
   * 端末認証を利用する設定となっており、かつ登録端末以外からの操作の場合、
   * 別途送付されるパスコードを利用してペルソナのスイッチ(/pass_code_switch)を実行し、その後、端末の追加を実行することができます。
   * パスコードは一度トライして失敗した場合無効となるため、再度パスコードなしでリクエストを実行し、パスコードを送付する必要があります。
   */
  public static async switchPersona(
    personaId: string,
    requestBody: SwitchPersonaRequest
  ): Promise<AuthenticationResult> {
    return BitkeyPlatformAPI.callApi<AuthenticationResult>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.post(
        `/personas/${personaId}/switch`,
        BitkeyPlatformAPI.toRequest(requestBody)
      )
    );
  }

  /**
   * 端末認証の有効・無効を制御します。
   */
  public static async updatePersonaTerminalAuthConfig(
    personaId: string,
    requestBody: UpdatePersonaTerminalAuthConfigRequest
  ): Promise<void> {
    return BitkeyPlatformAPI.callApi<void>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.put(
        `/personas/${personaId}/terminal_auth_config`,
        BitkeyPlatformAPI.toRequest(requestBody)
      )
    );
  }

  /**
   * ペルソナを指定した個人または組織に対して非公開にします
   */
  public static async addPersonaUnpublishSetting(personaId: string, requestBody: Personality): Promise<void> {
    return BitkeyPlatformAPI.callApi<void>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.post(
        `/personas/${personaId}/unpublish_settings`,
        BitkeyPlatformAPI.toRequest(requestBody)
      )
    );
  }

  /**
   * リフレッシュトークンを無効化します。
   */
  public static async deactivateRefreshTokens(requestBody: DeactivateRefreshTokensRequest): Promise<void> {
    return BitkeyPlatformAPI.callApi<void>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.put(
        `/refresh_tokens/deactivate`,
        BitkeyPlatformAPI.toRequest(requestBody)
      )
    );
  }

  /**
   * サービスアカウントの一覧を取得します。
   *
   * 操作者が所有するサービスアカウントの一覧を取得します。
   */
  public static async listServiceAccountsByPersonalOrOrg(): Promise<ServiceAccount[]> {
    return BitkeyPlatformAPI.callApi<ServiceAccount[]>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.get(`/service_accounts`)
    );
  }

  /**
   * サービスアカウントを登録します。
   *
   * 登録するとアクセストークンとリフレッシュトークンが発行されます。
   */
  public static async addServiceAccountByPersonalOrOrg(
    requestBody: AddServiceAccountByPersonalOrOrgRequest
  ): Promise<AddServiceAccountByPersonalOrOrgResponse> {
    return BitkeyPlatformAPI.callApi<AddServiceAccountByPersonalOrOrgResponse>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.post(`/service_accounts`, BitkeyPlatformAPI.toRequest(requestBody))
    );
  }

  /**
   * 発行した予約コードを利用して、サービスアカウントの短い有効期間のトークンを発行します。
   *
   * 発行した予約コードを利用して、サービスアカウントの短い有効期間のトークンを発行します。
   * リフレッシュトークンは発行されません。
   */
  public static async issueServiceAccountShortPeriodToken(
    requestBody: IssueServiceAccountShortPeriodTokenRequest
  ): Promise<ServiceAccountShortPeriodToken> {
    return BitkeyPlatformAPI.toResponse<ServiceAccountShortPeriodToken>(
      BitkeyPlatformAPI.instance.axiosWithoutToken.post(
        `/service_accounts/short_period_tokens`,
        BitkeyPlatformAPI.toRequest(requestBody)
      )
    );
  }

  /**
   * サービスアカウントを取得します。
   *
   * 操作者が所有するサービスアカウントのみ取得可能です。
   */
  public static async findServiceAccountByPersonalOrOrg(serviceAccountId: string): Promise<ServiceAccount> {
    return BitkeyPlatformAPI.callApi<ServiceAccount>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.get(`/service_accounts/${serviceAccountId}`)
    );
  }

  /**
   * サービスアカウントの名称、および、ロールを更新します。
   *
   * 操作者が所有するサービスアカウントに対してのみ実行可能です。
   */
  public static async updateServiceAccountByPersonalOrOrg(
    serviceAccountId: string,
    requestBody: UpdateServiceAccountByPersonalOrOrgRequest
  ): Promise<ServiceAccount> {
    return BitkeyPlatformAPI.callApi<ServiceAccount>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.put(
        `/service_accounts/${serviceAccountId}`,
        BitkeyPlatformAPI.toRequest(requestBody)
      )
    );
  }

  /**
   * サービスアカウントを削除します。
   *
   * 操作者が所有するサービスアカウントに対してのみ実行可能です。
   */
  public static async deleteServiceAccountByPersonalOrOrg(serviceAccountId: string): Promise<void> {
    return BitkeyPlatformAPI.callApi<void>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.delete(`/service_accounts/${serviceAccountId}`)
    );
  }

  /**
   * サービスアカウント用のアクセストークンを再発行します。
   */
  public static async issueServiceAccountTokenByPersonalOrOrg(serviceAccountId: string): Promise<ServiceAccountToken> {
    return BitkeyPlatformAPI.callApi<ServiceAccountToken>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.post(`/service_accounts/${serviceAccountId}/access_tokens`)
    );
  }

  /**
   * リフレッシュトークンを利用してアクセストークンを発行し直します。
   */
  public static async refreshServiceAccountAccessTokenByPersonalOrOrg(
    serviceAccountId: string,
    requestBody: RefreshServiceAccountAccessTokenByPersonalOrOrgRequest
  ): Promise<ServiceAccountToken> {
    return BitkeyPlatformAPI.toResponse<ServiceAccountToken>(
      BitkeyPlatformAPI.instance.axiosWithoutToken.post(
        `/service_accounts/${serviceAccountId}/access_tokens/refresh`,
        BitkeyPlatformAPI.toRequest(requestBody)
      )
    );
  }

  /**
   * HTTP ヘッダーにセットされたサービスアカウント用のアクセストークンを検証します。
   */
  public static async verifyServiceAccountAccessTokenByPersonalOrOrg(
    serviceAccountId: string
  ): Promise<VerifyServiceAccountAccessTokenByPersonalOrOrgResponse> {
    return BitkeyPlatformAPI.callApi<VerifyServiceAccountAccessTokenByPersonalOrOrgResponse>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.post(`/service_accounts/${serviceAccountId}/access_tokens/verify`)
    );
  }

  /**
   * サービスアカウントに対する特定のホールの権限を取得します。
   */
  public static async findServiceAccountHoleRights(
    serviceAccountId: string,
    holeId: string
  ): Promise<ServiceAccountHoleRight> {
    return BitkeyPlatformAPI.callApi<ServiceAccountHoleRight>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.get(`/service_accounts/${serviceAccountId}/holes/${holeId}/rights`)
    );
  }

  /**
   * サービスアカウントに対する特定のホールの権限を更新します。
   */
  public static async updateServiceAccountHoleRights(
    serviceAccountId: string,
    holeId: string,
    requestBody: NullableHolePower
  ): Promise<ServiceAccountHoleRight> {
    return BitkeyPlatformAPI.callApi<ServiceAccountHoleRight>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.put(
        `/service_accounts/${serviceAccountId}/holes/${holeId}/rights`,
        BitkeyPlatformAPI.toRequest(requestBody)
      )
    );
  }

  /**
   * サービスアカウントに対する特定のオブジェクトの権限を取得します。
   */
  public static async findServiceAccountObjectRights(
    serviceAccountId: string,
    objectId: string
  ): Promise<ServiceAccountObjectRight> {
    return BitkeyPlatformAPI.callApi<ServiceAccountObjectRight>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.get(`/service_accounts/${serviceAccountId}/objects/${objectId}/rights`)
    );
  }

  /**
   * サービスアカウントに対する特定のオブジェクトの権限を更新します。
   */
  public static async updateServiceAccountObjectRights(
    serviceAccountId: string,
    objectId: string,
    requestBody: NullableObjectPower
  ): Promise<ServiceAccountObjectRight> {
    return BitkeyPlatformAPI.callApi<ServiceAccountObjectRight>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.put(
        `/service_accounts/${serviceAccountId}/objects/${objectId}/rights`,
        BitkeyPlatformAPI.toRequest(requestBody)
      )
    );
  }

  /**
   * リフレッシュトークンを無効化します。
   */
  public static async deactivateServiceAccountRefreshTokensByPersonalOrOrg(
    serviceAccountId: string,
    requestBody: DeactivateServiceAccountRefreshTokensByPersonalOrOrgRequest
  ): Promise<void> {
    return BitkeyPlatformAPI.callApi<void>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.put(
        `/service_accounts/${serviceAccountId}/refresh_tokens/deactivate`,
        BitkeyPlatformAPI.toRequest(requestBody)
      )
    );
  }

  /**
   * サービスアカウントに対する権限を取得します。
   */
  public static async findServiceAccountRights(serviceAccountId: string): Promise<ServiceAccountRight> {
    return BitkeyPlatformAPI.callApi<ServiceAccountRight>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.get(`/service_accounts/${serviceAccountId}/rights`)
    );
  }

  /**
   * サービスアカウントに対する権限を更新します。
   */
  public static async updateServiceAccountRights(
    serviceAccountId: string,
    requestBody: NullablePower
  ): Promise<ServiceAccountRight> {
    return BitkeyPlatformAPI.callApi<ServiceAccountRight>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.put(
        `/service_accounts/${serviceAccountId}/rights`,
        BitkeyPlatformAPI.toRequest(requestBody)
      )
    );
  }

  /**
   * サービスアカウントの短い有効期間のトークンを発行するための予約コードを発行します。
   */
  public static async issueServiceAccountTokenReservationCode(
    serviceAccountId: string,
    requestBody: IssueServiceAccountTokenReservationCodeRequest
  ): Promise<ReservationCode> {
    return BitkeyPlatformAPI.callApi<ReservationCode>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.post(
        `/service_accounts/${serviceAccountId}/token_reservations`,
        BitkeyPlatformAPI.toRequest(requestBody)
      )
    );
  }

  /**
   * 自身に関わる Treasure Box の貸出/借出契約の一覧を取得します.
   */
  public static async listTreasureBoxLendContracts(
    queryParameters: ListTreasureBoxLendContractsQuery
  ): Promise<TreasureBoxLendContract[]> {
    return BitkeyPlatformAPI.callApi<TreasureBoxLendContract[]>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.get(`/treasure_box_lend_contracts`, {
        params: BitkeyPlatformAPI.toRequest(queryParameters),
      })
    );
  }

  /**
   * Treasure Box の貸出/借出契約を参照します。
   *
   * Treasure Box の貸出/借出契約を参照します。
   * 貸出/借出実行者もしくは、貸出/借出対象者が参照可能です。
   */
  public static async findTreasureBoxLendContract(treasureBoxLendContractId: string): Promise<TreasureBoxLendContract> {
    return BitkeyPlatformAPI.callApi<TreasureBoxLendContract>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.get(`/treasure_box_lend_contracts/${treasureBoxLendContractId}`)
    );
  }

  /**
   * Treasure Box の貸出/借出契約を削除します。
   *
   * Treasure Box の貸出/借出契約を削除します。
   * 貸出/借出実行者もしくは、貸出/借出対象者が削除可能です。
   */
  public static async deleteTreasureBoxLendContract(treasureBoxLendContractId: string): Promise<void> {
    return BitkeyPlatformAPI.callApi<void>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.delete(`/treasure_box_lend_contracts/${treasureBoxLendContractId}`)
    );
  }

  /**
   * 自身に関係する Treasure Box の移譲提案を一覧取得します
   */
  public static async listTreasureBoxTransfersByToOwnerID(
    queryParameters: ListTreasureBoxTransfersByToOwnerIDQuery
  ): Promise<TreasureBoxTransfer[]> {
    return BitkeyPlatformAPI.callApi<TreasureBoxTransfer[]>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.get(`/treasure_box_transfers`, {
        params: BitkeyPlatformAPI.toRequest(queryParameters),
      })
    );
  }

  /**
   * 自身に関係する Treasure Box の移譲提案を取得します
   */
  public static async findTreasureBoxTransfer(treasureBoxTransferId: string): Promise<TreasureBoxTransfer> {
    return BitkeyPlatformAPI.callApi<TreasureBoxTransfer>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.get(`/treasure_box_transfers/${treasureBoxTransferId}`)
    );
  }

  /**
   * 提案された Treasure Box の移譲提案を承認します
   */
  public static async acceptTreasureBoxTransfer(treasureBoxTransferId: string): Promise<TreasureBoxTransfer> {
    return BitkeyPlatformAPI.callApi<TreasureBoxTransfer>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.put(`/treasure_box_transfers/${treasureBoxTransferId}/accept`)
    );
  }

  /**
   * Treasure Box を移譲した際の詳細を取得します。
   *
   * Treasure Box の移譲に伴って、変更されたデータのIDを一覧で取得できます。
   * 操作者が組織ペルソナの場合は、組織内で treasure_box_set 権限を保持している必要があります。
   */
  public static async listTreasureBoxTransferDetails(
    treasureBoxTransferId: string,
    queryParameters: ListTreasureBoxTransferDetailsQuery
  ): Promise<TransferDetails> {
    return BitkeyPlatformAPI.callApi<TransferDetails>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.get(`/treasure_box_transfers/${treasureBoxTransferId}/details`, {
        params: BitkeyPlatformAPI.toRequest(queryParameters),
      })
    );
  }

  /**
   * 自身に関係する Treasure Box の移譲提案を却下します
   *
   * 自身に関係する Treasure Box の移譲提案を却下します
   * 提案側と受け入れ側どちらからでも、実行可能です
   */
  public static async rejectTreasureBoxTransfer(treasureBoxTransferId: string): Promise<TreasureBoxTransfer> {
    return BitkeyPlatformAPI.callApi<TreasureBoxTransfer>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.put(`/treasure_box_transfers/${treasureBoxTransferId}/reject`)
    );
  }

  /**
   * Treasure Box を一覧で取得します
   */
  public static async listTreasureBoxes(queryParameters: ListTreasureBoxesQuery): Promise<TreasureBox[]> {
    return BitkeyPlatformAPI.callApi<TreasureBox[]>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.get(`/treasure_boxes`, {
        params: BitkeyPlatformAPI.toRequest(queryParameters),
      })
    );
  }

  /**
   * Treasure Box を作成します。
   *
   * - Why & What
   *   - Treasure Box を作成し、デバイスにインストールするための `treasure_box_set_request` を取得します。
   *   - `treasure_box_set_request` には、以下２つの値が含まれます。インストールするデバイス側で、公開鍵の有効性を確認するために、署名の検証を実施してください。
   *     - `serialized_public_key`
   *       -  Treasure Box 作成時に生成されたキーペアの公開鍵
   *     - `signature`
   *       - `serialized_public_key` を上記キーペアの秘密鍵で作成した署名
   *   - `signature` と `serialized_public_key` は経路上の攻撃者によって差し替えられ、MITM 攻撃されることが考えられますが、影響範囲は Treasure Box 1台であること、このタイミング以外では差し替えられないことから、リスクが低いと判断しています。
   *   - 検証に成功した場合、公開鍵をデバイス側に永続的に保持してください。その後、そのデバイス宛の署名の検証に利用します。
   *   - Treasure Box はデータを作成するだけでは機能せず、デバイスにインストールした上で、アクティベートすることで初めて Treasure Data 取得時に指定できるようになります。
   * - When
   *     - デバイスに Treasure Box をインストールする時など
   * - Who
   *     - 実行者が組織ペルソナの場合、所属組織において、`treasure_box_set` 権限を持つこと
   *     - オーナーを組織グループにする場合、実行者の所属組織が対象の組織グループに所属していること
   */
  public static async addTreasureBox(requestBody: AddTreasureBoxRequest): Promise<AddTreasureBoxResponse> {
    return BitkeyPlatformAPI.callApi<AddTreasureBoxResponse>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.post(`/treasure_boxes`, BitkeyPlatformAPI.toRequest(requestBody))
    );
  }

  /**
   * Treasure Box を参照します。
   */
  public static async findTreasureBox(treasureBoxId: string): Promise<TreasureBox> {
    return BitkeyPlatformAPI.callApi<TreasureBox>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.get(`/treasure_boxes/${treasureBoxId}`)
    );
  }

  /**
   * Treasure Box を削除します。
   *
   * - Why & What
   *   - Treasure Box を削除し、 Treasure Data の作成時に指定できないようにします。
   *     - 実行後にレスポンスとして取得できる `treasure_box_delete_request` を検証することで Treasure Box はアンインストール命令が正当なものであることを確認できます。
   *     - Treasure Box 作成時に生成された秘密鍵を使って、アクティベート時に保存されたデバイスの UDID に ECDSA で署名した値が `treasure_box_delete_request` に含まれます。
   *     - アンインストールしたいデバイス側で、`treasure_box_set_request` に含まれていた公開鍵を使って、 `treasure_box_delete_request` がデバイス自身の UDID に署名されていることを検証してください。
   *     - `treasure_box_delete_request` は、`GET /treasure_boxes` で `deleted=true` を指定することでも取得するこができます。
   * - When
   *   - デバイスをアンインストールする時など
   * - Who
   *   - Treasure Box のオーナー
   *     - 組織グループで作成した場合、オーナーとは、組織グループに所属している組織を指す
   *   - 実行者が組織ペルソナの場合、所属組織において、`treasure_box_delete` を持つこと
   */
  public static async deleteTreasureBox(treasureBoxId: string): Promise<DeleteTreasureBoxResponse> {
    return BitkeyPlatformAPI.callApi<DeleteTreasureBoxResponse>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.delete(`/treasure_boxes/${treasureBoxId}`)
    );
  }

  /**
   * Treasure Box をアクティベートします。
   *
   * - Why & What
   *   - Treasure Box をアクティベートし、 Treasure Data を配信できる状態にします。
   *   - デバイス側は鍵ペアを作成し、その公開鍵と公開鍵への署名を `treasure_box_set_response` としてリクエストに含めてください。
   *   - BKPは入力の `treasure_box_set_response` に含まれる、`signature` と `seriarized_device_public_key` を使って、`treasure_box_set_response` が正しくデバイスで署名されていることを検証します。
   *   - 検証が成功すると、デバイスの UDID を保存してアクティベートを完了します。
   * - When
   *   - Treasure Box を作成し、対象のデバイスに `treasure_box_set_request` を送って成功した後。
   * - Who
   *   - Treasure Box のオーナー
   *     - 組織グループで作成した場合、オーナーとは、組織グループに所属している組織を指します。
   *   - 実行者が組織ペルソナの場合、所属組織において、 `treasure_box_set` を持つこと。
   * - How
   *   - リクエストには、デバイスに `treasure_box_set_request` を送った結果、デバイスから得られる `treasure_box_set_response` をセットしてください。
   */
  public static async activateTreasureBox(
    treasureBoxId: string,
    requestBody: ActivateTreasureBoxRequest
  ): Promise<TreasureBox> {
    return BitkeyPlatformAPI.callApi<TreasureBox>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.put(
        `/treasure_boxes/${treasureBoxId}/activate`,
        BitkeyPlatformAPI.toRequest(requestBody)
      )
    );
  }

  /**
   * Treasure Box に登録されているオブジェクトの一覧を取得します。
   */
  public static async listBoundObject(treasureBoxId: string): Promise<BitkeyObject[]> {
    return BitkeyPlatformAPI.callApi<BitkeyObject[]>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.get(`/treasure_boxes/${treasureBoxId}/objects`)
    );
  }

  /**
   * Treasure Box にオブジェクトを登録します。
   */
  public static async bindObjectToTreasureBox(treasureBoxId: string, objectId: string): Promise<void> {
    return BitkeyPlatformAPI.callApi<void>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.put(`/treasure_boxes/${treasureBoxId}/objects/${objectId}`)
    );
  }

  /**
   * Treasure Box からオブジェクトの登録を解除します。
   */
  public static async unbindObjectToTreasureBox(treasureBoxId: string, objectId: string): Promise<void> {
    return BitkeyPlatformAPI.callApi<void>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.delete(`/treasure_boxes/${treasureBoxId}/objects/${objectId}`)
    );
  }

  /**
   * 所有している Treasure Box の移譲提案を登録します
   *
   * Treasure Box の移譲提案を登録すると、該当の Treasure Box が一時的に凍結されます。
   * 凍結されるとそれらを指定した以下取引の登録ができなくなります。
   *   * treasure deal
   *   * treasure deal offer
   *   * treasure deal preset
   * 提案の受け入れ・却下、もしくは提案が取り消されることで凍結は解除されます。
   *
   * 提案が受け入れられた際、該当 Treasure Box が移譲されます。
   * また、それに紐づく取引や権限情報は削除・無効化されます。
   *   e.g. One-time key の申請・譲渡提案
   *
   * 操作者が組織ペルソナの場合は、組織内で treasure_box_delete 権限を保持している必要があります。
   */
  public static async addTreasureBoxTransfer(
    treasureBoxId: string,
    requestBody: AddTreasureBoxTransferRequest
  ): Promise<TreasureBoxTransfer> {
    return BitkeyPlatformAPI.callApi<TreasureBoxTransfer>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.post(
        `/treasure_boxes/${treasureBoxId}/transfers`,
        BitkeyPlatformAPI.toRequest(requestBody)
      )
    );
  }

  /**
   * 所有している Treasure Box の移譲提案を参照します
   *
   * 操作者が組織ペルソナの場合は、組織内で treasure_box_set 権限を保持している必要があります。
   */
  public static async findTreasureBoxTransferWithTreasureBoxID(
    treasureBoxId: string,
    treasureBoxTransferId: string
  ): Promise<TreasureBoxTransfer> {
    return BitkeyPlatformAPI.callApi<TreasureBoxTransfer>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.get(
        `/treasure_boxes/${treasureBoxId}/transfers/${treasureBoxTransferId}`
      )
    );
  }

  /**
   * 所有している Treasure Box の移譲提案を取り消します
   *
   * 取り消しをすると、合わせて該当の Treasure Box やそれに紐づく以下のリソースに対する更新操作の凍結が解除されます。
   *   * treasure_deal
   *   * treasure_deal_offer
   *   * treasure_data_preset
   * 操作者が組織ペルソナの場合は、組織内で treasure_box_set 権限を保持している必要があります。
   */
  public static async deleteTreasureBoxTransfer(treasureBoxId: string, treasureBoxTransferId: string): Promise<void> {
    return BitkeyPlatformAPI.callApi<void>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.delete(
        `/treasure_boxes/${treasureBoxId}/transfers/${treasureBoxTransferId}`
      )
    );
  }

  /**
   * 特定の Treasure Box に対する Treasure Data を取得します。
   *
   * - Why & What
   *   - Treasure Box に対して配信可能な Treasure Data を取得します。
   *   - Offset と Limit を使用して大量データの取得が可能です。
   *   - `datasource_type` を指定し、特定のデータソースタイプのみの Treasure Data を取得します。
   *   - 複数のデータソースタイプの Treasure Data を取得したい場合には、データソースタイプの種類分リクエストしてください。
   * - When
   *   - Treasure Box に対して Treasure Data Preset や Treasure Deal, Treasure Deal Offer を登録した後
   * - Who
   *   - 対象の Treasure Box が
   *     - 個人所有の場合
   *       - 実行者がその所有者であること
   *     - 組織所有の場合
   *       - 実行者がその組織に所属していること
   *     - 組織グループ所有の場合
   *       - 実行者の所属組織がその組織グループに所属していること
   *   - もしくは、上記条件を満たす実行者が作成した Treasure Broker の `broker_key` をアクセストークンに指定していること
   * - How
   *   - データソースの指定
   *     - `datasource_type` を指定し、特定のデータソースのみ Treasure Data を取得します。
   *   - 有効期限の指定
   *     - `usage_period` を指定して作成された Treasure Deal, Treasure Deal Offer は、`start_at_epoch` 、 `end_at_epoch` の条件を満たしたもののみを対象として、 Treasure Data が取得できます。
   *     - `start_at_epoch` が未指定、もしくは実行時間以前が指定された場合、実行時間がデフォルト値として設定されます。
   *     - `end_at_epoch` が未指定の場合、 `start_at_epoch` の1日後がデフォルト値として設定されます。
   */
  public static async listTreasureDataInSpecificBox(
    treasureBoxId: string,
    queryParameters: ListTreasureDataInSpecificBoxQuery
  ): Promise<TreasureDataSetWithPropertySize> {
    return BitkeyPlatformAPI.callApi<TreasureDataSetWithPropertySize>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.get(`/treasure_boxes/${treasureBoxId}/treasure_data`, {
        params: BitkeyPlatformAPI.toRequest(queryParameters),
      })
    );
  }

  /**
   * Treasure Data の 最終更新日時を取得します。
   *
   * 自身の保持する Treasure Data の最終更新日時と、このAPIのレスポンスを比較し、レスポンスの方が新しければ Treasure Data に更新があることがわかります。
   * レスポンスの方が古い場合は Treasure Data の更新不要です。
   * Treasure Data を更新する場合は、 `GET /treasure_boxes/{treasure_box_id}/treasure_data` を利用して自身のデータを全て洗い替えてください。
   */
  public static async getLastUpdatedAt(treasureBoxId: string): Promise<GetLastUpdatedAtResponse> {
    return BitkeyPlatformAPI.callApi<GetLastUpdatedAtResponse>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.get(`/treasure_boxes/${treasureBoxId}/treasure_data/last_updated_at`)
    );
  }

  /**
   * Treasure Broker の一覧を取得します。
   *
   * 操作実行者が登録した Treasure Broker の一覧を取得します。
   * `organization_group_id` を指定した場合、指定した組織グループが所有している Treasure Broker のみ取得します。
   * `organization_group_id` を指定しない場合、組織グループ所有になっている Treasure Broker は取得しません。
   */
  public static async listTreasureBrokers(queryParameters: ListTreasureBrokersQuery): Promise<TreasureBroker[]> {
    return BitkeyPlatformAPI.callApi<TreasureBroker[]>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.get(`/treasure_brokers`, {
        params: BitkeyPlatformAPI.toRequest(queryParameters),
      })
    );
  }

  /**
   * Treasure Data を配信する Treasure Broker を登録します。
   *
   * 登録するとアクセストークンとリフレッシュトークンが発行されます。
   */
  public static async addTreasureBroker(requestBody: AddTreasureBrokerRequest): Promise<AddTreasureBrokerResponse> {
    return BitkeyPlatformAPI.callApi<AddTreasureBrokerResponse>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.post(`/treasure_brokers`, BitkeyPlatformAPI.toRequest(requestBody))
    );
  }

  /**
   * Treasure Broker を取得します。
   *
   * 操作実行者が登録した Treasure Broker のみ取得可能です。
   */
  public static async findTreasureBroker(treasureBrokerId: string): Promise<TreasureBroker> {
    return BitkeyPlatformAPI.callApi<TreasureBroker>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.get(`/treasure_brokers/${treasureBrokerId}`)
    );
  }

  /**
   * Treasure Broker を削除します。
   *
   * 操作実行者が登録した Treasure Broker に対してのみ実行可能です。
   */
  public static async deleteTreasureBroker(treasureBrokerId: string): Promise<void> {
    return BitkeyPlatformAPI.callApi<void>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.delete(`/treasure_brokers/${treasureBrokerId}`)
    );
  }

  /**
   * Treasure Broker 用のアクセストークンを再発行します。
   */
  public static async issueBrokerToken(treasureBrokerId: string): Promise<TreasureBrokerToken> {
    return BitkeyPlatformAPI.callApi<TreasureBrokerToken>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.post(`/treasure_brokers/${treasureBrokerId}/access_tokens`)
    );
  }

  /**
   * リフレッシュトークンを利用してアクセストークンを発行し直します。
   */
  public static async refreshBrokerAccessToken(
    treasureBrokerId: string,
    requestBody: RefreshBrokerAccessTokenRequest
  ): Promise<TreasureBrokerToken> {
    return BitkeyPlatformAPI.toResponse<TreasureBrokerToken>(
      BitkeyPlatformAPI.instance.axiosWithoutToken.post(
        `/treasure_brokers/${treasureBrokerId}/access_tokens/refresh`,
        BitkeyPlatformAPI.toRequest(requestBody)
      )
    );
  }

  /**
   * HTTP ヘッダーにセットされた Treasure Broker 用のアクセストークンを検証します。
   */
  public static async verifyBrokerAccessToken(treasureBrokerId: string): Promise<VerifyBrokerAccessTokenResponse> {
    return BitkeyPlatformAPI.callApi<VerifyBrokerAccessTokenResponse>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.post(`/treasure_brokers/${treasureBrokerId}/access_tokens/verify`)
    );
  }

  /**
   * Treasure Broker の名称を更新します。
   *
   * 操作実行者が登録した Treasure Broker に対してのみ実行可能です。
   */
  public static async updateTreasureBrokerName(
    treasureBrokerId: string,
    requestBody: UpdateTreasureBrokerNameRequest
  ): Promise<TreasureBroker> {
    return BitkeyPlatformAPI.callApi<TreasureBroker>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.put(
        `/treasure_brokers/${treasureBrokerId}/name`,
        BitkeyPlatformAPI.toRequest(requestBody)
      )
    );
  }

  /**
   * リフレッシュトークンを無効化します。
   */
  public static async deactivateBrokerRefreshTokens(
    treasureBrokerId: string,
    requestBody: DeactivateBrokerRefreshTokensRequest
  ): Promise<void> {
    return BitkeyPlatformAPI.callApi<void>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.put(
        `/treasure_brokers/${treasureBrokerId}/refresh_tokens/deactivate`,
        BitkeyPlatformAPI.toRequest(requestBody)
      )
    );
  }

  /**
   * Treasure Broker のサスペンド状況を更新します。
   *
   * 操作実行者が登録した Treasure Broker に対してのみ実行可能です。
   */
  public static async updateTreasureBrokerSuspended(
    treasureBrokerId: string,
    requestBody: UpdateTreasureBrokerSuspendedRequest
  ): Promise<TreasureBroker> {
    return BitkeyPlatformAPI.callApi<TreasureBroker>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.put(
        `/treasure_brokers/${treasureBrokerId}/suspended`,
        BitkeyPlatformAPI.toRequest(requestBody)
      )
    );
  }

  /**
   * Treasure Data を取得します。
   *
   * - Why & What
   *   - Treasure Box に対して配信可能な Treasure Data を取得します。
   * - When
   *   - Treasure Box に対して Treasure Data Preset や Treasure Deal, Treasure Deal Offer を登録した後
   * - Who
   *   - 対象の Treasure Box が
   *       - 個人所有の場合
   *           - 実行者がその所有者であること
   *       - 組織所有の場合
   *           - 実行者がその組織に所属していること
   *       - 組織グループ所有の場合
   *           - 実行者の所属組織がその組織グループに所属していること
   *   - もしくは、上記条件を満たす実行者が作成した Treasure Broker の `broker_key` をアクセストークンに指定していること
   * - How
   *   - 配信対象の Treasure Box の ID をクエリパラメータ `box_ids[]` に対し、１つ以上セットしてください。
   *   - 取得データの絞り込み方法
   *       - データソースの指定
   *           - `datasource_type`, `datasource_id` を指定すると、このデータソースのみの Treasure Data が取得できます。
   *       - 有効期限の指定
   *           - `usage_period` を指定して作成された Treasure Deal, Treasure Deal Offer は、`start_at_epoch` 、 `end_at_epoch` の条件を満たしたもののみを対象として、 Treasure Data が取得できます。
   *           - `start_at_epoch` が未指定、もしくは実行時間以前が指定された場合、実行時間がデフォルト値として設定されます。
   *           - `end_at_epoch` が未指定の場合、 `start_at_epoch` の1日後がデフォルト値として設定されます。
   */
  public static async listTreasureData(queryParameters: ListTreasureDataQuery): Promise<TreasureDataSet[]> {
    return BitkeyPlatformAPI.callApi<TreasureDataSet[]>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.get(`/treasure_data`, {
        params: BitkeyPlatformAPI.toRequest(queryParameters),
      })
    );
  }

  /**
   * 設定された Treasure Data Preset を 一覧で取得します。
   */
  public static async listTreasureDataPresets(
    queryParameters: ListTreasureDataPresetsQuery
  ): Promise<TreasureDataPreset[]> {
    return BitkeyPlatformAPI.callApi<TreasureDataPreset[]>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.get(`/treasure_data_presets`, {
        params: BitkeyPlatformAPI.toRequest(queryParameters),
      })
    );
  }

  /**
   * Treasure Data Preset を 作成します。
   *
   * - Why & What
   *   - Treasure Deal や Treasure Deal Offer と違い、解錠条件を設けずに利用したい Member Property / Arbitrary Property を登録できます。
   *   - 条件によらず解錠が可能になる Member Property / Arbitrary Property を設定し、それに基づく Treasure Data を取得できるようにします。
   * - When
   *   - `PUT /treasure_boxes/{treasure_box_id}/objects/{object_id}` によって Object と Treasure Box の紐付けが完了した後に利用できます。
   *   - 解錠条件を設けずに利用したい Member Property / Arbitrary Property を元に Treasure Data を取得したい場合に事前に Treasure Data Preset を作成する必要があります。
   * - Who
   *   - 対象の Hole のオーナーであること
   *   - 対象の Treasure Box のオーナーであること
   *   - 所属組織、組織グループにおいて、 `treasure_data_preset_control` 権限があること
   *   - 組織グループに利用許可された別組織の Member Property (= Shared Member Property) を指定する場合、`shared_member_property_find` 権限があること
   * - How
   *   - `personality_properties` は Treasure Box の所有者と実行者によって以下のような条件があります。
   *     - 実行者が個人ペルソナの場合、`arbitrary_property_id` のみ指定可能でかつ指定しなければなりません。
   *     - 実行者が組織ペルソナの場合、`member_property_id` または `member_property_label_id` のどちらかを指定しなければなりません。
   *     - 対象の Treasure Box の所有者が組織グループの場合には、 `member_property_id` のみ指定可能です。
   *   - 条件によらず解錠が可能になる Member Property / Arbitrary Property を設定し、それに基づく Treasure Data を取得できるようにします。
   *   - 繰り返し条件や、使用可能時間、使用回数などを細かく指定することはできません。
   */
  public static async addTreasureDataPreset(requestBody: AddTreasureDataPresetRequest): Promise<TreasureDataPreset> {
    return BitkeyPlatformAPI.callApi<TreasureDataPreset>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.post(`/treasure_data_presets`, BitkeyPlatformAPI.toRequest(requestBody))
    );
  }

  /**
   * TreasureDataPreset を参照します。
   */
  public static async findTreasureDataPreset(treasureDataPresetId: string): Promise<TreasureDataPreset> {
    return BitkeyPlatformAPI.callApi<TreasureDataPreset>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.get(`/treasure_data_presets/${treasureDataPresetId}`)
    );
  }

  /**
   * TreasureDataPreset を更新します。
   *
   * - Why & What
   *   - Treasure Data Preset の Member Property / Arbitrary Property を更新します。
   * - When
   *   - `POST /treasure_data_presets` で作成した後、 条件によらず解錠を可能にする Member Property / Arbitrary Property が増減した場合に利用します。
   * - Who
   *   - 所属組織、組織グループにおいて、 `treasure_data_preset_control` 権限があること
   *   - Treasure Box のオーナーであること
   *   - Shared Member Property を指定する場合、`shared_member_property_find` 権限があること
   */
  public static async updateTreasureDataPreset(
    treasureDataPresetId: string,
    requestBody: UpdateTreasureDataPresetRequest
  ): Promise<TreasureDataPreset> {
    return BitkeyPlatformAPI.callApi<TreasureDataPreset>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.put(
        `/treasure_data_presets/${treasureDataPresetId}`,
        BitkeyPlatformAPI.toRequest(requestBody)
      )
    );
  }

  /**
   * TreasureDataPreset を削除します。
   *
   * - Why & What
   *   - Treasure Data Preset を削除し、削除対象の Treasure Data をデバイスから削除するための `delete_treasure_data_request` を作成します。
   *   - `delete_treasure_data_request` は、 Treasure Data ID と Treasure Box の秘密鍵による署名で構成されています。
   * - When
   *   - `POST /treasure_data_presets` で作成した Treasure Data Preset を削除する時に利用します。
   * - Who
   *   - 所属組織、組織グループにおいて、 `treasure_data_preset_control` 権限があること
   *   - Treasure Box のオーナーであること
   */
  public static async deleteTreasureDataPreset(
    treasureDataPresetId: string
  ): Promise<DeleteTreasureDataPresetResponse> {
    return BitkeyPlatformAPI.callApi<DeleteTreasureDataPresetResponse>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.delete(`/treasure_data_presets/${treasureDataPresetId}`)
    );
  }

  /**
   * Treasure Data Preset を 作成します。
   *
   * - Why & What
   *   - Treasure Deal や Treasure Deal Offer と違い、解錠条件を設けずに利用したい Member Property / Arbitrary Property を登録できます。
   *   - 条件によらず解錠が可能になる Member Property / Arbitrary Property を設定し、それに基づく Treasure Data を取得できるようにします。
   * - When
   *   - `PUT /treasure_boxes/{treasure_box_id}/objects/{object_id}` によって Object と Treasure Box の紐付けが完了した後に利用できます。
   *   - 解錠条件を設けずに利用したい Member Property / Arbitrary Property を元に Treasure Data を取得したい場合に事前に Treasure Data Preset を作成する必要があります。
   * - Who
   *   - 対象の Hole のオーナーであること
   *   - 対象の Treasure Box のオーナーであること
   *   - 所属組織、組織グループにおいて、 `treasure_data_preset_control` 権限があること
   *   - 組織グループに利用許可された別組織の Member Property (= Shared Member Property) を指定する場合、`shared_member_property_find` 権限があること
   *   - Treasure Box Lend Contracts によって作成すべき `hole_id` が予め指定されていること。
   * - How
   *   - `personality_properties` は Treasure Box の所有者と実行者によって以下のような条件があります。
   *     - 実行者が個人ペルソナの場合、`arbitrary_property_id` のみ指定可能でかつ指定しなければなりません。
   *     - 実行者が組織ペルソナの場合、`member_property_id` または `member_property_label_id` のどちらかを指定しなければなりません。
   *     - 対象の Treasure Box の所有者が組織グループの場合には、 `member_property_id` のみ指定可能です。
   *   - 条件によらず解錠が可能になる Member Property / Arbitrary Property を設定し、それに基づく Treasure Data を取得できるようにします。
   *   - 繰り返し条件や、使用可能時間、使用回数などを細かく指定することはできません。
   */
  public static async addTreasureDataPresetsWithoutHole(
    requestBody: AddTreasureDataPresetsWithoutHoleRequest
  ): Promise<TreasureDataPreset[]> {
    return BitkeyPlatformAPI.callApi<TreasureDataPreset[]>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.post(
        `/treasure_data_presets_without_hole`,
        BitkeyPlatformAPI.toRequest(requestBody)
      )
    );
  }

  /**
   * 自分が提案した TreasureDealOffer の一覧を取得します。
   *
   * TreasureDealOffer の一覧を取得します。
   * 削除済み、もしくは、 `end_at_epoch` を過ぎた提案は表示されません。
   *
   * ペルソナが組織に所属している場合は、組織として提案した一覧が取得できます。
   * それ以外は、個人として提案した一覧が取得できます。
   */
  public static async listTreasureDealOffers(
    queryParameters: ListTreasureDealOffersQuery
  ): Promise<TreasureDealOffer[]> {
    return BitkeyPlatformAPI.callApi<TreasureDealOffer[]>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.get(`/treasure_deal_offers`, {
        params: BitkeyPlatformAPI.toRequest(queryParameters),
      })
    );
  }

  /**
   * TreasureBox に 配信する TreasureData のもととなる提案を作成します。
   *
   * - Why & What
   *   - Treasure Box の所有者とは異なる組織・個人が所有する Member Property / Arbitrary Property を設定した Treasure Data データソースを作成したい時に利用します。
   *   - Member Property / Arbitrary Property の所有者（組織、個人）に対して、解錠条件付きの Treasure Data データソースの作成を提案します。
   *   - `POST /treasure_deal_offers/{treasure_deal_offer_id}/accept` によって提案が受け入れられることによって、Treasure Data 作成時のデータソースとして扱われるようになります。
   * - When
   *   - `PUT /treasure_boxes/{treasure_box_id}/objects/{object_id}` によって Object と Treasure Box の紐付けが完了した後
   * - Who
   *   - 対象の Hole が
   *       - 個人所有の場合
   *           - その Hole のオーナーであること
   *       - 組織所有の場合
   *           - 実行者の所属組織がその Hole のオーナーであること
   *           - かつ、実行者がその組織において、対象の Hole に対する `treasure_deal_control` 権限があること
   *       - 組織グループ所有の場合
   *           - 実行者の所属組織がその組織グループに属していること
   *           - かつ、実行者がその組織グループにおいて、対象の Hole に対する `treasure_deal_control` 権限があること
   *   - 対象の Treasure Box が
   *       - 個人所有の場合
   *           - その Treasure Box のオーナーであること
   *       - 組織所有の場合
   *           - 実行者の所属組織がその Treasure Box のオーナーであること
   *           - かつ、実行者がその組織において `treausre_box_find` 権限があること
   *       - 組織グループ所有の場合
   *           - 実行者の所属組織がその組織グループに属していること
   *           - かつ、実行者がその組織グループにおいて `treausre_box_find` 権限があること
   * - How
   *   - 提案相手の指定方法
   *     - 直接相手の Personality を指定するか、Pass Code を生成させることで提案先を制限することができます。
   *     - 提案先 Personality と Pass Code 生成可否の組み合わせごとの受け入れ制限は以下のようになります。
   *       - 提案先 Personality: 無指定 かつ Pass Code: 生成しない 場合、受け入れ制限がない提案となる
   *       - 提案先 Personality: 指定 かつ Pass Code: 生成しない 場合、提案先の Personality のみ受け入れ可能な提案となる
   *       - 提案先 Personality: 無指定 かつ Pass Code: 生成する 場合、Pass Code を提示することで受け入れ可能な提案となる
   *       - 提案先 Personality: 指定 かつ Pass Code: 生成する 場合、提案先の Personality に一致、
   *         もしくは Pass Code を提示することで受け入れ可能な提案となる
   *   - 解錠条件の指定方法
   *       - 繰り返し条件や、使用可能時間、使用回数などを細かく指定することができます。
   *   - 提案の受け入れ期限を設定したい場合は、`acceptance_time_limit_epoch` を指定してください。
   */
  public static async addTreasureDealOffer(
    requestBody: AddTreasureDealOfferRequest
  ): Promise<AddTreasureDealOfferResponse> {
    return BitkeyPlatformAPI.callApi<AddTreasureDealOfferResponse>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.post(`/treasure_deal_offers`, BitkeyPlatformAPI.toRequest(requestBody))
    );
  }

  /**
   * 提案された TreasureDealOffer を取得します。
   */
  public static async findTreasureDealOffer(treasureDealOfferId: string): Promise<TreasureDealOffer> {
    return BitkeyPlatformAPI.callApi<TreasureDealOffer>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.get(`/treasure_deal_offers/${treasureDealOfferId}`)
    );
  }

  /**
   * 提案された TreasureDealOffer の内容を変更します。
   *
   * - Why & What
   *   - 対象の Treasure Deal Offer の受け入れ期限を変更することができます。
   * - When
   *   - `POST /treasure_deal_offers` によって Treasure Deal Offer が作成された後
   * - Who
   *   - 対象の Treasure Deal Offer が組織所有の場合
   *       - 実行者が同じ組織に所属していること
   *   - 対象の Treasure Deal Offer が個人所有の場合
   *       - 実行者が所有者と同じであること
   */
  public static async updateTreasureDealOffer(
    treasureDealOfferId: string,
    requestBody: UpdateTreasureDealOfferRequest
  ): Promise<TreasureDealOffer> {
    return BitkeyPlatformAPI.callApi<TreasureDealOffer>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.put(
        `/treasure_deal_offers/${treasureDealOfferId}`,
        BitkeyPlatformAPI.toRequest(requestBody)
      )
    );
  }

  /**
   * 提案された TreasureDealOffer を削除します。
   *
   * - Why & What
   *   - Treasure Deal Offer の提案内容を取り下げ、対象の Treasure Deal Offer を指定して配信された Treasure Data を配信先のデバイスからアンインストールするための `delete_treasure_data_request` を取得する際に利用します。
   *   - 対象の Treasure Deal Offer が受け入れ前の場合、Treasure Deal Offer が削除され、以後受け入れも拒否もできない状態になります。
   *   - 受け入れ済の Treasure Deal Offer の場合、該当の Treasure Deal Offer をデータソースとして配信された Treasure Data を配信先のアンインストールするための `delete_treasure_data_request` を取得できます。
   *       - 対象の Treasure Deal Offer が削除済であっても、再度実行することで `delete_treasure_data_request` を取得することが可能です。
   * - When
   *   - `POST /treasure_deal_offers` によって Treasure Deal Offer が作成された後
   * - Who
   *   - 対象の Treasure Deal Offer が組織所有の場合
   *       - 実行者が同じ組織に所属していること
   *       - かつ、実行者が以下条件を満たすこと
   *           -  `broker_key` をアクセストークンとして指定していること
   *           -  もしくは、通常のアクセストークンを指定しているペルソナで、所属組織において `treasure_deal_control` 権限があること
   *   - 対象の Treasure Deal Offer が個人所有の場合
   *       - 実行者が所有者と同じであること
   */
  public static async deleteTreasureDealOffer(treasureDealOfferId: string): Promise<DeleteTreasureDealOfferResponse> {
    return BitkeyPlatformAPI.callApi<DeleteTreasureDealOfferResponse>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.delete(`/treasure_deal_offers/${treasureDealOfferId}`)
    );
  }

  /**
   * 提案された TreasureDealOffer を受け入れます。
   *
   * - Why & What
   *   - 実行者が所有している Member Property / Arbitrary Property を指定して、Treasure Deal Offer の提案内容を受け入れたい時に利用します。
   *       - 受け入れ済みの Treasure Deal Offer に対して、 Member Property / Arbitrary Property を追加したい場合は再度本 API を実行してください。
   *       - ただし、Treasure Deal Offer 作成時に、`accept_only_one = true` が指定されていた場合、1つ以上の Member Property / Arbitrary Property を指定すると失敗します。
   *   - Treasure Deal Offer 作成時に指定された解錠条件、かつ受け入れ時に指定された Member Property / Arbitrary Property が設定された Treasure Data が取得できるようになります。
   * - When
   *   - `POST /treasure_deal_offers` によって Treasure Deal Offer が作成された後
   *   - `POST /treasure_deal_offers/{treasure_deal_offer_id}/reject` によって Treasure Deal Offer が拒否されてしまうと、実行できなくなります。
   *   - また、Treasure Deal Offer 作成時に受け入れ期限が設定されている場合、受け入れ期限後に実行すると失敗します。
   * - Who
   *   - 対象の Treasure Deal Offer の提案先として指定されている組織・個人であること
   *   - もしくは対象の Treasure Deal Offer 生成時の Pass Code を知っていること
   *   - 実行者が組織に所属している場合、所属組織において、以下権限があること
   *     - 個人として受け入れる場合（is_organization = false にした場合）は `deal_offer_accept_as_persona` 権限があること
   *     - 組織として受け入れる場合（is_organization = true にした場合）は `deal_offer_accept_as_organization` 権限があること
   * - How
   *   - Member Property / Arbitrary Property の指定方法
   *     - 実行者が組織に所属している場合
   *       - 組織として受け入れる場合（ `is_organization = true` にした場合）
   *         `member_property_id` もしくは `member_property_label_id` による指定ができます。
   *         `member_property_label_id` のみを指定した場合、対象の組織が保持する Member Property のうち、指定された `member_property_label_id` に一致するものが、Treasure Data の作成対象となります。
   *         どちらも指定した場合は、 `member_property_id` で特定される Member Property の `member_property_label_id` と、指定された `member_property_label_id` の一致チェックをおこないます。
   *
   *       - 個人として受け入れる場合（ `is_organization = false` にした場合）
   *         組織として受け入れる場合と同様の指定が可能です。
   *         ただし、実行者に付与された Member Property のみが対象となります。
   *
   *     - 実行者が組織に所属していない場合
   *       - `arbitrary_property_id` での指定のみが可能です。
   */
  public static async acceptTreasureDealOffer(
    treasureDealOfferId: string,
    requestBody: AcceptTreasureDealOfferRequest
  ): Promise<void> {
    return BitkeyPlatformAPI.callApi<void>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.post(
        `/treasure_deal_offers/${treasureDealOfferId}/accept`,
        BitkeyPlatformAPI.toRequest(requestBody)
      )
    );
  }

  /**
   * 提案された TreasureDealOffer を拒否します。
   *
   * - Why & What
   *   - Treasure Deal Offer の提案内容を拒否したい時に利用します。
   *   - 対象の Treasure Deal Offer が受け入れ前の場合、Treasure Deal Offer が却下され、以後受け入れができない状態になります。
   *   - 受け入れ済の Treasure Deal Offer については、拒否が成功しても、引き続き Treasure Data のデータソースとして指定可能です。
   * - When
   *   - `POST /treasure_deal_offers` によって Treasure Deal Offer が作成された後
   *   - また、Treasure Deal Offer 作成時に受け入れ期限が設定されている場合、受け入れ期限後に実行すると失敗します。
   * - Who
   *   - 対象の Treasure Deal Offer の提案先として指定されている組織・個人であること
   */
  public static async rejectTreasureDealOffer(treasureDealOfferId: string): Promise<void> {
    return BitkeyPlatformAPI.callApi<void>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.post(`/treasure_deal_offers/${treasureDealOfferId}/reject`)
    );
  }

  /**
   * 条件に一致する Treasure Deal の情報を参照します。
   */
  public static async listTreasureDeals(queryParameters: ListTreasureDealsQuery): Promise<TreasureDeal[]> {
    return BitkeyPlatformAPI.callApi<TreasureDeal[]>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.get(`/treasure_deals`, {
        params: BitkeyPlatformAPI.toRequest(queryParameters),
      })
    );
  }

  /**
   * Treasure Deal を登録します。
   *
   * - Why & What
   *   - Member Property （組織） / Arbitrary Property （個人） の所有者とは異なる組織・個人が所有する Treasure Box のための Treasure Data データソースを作成したい場合に実行します。
   *   - Treasure Box の所有者に対して、解錠条件付きの Treasure Data データソースの作成を申請します。
   *   - `PUT /treasure_deals/{treasure_deal_id}/approve` によって申請が承認されることによって、Treasure Data 作成時のデータソースとして扱われるようになります。
   * - When
   *   - `POST /organizations/{organization_id}/member_properties` もしくは、 `POST /users/arbitrary_properties` によって Member Property / Arbitrary Property の登録が完了した後
   * - Who
   *   - 実行者が組織に所属している場合、`treasure_deal_apply` 権限があること
   *   - 実行者が指定した `apply_to` の個人 or 組織から公開設定を受けていること
   * - How
   *   - 解錠したい Hole および、 Object もしくは、 Treasure Box の指定方法
   *     - `hole_id` , `object_id` のいずれか、及び `treasure_box_id` を指定することで、利用したい対象を申請することができます。
   *   - `permittee_properties` の指定条件
   *       - 実行者が個人の場合、自身が所有している Arbitrary Property のみ指定することができます。
   *       - 実行者が組織に所属している場合、自組織が所有している Member Property のみ指定することができます。
   *   - 解錠条件の指定方法
   *     - 繰り返し条件や、使用可能時間、使用回数などを細かく指定することができます。
   */
  public static async addTreasureDeal(requestBody: AddTreasureDealRequest): Promise<TreasureDeal> {
    return BitkeyPlatformAPI.callApi<TreasureDeal>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.post(`/treasure_deals`, BitkeyPlatformAPI.toRequest(requestBody))
    );
  }

  /**
   * Treasure Deal の情報を参照します。
   */
  public static async findTreasureDeal(treasureDealId: string): Promise<TreasureDeal> {
    return BitkeyPlatformAPI.callApi<TreasureDeal>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.get(`/treasure_deals/${treasureDealId}`)
    );
  }

  /**
   * Treasure Deal の申請を取り消します。
   *
   * - Why & What
   *   - Treasure Deal の申請を取り消したい場合に実行します。
   * - When
   *   - `POST /treasure_deals` によって、 Treasure Deal が作成された後
   *   - `PUT /treasure_deals/{treasure_deal_id}/approve` もしくは、 `PUT /treasure_deals/{treasure_deal_id}/reject` によって、 Treasure Deal が承認、もしくは却下される前
   * - Who
   *   - Treasure Deal を申請した本人、もしくは、組織に所属する人
   *   - 実行者が組織に所属する場合は、`treasure_deal_apply` 権限があること
   */
  public static async deleteTreasureDeal(treasureDealId: string): Promise<void> {
    return BitkeyPlatformAPI.callApi<void>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.delete(`/treasure_deals/${treasureDealId}`)
    );
  }

  /**
   * Treasure Deal を承認します。
   *
   * - Why & What
   *   - Hole、 Object および、 Treasure Box の所有者の同意を持って、Treasure Data データソースを作成したい場合に実行します。
   *   - 申請された Treasure Deal を承認し、申請者の指定する Member Property （組織） / Arbitrary Property （個人） を利用して、実行者が所有する Hole, Object および Treasure Box のための Treasure Data データソースを作成します。
   * - When
   *   - `POST /treasure_deals` によって、自身、もしくは自組織宛の Treasure Deal が作成された後
   *   - `DELETE /treasure_deals/{treasure_deal_id}` もしくは、 `PUT /treasure_deals/{treasure_deal_id}/reject` によって、 Treasure Deal が取り消し、もしくは却下される前
   * - Who
   *   - `apply_to` に指定された本人、もしくは、組織に所属する人
   *   - Treasure Deal で指定された Hole, Object および Treasure Box の所有者、もしくは借用者
   *   - 実行者が組織に所属する場合は、`treasure_deal_control` 権限があること
   * - How
   *   - 解錠条件の指定方法
   *     - 繰り返し条件や、使用可能時間、使用回数などを変更することができます。
   *   - 解錠できる Hole および、 Object もしくは、 Treasure Box の指定方法
   *     - 自身が所有、もしくは借用している hole_id, object_id および treasure_box_id を指定する必要があります。
   */
  public static async approveTreasureDeal(
    treasureDealId: string,
    requestBody: ApproveTreasureDealRequest
  ): Promise<TreasureDeal> {
    return BitkeyPlatformAPI.callApi<TreasureDeal>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.put(
        `/treasure_deals/${treasureDealId}/approve`,
        BitkeyPlatformAPI.toRequest(requestBody)
      )
    );
  }

  /**
   * 承認済みの Treasure Deal を無効化します。
   *
   * - Why & What
   *   - 承認した Treasure Deal を無効にし、Treasure Data を削除したい場合に実行します。
   *   - Treasure Deal の申請を無効にすると、以後、Treasure Data データソースとして Treasure Data を生成されることはなくなります。
   *   - レスポンスに返却される `delete_treasure_data_request` をデバイスに渡すことで、デバイス上からも Treasure Data を削除することができます。
   *   - デバイス側では `delete_treasure_data_request` を `treasure_box_set_request` に含まれていた公開鍵を使って、 検証してください。
   * - When
   *   - `PUT /treasure_deals/{treasure_deal_id}/approve` によって、自身、もしくは自組織宛の Treasure Deal が承認された後
   *   - `PUT /treasure_deals/{treasure_deal_id}/disable` によって Treasure Deal が無効にされた後、何度でも実行でき、 `delete_treasure_data_request` を入手できます。
   * - Who
   *   - Treasure Deal で指定された Hole, Object および Treasure Box の所有者、もしくは借用者
   *   - 承認者が組織に所属する場合は、`treasure_deal_control` 権限があること
   * - How
   *   - 無効になった Treasure Deal の確認
   *     - 無効になった Treasure Deal に対しても、本 API を再度実行することで `delete_treasure_data_request` を入手できます。
   *     - また、無効になった Treasure Deal は `GET /treasure_deals/{treasure_deal_id}` の `deal_status` を `disabled` にすることで確認できます。
   */
  public static async disableTreasureDeal(treasureDealId: string): Promise<DisableTreasureDealResponse> {
    return BitkeyPlatformAPI.callApi<DisableTreasureDealResponse>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.put(`/treasure_deals/${treasureDealId}/disable`)
    );
  }

  /**
   * Treasure Deal を却下します。
   *
   * - Why & What
   *   - Treasure Deal の申請を却下したい場合に実行します。
   * - When
   *   - `POST /treasure_deals` によって、自身、もしくは自組織宛の Treasure Deal が作成された後
   *   - `DELETE /treasure_deals/{treasure_deal_id}` もしくは、 `PUT /treasure_deals/{treasure_deal_id}/approve` によって、 Treasure Deal が取り消し、もしくは承認される前
   * - Who
   *   - 実行者、もしくは実行者が所属する組織が、`apply_to` に指定されていること
   *   - 実行者が組織に所属する場合は、`treasure_deal_control` 権限があること
   */
  public static async rejectTreasureDeal(treasureDealId: string): Promise<TreasureDeal> {
    return BitkeyPlatformAPI.callApi<TreasureDeal>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.put(`/treasure_deals/${treasureDealId}/reject`)
    );
  }

  /**
   * ユーザー登録のためのセッションを開始します。
   *
   * ユーザー登録のためのセッションID、パスコードが発行されます。
   * セッションIDはAPIレスポンスで返され、パスコードはメール(or SMS)で送付されます。
   * メールアドレス と 電話番号の両方を指定された場合、電話番号は無視されます。
   */
  public static async addUser(requestBody: AddUserRequest): Promise<AddUserResponse> {
    return BitkeyPlatformAPI.toResponse<AddUserResponse>(
      BitkeyPlatformAPI.instance.axiosWithoutToken.post(`/users`, BitkeyPlatformAPI.toRequest(requestBody))
    );
  }

  /**
   * ユーザー登録を完了します。
   *
   * セッションIDとパスコードを検証した上で、ユーザー登録を完了します。
   * 端末認証用の公開鍵を登録する場合は `authorization_code` を `nonce` として署名を作成し、 `authorization_code_signature` に入力してください。
   */
  public static async activateUser(requestBody: ActivateUserRequest): Promise<ActivateUserResponse> {
    return BitkeyPlatformAPI.toResponse<ActivateUserResponse>(
      BitkeyPlatformAPI.instance.axiosWithoutToken.post(`/users/activate`, BitkeyPlatformAPI.toRequest(requestBody))
    );
  }

  /**
   * ユーザ招待のための一時的な user_alias_code を発行します。
   *
   * ユーザ招待のための一時的な user_alias_code を発行します。
   * 使用できるのは1回だけです。
   * user_alias_code は `POST /organizations/{organization_id}/members` のAPI でユーザを招待するために使います。
   * 招待されるユーザが user_alias_code を発行し、QRコードなどで招待する組織のユーザに伝えてください。
   * `POST /organizations/{organization_id}/members` では userID もしくは、 user_alias_code があれば招待できます。
   */
  public static async issueUserAliasCode(): Promise<IssueUserAliasCodeResponse> {
    return BitkeyPlatformAPI.callApi<IssueUserAliasCodeResponse>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.post(`/users/alias_codes`)
    );
  }

  /**
   * ユーザ任意のデータを一覧取得します
   */
  public static async listArbitraryProperty(queryParameters: ListArbitraryPropertyQuery): Promise<ArbitraryProperty[]> {
    return BitkeyPlatformAPI.callApi<ArbitraryProperty[]>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.get(`/users/arbitrary_properties`, {
        params: BitkeyPlatformAPI.toRequest(queryParameters),
      })
    );
  }

  /**
   * ユーザ任意のデータを登録します
   */
  public static async addArbitraryProperty(requestBody: AddArbitraryPropertyRequest): Promise<ArbitraryProperty> {
    return BitkeyPlatformAPI.callApi<ArbitraryProperty>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.post(
        `/users/arbitrary_properties`,
        BitkeyPlatformAPI.toRequest(requestBody)
      )
    );
  }

  /**
   * ユーザー任意の添付データを登録します
   */
  public static async addArbitraryPropertyWithAttachment(): Promise<ArbitraryProperty> {
    return BitkeyPlatformAPI.callApi<ArbitraryProperty>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.post(`/users/arbitrary_properties/attachments`)
    );
  }

  /**
   * ユーザ任意のデータをID指定で取得します
   */
  public static async findArbitraryProperty(arbitraryPropertyId: string): Promise<ArbitraryProperty> {
    return BitkeyPlatformAPI.callApi<ArbitraryProperty>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.get(`/users/arbitrary_properties/${arbitraryPropertyId}`)
    );
  }

  /**
   * ユーザ任意のデータを削除します
   */
  public static async deleteArbitraryProperty(arbitraryPropertyId: string): Promise<void> {
    return BitkeyPlatformAPI.callApi<void>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.delete(`/users/arbitrary_properties/${arbitraryPropertyId}`)
    );
  }

  /**
   * ユーザ任意の添付データをID指定で取得します
   */
  public static async findArbitraryPropertyAttachment(arbitraryPropertyId: string): Promise<PropertyAttachment> {
    return BitkeyPlatformAPI.callApi<PropertyAttachment>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.get(`/users/arbitrary_properties/${arbitraryPropertyId}/attachments`)
    );
  }

  /**
   * ユーザ任意のデータの名前を更新します
   */
  public static async updateArbitraryPropertyName(
    arbitraryPropertyId: string,
    requestBody: UpdateArbitraryPropertyNameRequest
  ): Promise<ArbitraryProperty> {
    return BitkeyPlatformAPI.callApi<ArbitraryProperty>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.put(
        `/users/arbitrary_properties/${arbitraryPropertyId}/name`,
        BitkeyPlatformAPI.toRequest(requestBody)
      )
    );
  }

  /**
   * ユーザ任意のデータの名前を更新します
   *
   * データの種類によって value の更新が禁止されているものがあります。
   * e.g. face-image
   */
  public static async updateArbitraryPropertyValue(
    arbitraryPropertyId: string,
    requestBody: UpdateArbitraryPropertyValueRequest
  ): Promise<ArbitraryProperty> {
    return BitkeyPlatformAPI.callApi<ArbitraryProperty>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.put(
        `/users/arbitrary_properties/${arbitraryPropertyId}/value`,
        BitkeyPlatformAPI.toRequest(requestBody)
      )
    );
  }

  /**
   * ユーザーを認証してAPIトークンを発行します。(廃止予定)
   *
   * こちらのAPIは廃止予定で、現在非推奨となっています。
   * `/personas/auth` もしくは `/personas/{persona_id}/auth` をお使いください。
   *
   * 指定された認証情報を元にユーザーを認証して、APIトークンを発行します。
   * APIトークンは、ユーザー登録時に作成されたデフォルトのペルソナを対象に発行されます。
   * メールアドレス と 電話番号の両方を指定された場合、電話番号は無視されます。
   */
  public static async authenticateUser(requestBody: InitialAuthenticationRequest): Promise<AuthenticationResult> {
    return BitkeyPlatformAPI.toResponse<AuthenticationResult>(
      BitkeyPlatformAPI.instance.axiosWithoutToken.post(`/users/auth`, BitkeyPlatformAPI.toRequest(requestBody))
    );
  }

  /**
   * 連絡先一覧を取得します。
   *
   * ユーザーに紐づく連絡先を一覧取得します。
   */
  public static async listContacts(): Promise<Contact[]> {
    return BitkeyPlatformAPI.callApi<Contact[]>(() => BitkeyPlatformAPI.instance.axiosWithToken.get(`/users/contacts`));
  }

  /**
   * 連絡先の登録を開始します。
   *
   * ユーザーに紐づく連絡先の登録を開始します。
   * このAPI実行後、連絡先に届く `activate_code` を
   * 以下の PUT /users/contacts/{contact_id} で検証することで、連絡先の登録を完了します。
   */
  public static async startContactRegistration(requestBody: StartContactRegistrationRequest): Promise<void> {
    return BitkeyPlatformAPI.callApi<void>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.post(`/users/contacts`, BitkeyPlatformAPI.toRequest(requestBody))
    );
  }

  /**
   * 連絡先の登録を完了します。
   *
   * `activate_code` を検証し、ユーザーに紐づく連絡先の登録を完了します。
   */
  public static async activateContact(requestBody: ActivateContactRequest): Promise<Contact> {
    return BitkeyPlatformAPI.callApi<Contact>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.put(
        `/users/contacts/activate`,
        BitkeyPlatformAPI.toRequest(requestBody)
      )
    );
  }

  /**
   * 連絡先を取得します。
   *
   * ユーザーに紐づく連絡先をID指定で取得します。
   */
  public static async findContact(contactId: string): Promise<Contact> {
    return BitkeyPlatformAPI.callApi<Contact>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.get(`/users/contacts/${contactId}`)
    );
  }

  /**
   * 連絡先を削除します。
   *
   * ユーザーに紐づく連絡先をID指定で削除します。
   */
  public static async deleteContact(contactId: string): Promise<void> {
    return BitkeyPlatformAPI.callApi<void>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.delete(`/users/contacts/${contactId}`)
    );
  }

  /**
   * 連絡先に結びついているペルソナの一覧を返します。
   *
   * 指定の連絡先に結びついているペルソナの一覧を返します。
   */
  public static async listIdentifyingPersonas(contactId: string): Promise<ContactIdentifiedPersona[]> {
    return BitkeyPlatformAPI.callApi<ContactIdentifiedPersona[]>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.get(`/users/contacts/${contactId}/identifying_personas`)
    );
  }

  /**
   * 指定のペルソナに対し、指定の連絡先を紐付けます。
   *
   * ID指定されたペルソナに、連絡先をID指定で紐付けます。
   * これにより、ペルソナ毎に異なる連絡先を指定することが可能になります。
   */
  public static async registerIdentifyingPersonas(contactId: string, personaId: string): Promise<void> {
    return BitkeyPlatformAPI.callApi<void>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.post(`/users/contacts/${contactId}/identifying_personas/${personaId}`)
    );
  }

  /**
   * 指定のペルソナから、指定の連絡先の登録を解除します。
   *
   * ID指定されたペルソナに紐づいている連絡先を、ID指定で紐付け解除します。
   */
  public static async deleteIdentifyingPersonas(contactId: string, personaId: string): Promise<void> {
    return BitkeyPlatformAPI.callApi<void>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.delete(`/users/contacts/${contactId}/identifying_personas/${personaId}`)
    );
  }

  /**
   * メールアドレスを変更します。
   *
   * 事前にセッションを開始し、パスコードをメールから入手してください。
   */
  public static async updateUserEmail(requestBody: UpdateUserEmailRequest): Promise<void> {
    return BitkeyPlatformAPI.callApi<void>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.put(`/users/email`, BitkeyPlatformAPI.toRequest(requestBody))
    );
  }

  /**
   * メール変更のセッションを開始します。
   *
   * 新しいメールアドレス宛にコードが発行されます。
   */
  public static async startUpdateUserEmail(requestBody: StartUpdateUserEmailRequest): Promise<void> {
    return BitkeyPlatformAPI.callApi<void>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.post(`/users/email`, BitkeyPlatformAPI.toRequest(requestBody))
    );
  }

  /**
   * ペルソナに結びついている連絡先の一覧を返します。
   *
   * 指定のペルソナに結びついている連絡先の一覧を返します。
   */
  public static async listContactsByPersona(personaId: string): Promise<Contact[]> {
    return BitkeyPlatformAPI.callApi<Contact[]>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.get(`/users/personas/${personaId}/contacts`)
    );
  }

  /**
   * ユーザのペルソナを取得します。
   */
  public static async searchPersonaByUser(userId: string): Promise<PersonaWithDetails[]> {
    return BitkeyPlatformAPI.callApi<PersonaWithDetails[]>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.get(`/users/${userId}/personas`)
    );
  }

  /**
   * ユーザのペルソナを有効化します。
   *
   * ユーザのペルソナを有効化し、入力されたpasswordで認証可能にします。
   * ペルソナを追加した際に入力したメールアドレス、もしくは電話番号に対して送付されたメッセージに記載された検証コードが必要です。
   * persona_public_keyを入力した場合にはnonceの検証を行い、ペルソナの端末認証用の公開鍵として登録します。
   */
  public static async activatePersona(
    userId: string,
    personaId: string,
    requestBody: ActivatePersonaRequest
  ): Promise<ActivatePersonaResponse> {
    return BitkeyPlatformAPI.callApi<ActivatePersonaResponse>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.put(
        `/users/${userId}/personas/${personaId}/activate`,
        BitkeyPlatformAPI.toRequest(requestBody)
      )
    );
  }

  /**
   * ユーザーIDを再発行します。
   *
   * ユーザーIDの再発行を行います。
   * 変更後のユーザーIDは指定できません。
   */
  public static async reissueUserId(userId: string): Promise<ReissueUserIdResponse> {
    return BitkeyPlatformAPI.callApi<ReissueUserIdResponse>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.put(`/users/${userId}/reissue_id`)
    );
  }

  /**
   * バージョンを表示します。
   */
  public static async getVersion(): Promise<GetVersionResponse> {
    return BitkeyPlatformAPI.toResponse<GetVersionResponse>(
      BitkeyPlatformAPI.instance.axiosWithoutToken.get(`/version`)
    );
  }
}
