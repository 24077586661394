import React, {Dispatch, SetStateAction, useCallback} from 'react';
import {Snackbar} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

export enum SimpleSnackbarType {
  Error = 'error',
  Warning = 'warning',
  Info = 'info',
  Success = 'success',
}

export type SimpleSnackbarState = {
  open: boolean;
  message: string;
  type: SimpleSnackbarType;
  duration?: number;
};

const SimpleSnackbar = React.memo<{
  snackbarState: SimpleSnackbarState;
  setSnackbarState: Dispatch<SetStateAction<SimpleSnackbarState>>;
}>(({snackbarState, setSnackbarState}) => {
  const onClose = useCallback(() => {
    setSnackbarState(s => ({...s, open: false}));
  }, [setSnackbarState]);

  return (
    <Snackbar
      open={snackbarState.open}
      onClose={onClose}
      autoHideDuration={snackbarState?.duration ? snackbarState?.duration : 3000}
    >
      <Alert elevation={6} variant={'filled'} onClose={onClose} severity={snackbarState.type}>
        {snackbarState.message}
      </Alert>
    </Snackbar>
  );
});

export default SimpleSnackbar;
