export enum BColor {
  // homehub用
  // TODO 動的に変更できるように
  Main = '#F97026',
  MainDark = '#bf4000',
  MainLight = '#ffa155',

  Black = '#333333',
  Modify = '#757575',
  Hint = '#9e9e9e',

  White = '#ffffff',
  Gray300 = '#e0e0e0',
  // Main = '#1ebec8',
  // MainDark = '#15858c',
  // MainLight = '#4bcbd3',
  Sub = '#ebc84b',
  SubDark = '#a48c34',
  SubLight = 'efd36f',
  Alert = '#e15a4b',
  Mod = '#283c4b',

  Background = '#F9F9F9',
}
